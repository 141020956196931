import {
  Body,
  BodySizeEnum,
  Box,
  Icon,
  IconComponentType,
  LogOutIcon,
  MoreIcon,
  SizeEnum,
  SupportIcon,
} from '@northone/ui-components'
import { useRef, useState } from 'react'
import { Pressable } from 'react-native'

import { Intercom } from '@/core/intercom'
import { useClickOutside } from '@/hooks/useClickOutside'

import { LogoutModal } from './LogoutModal'

interface DropdownMenuOptionProps {
  text: string
  icon?: IconComponentType
  onPress: () => void
}

export interface DropdownMenuProps {
  position?: 'right' | 'left'
  options: DropdownMenuOptionProps[]
}

export function DropdownMenu({ options, position }: DropdownMenuProps) {
  const [isOpen, setIsOpen] = useState(false)

  const onTogglePress = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Box sx={{ alignItems: 'flex-end' }}>
      <Pressable onPress={onTogglePress}>
        <MoreIcon size={SizeEnum.MD} />
      </Pressable>
      {isOpen ? <DropdownMenuDrawer options={options} setIsOpen={setIsOpen} position={position} /> : null}
    </Box>
  )
}

const DropdownMenuDrawer = ({
  options,
  setIsOpen,
  position = 'right',
}: {
  options: DropdownMenuOptionProps[]
  setIsOpen: (isOpen: boolean) => void
  position?: 'right' | 'left'
}) => {
  const drawerRef = useRef<HTMLDivElement>(null)
  const closeModal = () => setIsOpen(false)
  useClickOutside(drawerRef, closeModal)

  const mappedOptions = options.map((item, idx) => {
    const onPress = () => {
      item.onPress()
      setIsOpen(false)
    }

    return <DropdownItem {...item} onPress={onPress} key={idx} />
  })
  return (
    <div ref={drawerRef}>
      <Box
        sx={{
          position: 'absolute',
          top: '110%',
          zIndex: 2,
          width: 300,
          borderWidth: 1,
          borderRadius: '$sm',
          borderColor: '$charcoal1',
          maxHeight: '$80',
          marginTop: '$2',
          ...(position === 'right' ? { right: 0 } : { left: 0 }),
        }}
      >
        {mappedOptions}
      </Box>
    </div>
  )
}

const DropdownItem = ({ onPress, text, icon }: DropdownMenuOptionProps) => {
  const [isHovered, setIsHovered] = useState(false)
  const iconComponent = icon ? <Icon as={icon} size={SizeEnum.MD} /> : null
  return (
    <Pressable onPress={onPress} onHoverIn={() => setIsHovered(true)} onHoverOut={() => setIsHovered(false)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingRight: '$6',
          paddingLeft: '$6',
          minHeight: '$12',
          backgroundColor: isHovered ? '$white2' : '$white1',
        }}
      >
        <Body size={BodySizeEnum.MD}>{text}</Body>
        {iconComponent}
      </Box>
    </Pressable>
  )
}

export const AppMenu = () => {
  const [logoutModalIsVisible, setLogoutModalIsVisible] = useState<boolean>(false)
  const closeLogoutModal = () => setLogoutModalIsVisible(false)
  const openLogoutModal = () => setLogoutModalIsVisible(true)
  const openIntercom = () => Intercom.show()
  return (
    <>
      <LogoutModal isVisible={logoutModalIsVisible} onPressGoBack={closeLogoutModal} />
      <DropdownMenu
        options={[
          { text: 'Support', onPress: openIntercom, icon: SupportIcon },
          { text: 'Log out', onPress: openLogoutModal, icon: LogOutIcon },
        ]}
      />
    </>
  )
}
