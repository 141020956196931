import { useState } from 'react'
import { useAppSelector } from '@/core/redux/utils'
import useBusinessType from '@/hooks/useBusinessType'
import { errorMessages } from '@/utils/error-messages'

export const useBusinessDetailsValidation = () => {
  const { isSoleProp } = useBusinessType()
  const [shouldShowFieldErrors, setShouldShowFieldErrors] = useState(false)
  const { businessName, ein, fullTimeEmployees, hasRegisteredBusinessName, tradeName, yearsInOperation } =
    useAppSelector((state) => state.application)

  const fieldErrors = {
    yearsInOperation: errorMessages.yearsInOperation(yearsInOperation),
    ...(isSoleProp && hasRegisteredBusinessName && { businessName: errorMessages.businessName(tradeName ?? '') }),
    ...(!isSoleProp && {
      businessName: errorMessages.businessName(businessName),
      fullTimeEmployees: errorMessages.fullTimeEmployees(fullTimeEmployees),
      ein: errorMessages.ein(ein),
    }),
  } as const

  const hasError = Object.values(fieldErrors).some((value) => Boolean(value))

  return {
    fieldErrors: shouldShowFieldErrors ? fieldErrors : undefined,
    setShouldShowFieldErrors,
    hasError,
  } as const
}
