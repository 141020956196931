import { createSelector } from 'reselect'

import { IRootState } from '../root-state'
import { IApplicationState } from './application-state'

const getApplicationData = (state: IRootState): IApplicationState => state.application

export const getBusinessType = createSelector([getApplicationData], (state) => state.businessType)

export const getBusinessAddress = createSelector([getApplicationData], (state) => state.businessAddress)

export const getBusinessPhoneNumber = createSelector([getApplicationData], (state) => state.businessPhoneNumber)

export const getBusinessName = createSelector([getApplicationData], (state) => state.businessName)

export const getSocialMedia = createSelector([getApplicationData], (state) => state.socialMedia)
