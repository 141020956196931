// 36 is ideal max length for file name as per Design
export const truncateFileName = (fileName: string, maxLength = 36) => {
  if (fileName.length <= maxLength) return fileName

  const lastDotIndex = fileName.lastIndexOf('.')
  const extension = fileName.slice(lastDotIndex)
  const nameWithoutExtension = fileName.slice(0, lastDotIndex)

  // Subtract 3 for the ellipsis in the middle
  const availableLength = maxLength - 3 - extension.length
  const halfAvailable = Math.floor(availableLength / 2)
  const start = nameWithoutExtension.slice(0, halfAvailable)
  const end = nameWithoutExtension.slice(-halfAvailable)

  return `${start}...${end}${extension}`
}
