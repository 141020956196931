import {
  AlertTypeEnum,
  Body,
  BodySizeEnum,
  Box,
  EditIcon,
  InlineAlert,
  Label,
  LabelVariantEnum,
  PageHeader,
  PageHeaderSizeEnum,
  SizeEnum,
  TimerIcon,
} from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'
import { useState } from 'react'
import { Pressable } from 'react-native'

import { PRIMARY_OWNER_ID } from '@/core/redux/application-redux/application-state'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { validate } from '@/utils/validate'

import { DeleteCoOwnerConfirmationModal } from './DeleteCoOwnerConfirmationModal'
import { useOwnerHasDuplicateEmail } from './utils/use-owner-has-duplicate-email'
import { useTotalOwnershipPercentageIsValid } from './utils/use-ownership-percentage-validation'

const useCopy = () => {
  const t = useOnboardingTranslations()
  return {
    email: t('ownership.profile.labels.email'),
    birthdate: t('ownership.profile.labels.dateOfBirth'),
    streetAddress: t('ownership.profile.labels.streetAddress'),
    phoneNumber: t('ownership.profile.labels.phoneNumber'),
    ssn: t('ownership.profile.labels.ssnShort'),
    ownership: t('ownership.profile.labels.ownership'),
    required: t('ownership.profile.required'),
    missingSSNAlert: t('ownership.profile.missingSSNAlert'),
    invitePending: t('ownership.profile.labels.invitePending'),
    delete: t('ownership.profile.labels.delete'),
    edit: t('ownership.profile.labels.edit'),
  }
}

export const OwnerProfileSummary = ({
  ownerId,
  startEditing,
}: {
  startEditing: () => void
  ownerId: string
  isEditing?: boolean
}) => {
  const owner = useAppSelector((state) => state.application.owners[ownerId])
  if (!owner) throw new Error(`Owner Profile Summary error, owner with ID ${ownerId} not found`)
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    birthdate,
    jobTitle,
    ownershipPercentage,
    isInviteCheckboxSelected,
  } = owner
  const streetAddress = owner?.address?.streetAddress
  const copy = useCopy()
  const SSN = useAppSelector((state) => state.unpersisted.ssns)[ownerId]
  const displayEditForm = () => startEditing()
  const isPrimaryOwner = ownerId === PRIMARY_OWNER_ID
  const hasDuplicateEmail = useOwnerHasDuplicateEmail(email ?? '', ownerId)
  const ownershipPercentageIsValid = useTotalOwnershipPercentageIsValid()

  const ssnDisplayFieldProps: DisplayFieldProps = {
    label: copy.ssn,
    ...(validate.ssn(SSN) ? { value: '•••••••••' } : { value: 'Required', isInvalid: true }),
  }

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const showDeletionModal = () => setIsDeleteModalVisible(true)
  const closeDeletionModal = () => setIsDeleteModalVisible(false)

  const { isMobileSize } = useMobileScreenSize()

  return (
    <>
      {!isPrimaryOwner ? (
        <DeleteCoOwnerConfirmationModal
          ownerId={ownerId}
          closeModal={closeDeletionModal}
          isVisible={isDeleteModalVisible}
        />
      ) : null}
      <Box
        sx={{
          p: isMobileSize ? '$6' : '$10',
          backgroundColor: '$white1',
          borderWidth: 1,
          borderRadius: '$md',
          borderColor: '$charcoal1',
          gap: '$4',
        }}
      >
        <Box sx={{ gap: '$6' }}>
          {!validate.ssn(SSN) && (
            <InlineAlert type={AlertTypeEnum.Error} showIcon>
              {copy.missingSSNAlert}
            </InlineAlert>
          )}
          <Box sx={{ gap: '$2' }}>
            <Box sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <PageHeader size={PageHeaderSizeEnum.SM} headingLevel={1}>
                {firstName} {lastName}
              </PageHeader>
              <Box sx={{ flexDirection: 'row', gap: '$4', flexWrap: 'wrap' }}>
                {!isPrimaryOwner ? (
                  <Pressable onPress={showDeletionModal}>
                    <Box sx={{ flexDirection: 'row', gap: '$2', alignItems: 'center' }}>
                      <Body size={BodySizeEnum.MD} color={'$error4'}>
                        {copy.delete}
                      </Body>
                    </Box>
                  </Pressable>
                ) : null}
                <Pressable onPress={displayEditForm}>
                  <Box sx={{ flexDirection: 'row', gap: '$2', alignItems: 'center' }}>
                    <Body size={BodySizeEnum.MD}>{copy.edit}</Body>
                    <EditIcon size={SizeEnum.SM} />
                  </Box>
                </Pressable>
              </Box>
            </Box>
            <Body size={BodySizeEnum.LG}>{jobTitle}</Body>
          </Box>
          <Box sx={{ flexDirection: 'row', flexWrap: 'wrap', gap: '$6' }}>
            <Box sx={{ gap: '$4', maxWidth: 300, width: '$full' }}>
              <DisplayField label={copy.email} value={email ?? ''} isInvalid={hasDuplicateEmail} />
              <DisplayField label={copy.birthdate} value={birthdate ?? ''} />
              <DisplayField label={copy.streetAddress} value={streetAddress ?? ''} />
            </Box>
            <Box sx={{ gap: '$4' }}>
              <DisplayField label={copy.phoneNumber} value={phoneNumber ?? ''} />
              <DisplayField {...ssnDisplayFieldProps} />
              <DisplayField
                label={copy.ownership}
                value={`${ownershipPercentage}%`}
                isInvalid={!ownershipPercentageIsValid}
              />
            </Box>
          </Box>
        </Box>
        {!isPrimaryOwner && isInviteCheckboxSelected ? (
          <Box sx={{ alignItems: 'flex-start' }}>
            <Label icon={TimerIcon} variant={LabelVariantEnum.INFO}>
              {copy.invitePending}
            </Label>
          </Box>
        ) : null}
      </Box>
    </>
  )
}

interface DisplayFieldProps {
  label: string
  value: string
  isInvalid?: boolean
}
export const DisplayField = ({ label, value, isInvalid }: DisplayFieldProps) => (
  <Box>
    <Body size={BodySizeEnum.XS}>{label}</Body>
    <Body size={BodySizeEnum.MD} color={isInvalid ? '$error4' : '$charcoal7'}>
      {value}
    </Body>
  </Box>
)
