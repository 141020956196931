import { dollarsToCents } from '@northone/amount-utils'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LoadingScreen from '@/components/LoadingScreen'
import { analytics } from '@/core/analytics/events'
import { transformBusinessTypeToGQLInput } from '@/core/redux/application-redux/selectors'
import { useAppSelector } from '@/core/redux/utils'
import {
  PlaidItemStatus,
  useCaptureInitialFundingAmountMutation,
  useFundingAmountScreenSuspenseQuery,
  useGetPlaidFundingScreenDataSuspenseQuery,
} from '@/generated/graphql'
import { I18nPath, useOnboardingTranslations } from '@/i18n/locales/en/en'
import { Pathname } from '@/routes/constants'
import {
  PLAID_BALANCE_CHECK_COUNT_KEY,
  PLAID_BALANCE_CHECK_COUNT_MAXIMUM,
  PLAID_MINIMUM_BALANCE_REQUIRED,
} from '../constants'
import { getBalanceCompliantMaximumAmount } from '../utils'
import { PlaidFundingAmountComponent } from './amount.component'
import { TPlaidInitialFundingAmounts } from './useGetFundingAmounts'

const useBalanceCheckCounter = () => {
  const balanceCheckCountItem = sessionStorage.getItem(PLAID_BALANCE_CHECK_COUNT_KEY)
  const initialBalanceCheckCount = balanceCheckCountItem ? parseInt(balanceCheckCountItem) : 0

  const balanceCheckCount = useRef(initialBalanceCheckCount)
  const [isBalanceCheckLimitReached, setIsBalanceCheckLimitReached] = useState(
    initialBalanceCheckCount >= PLAID_BALANCE_CHECK_COUNT_MAXIMUM,
  )

  const incrementBalanceCheckCount = () => {
    balanceCheckCount.current += 1

    sessionStorage.setItem(PLAID_BALANCE_CHECK_COUNT_KEY, balanceCheckCount.current.toString())

    if (balanceCheckCount.current <= PLAID_BALANCE_CHECK_COUNT_MAXIMUM) return
    analytics.funding.plaid.balanceCheckLimitReached()
    setIsBalanceCheckLimitReached(true)
  }

  return { isBalanceCheckLimitReached, incrementBalanceCheckCount }
}

const getFundingAmounts = ({
  availableBalance,
  fundingAmounts,
  hasError,
}: {
  availableBalance?: number | null
  fundingAmounts: TPlaidInitialFundingAmounts
  hasError: boolean
}): TPlaidInitialFundingAmounts => {
  if (hasError) return { ...fundingAmounts, defaultAmount: fundingAmounts.minimumAmount }
  if (availableBalance === null || availableBalance === undefined) return fundingAmounts
  const { minimumAmount, maximumAmount, defaultAmount } = fundingAmounts
  return {
    minimumAmount,
    maximumAmount: getBalanceCompliantMaximumAmount({ availableBalance, fundingAmount: maximumAmount }),
    defaultAmount: getBalanceCompliantMaximumAmount({ availableBalance, fundingAmount: defaultAmount }),
  }
}

const getErrorI18nPath = ({
  isBalanceCheckLimitReached,
  availableBalanceBelowMinimumRequired,
}: {
  isBalanceCheckLimitReached: boolean
  availableBalanceBelowMinimumRequired: boolean
}): I18nPath | undefined => {
  if (isBalanceCheckLimitReached) return 'plaidFunding.amount.maxBalanceChecksPerformed'
  if (availableBalanceBelowMinimumRequired) return 'plaidFunding.amount.lowBalanceError'
  return undefined
}

export const PlaidFundingAmountScreen = () => {
  const navigate = useNavigate()
  const t = useOnboardingTranslations()
  const businessId = useAppSelector((state) => state.application.businessId)
  const { isBalanceCheckLimitReached, incrementBalanceCheckCount } = useBalanceCheckCounter()
  const [hasComponentRendered, setHasComponentRendered] = useState(false)

  const amount = useRef<number>()
  const [isLoading, setIsLoading] = useState(false)
  const [availableBalance, setAvailableBalance] = useState<number>()
  const [hasSetAvailableBalance, setHasSetAvailableBalance] = useState(false)
  const [isAvailableBalanceBelowMinimumRequired, setIsAvailableBalanceBelowMinimumRequired] = useState(false)
  const [errorMessageProps, setErrorMessageProps] = useState<{
    error: string
    appendContactCustomerCareLink: boolean
  }>()
  const [canRecheckBalance, setCanRecheckBalance] = useState(false)
  const [fundingAmounts, setFundingAmounts] = useState<TPlaidInitialFundingAmounts>()

  const [isPreparingToNavigate, setIsPreparingToNavigate] = useState(false)

  const businessType = useAppSelector((state) => state.application.businessType)
  const businessTypeGQLInput = transformBusinessTypeToGQLInput(businessType)
  const { data: fundingAmountScreenData } = useFundingAmountScreenSuspenseQuery({
    skip: !businessType,
    variables: { businessId, businessType: businessTypeGQLInput },
  })
  const { data: plaidBalanceData, refetch: getPlaidBalanceRefetch } = useGetPlaidFundingScreenDataSuspenseQuery({
    variables: { businessId },
    skip: isBalanceCheckLimitReached,
  })

  useEffect(() => {
    if (hasComponentRendered) return
    setHasComponentRendered(true)
  }, [hasComponentRendered])

  useEffect(() => {
    if (isBalanceCheckLimitReached || hasComponentRendered) return
    incrementBalanceCheckCount()
  }, [incrementBalanceCheckCount, isBalanceCheckLimitReached, hasComponentRendered])

  const [captureInitialFundingAmountMutation] = useCaptureInitialFundingAmountMutation({
    onError: () => {
      setIsPreparingToNavigate(false)
    },
  })

  const onSubmit = (updatedAmount: number) => {
    setIsLoading(true)
    amount.current = updatedAmount
    if (amount.current) {
      captureInitialFundingAmountMutation({
        variables: {
          businessId,
          amountInCents: dollarsToCents(amount.current),
          businessType: businessTypeGQLInput,
        },
      }).then(() => {
        setIsLoading(false)
        navigate(Pathname.APPLICATION_SUBMIT)
      })
    }
  }
  const onGoBack = () => navigate(Pathname.ACCOUNT_FUNDING)

  useEffect(() => {
    const activePlaidItems = fundingAmountScreenData.plaidItems?.data.filter(
      (item) => item.status === PlaidItemStatus.ACTIVE,
    )

    if (!activePlaidItems?.length) {
      navigate(Pathname.ACCOUNT_FUNDING_LINK_EXTERNAL_ACCOUNT, { replace: true })
      return
    }

    const newestPlaidItem = activePlaidItems[activePlaidItems.length - 1]
    const newestPlaidItemBalances = plaidBalanceData?.getPlaidBalance?.data.accounts?.find(
      (account) => account.account_id === newestPlaidItem?.accountId,
    )?.balances

    const balance = newestPlaidItemBalances?.available ?? undefined
    setAvailableBalance(balance)
    setHasSetAvailableBalance(true)

    const institutionName = newestPlaidItem?.institution?.name

    if (balance === undefined) {
      analytics.funding.plaid.balanceUnavailable({ institutionName })
      return
    }
    analytics.funding.plaid.balanceRetrieved({ balance, institutionName })
  }, [fundingAmountScreenData, navigate, plaidBalanceData, setAvailableBalance, setHasSetAvailableBalance])

  useEffect(() => {
    if (!hasSetAvailableBalance) return
    const isBalanceSufficient =
      availableBalance !== undefined ? availableBalance >= PLAID_MINIMUM_BALANCE_REQUIRED : undefined
    const availableBalanceBelowMinimumRequired = isBalanceSufficient === undefined ? false : !isBalanceSufficient
    const errorI18nPath = getErrorI18nPath({
      isBalanceCheckLimitReached,
      availableBalanceBelowMinimumRequired,
    })
    setIsAvailableBalanceBelowMinimumRequired(availableBalanceBelowMinimumRequired)
    if (errorI18nPath === 'plaidFunding.amount.lowBalanceError') {
      setErrorMessageProps({ error: t(errorI18nPath), appendContactCustomerCareLink: false })
    } else {
      errorI18nPath && setErrorMessageProps({ error: t(errorI18nPath), appendContactCustomerCareLink: true })
    }
    setCanRecheckBalance(!isBalanceSufficient && !isBalanceCheckLimitReached)
  }, [
    availableBalance,
    hasSetAvailableBalance,
    navigate,
    setAvailableBalance,
    setErrorMessageProps,
    isBalanceCheckLimitReached,
    t,
  ])

  const hasBalanceError = isBalanceCheckLimitReached || isAvailableBalanceBelowMinimumRequired

  useEffect(() => {
    if (!hasSetAvailableBalance) return
    if (!fundingAmountScreenData.fundingAmounts) return

    setFundingAmounts(
      getFundingAmounts({
        availableBalance,
        fundingAmounts: fundingAmountScreenData.fundingAmounts,
        hasError: hasBalanceError,
      }),
    )
  }, [fundingAmountScreenData, availableBalance, plaidBalanceData, hasSetAvailableBalance, hasBalanceError])

  const refetchPlaidBalance = (): void => {
    getPlaidBalanceRefetch().then(incrementBalanceCheckCount)
  }
  const recheckBalance = canRecheckBalance ? refetchPlaidBalance : undefined
  const continueButtonIsLoading = isLoading || isPreparingToNavigate

  if (isLoading) {
    const loadingScreenTitle = t('plaidFunding.processingFunding')
    return <LoadingScreen title={loadingScreenTitle} />
  }

  return (
    <>
      {fundingAmounts ? (
        <PlaidFundingAmountComponent
          isContinueButtonLoading={continueButtonIsLoading}
          onSubmit={onSubmit}
          onGoBack={onGoBack}
          onRecheckBalance={recheckBalance}
          fundingAmounts={fundingAmounts}
          hasError={hasBalanceError}
          errorMessageProps={errorMessageProps}
        />
      ) : (
        <LoadingScreen />
      )}
    </>
  )
}
