import { createModel } from 'xstate/lib/model'
import { auth, Auth0Error, getTokenOrLogout, getTokenSilently } from '@/core/auth/auth-service'
import { store } from '@/main'
import { removeQueryParams } from '@/utils/navigation'
import { getURLSearchParameter } from '@/utils/url-helpers'
import { ApplicationActionEnum } from '../redux/application-redux/application-actions'

/**
 * states:
 *   - idle
 *   - redirectCallback
 *   - checkingSession
 *   - loggedIn
 *   - loggedOut
 */
export const authModel = createModel(
  {
    account: undefined as string | undefined,
  },
  {
    events: {
      'SESSION.START': () => ({}),
      'SESSION.LOGOUT': () => ({}),
      'SESSION.NO': (account: string) => ({ account }),
      'SESSION.YES': (account: string) => ({ account }),
    },
  },
)
export const authStateMachine = authModel.createMachine(
  {
    id: 'authStateMachine',
    context: authModel.initialContext,
    predictableActionArguments: true,
    initial: 'idle',
    states: {
      idle: {
        on: {
          'SESSION.START': [
            {
              target: 'redirectCallback',
              cond: () => {
                return Boolean(getURLSearchParameter('code'))
              },
            },
            {
              target: 'checkingSession',
            },
          ],
        },
      },
      redirectCallback: {
        invoke: {
          id: 'redirectCallback-fetch',
          src: 'handleRedirectCallback',
          onDone: {
            actions: ['cleanURL'],
            target: 'checkingSession',
          },
        },
        on: {
          'SESSION.NO': {
            target: 'loggedOut',
            actions: [
              authModel.assign({
                account: (_, ev) => ev.account,
              }),
            ],
          },
        },
      },
      checkingSession: {
        invoke: {
          id: 'authMachine-fetch',
          src: 'fetchAccount',
        },
        on: {
          'SESSION.YES': {
            target: 'loggedIn',
            actions: [
              authModel.assign({
                account: (_, ev) => ev.account,
              }),
            ],
          },
          'SESSION.NO': {
            target: 'loggedOut',
            actions: [
              authModel.assign({
                account: (_, ev) => ev.account,
              }),
            ],
          },
        },
      },
      loggedIn: {
        activities: ['beeping'],
        on: {
          'SESSION.LOGOUT': 'loggedOut',
        },
      },
      loggedOut: {},
    },
  },
  {
    actions: {
      cleanURL: () => {
        removeQueryParams(['code', 'state'])
      },
    },
    services: {
      handleRedirectCallback: () => async (callback) => {
        const redirectCallbackResult = await auth.handleRedirectCallback().catch(function (err) {
          if (err.error === Auth0Error.BLOCKED) {
            callback({ type: 'SESSION.NO', account: Auth0Error.BLOCKED })
          }
        })
        // The redirect login result and redux getState methods are typed as `any`
        // and they do not have generic type inputs when instantiated, so used a type assertion here
        const signupPromoCode = redirectCallbackResult?.appState?.pc as string | undefined
        const stateAlreadyHasStoredPromoCode = store.getState()?.application?.signupPromoCode as string | undefined

        if (signupPromoCode && !stateAlreadyHasStoredPromoCode) {
          store.dispatch({ type: ApplicationActionEnum.SET_SIGNUP_PROMO_CODE, payload: signupPromoCode })
        }
      },
      fetchAccount: () => async (callback) => {
        const token = await getTokenSilently().catch((error) => {
          callback({ type: 'SESSION.NO', account: error.error })
        })
        token
          ? callback({ type: 'SESSION.YES', account: 'logged_in' })
          : callback({ type: 'SESSION.NO', account: Auth0Error.LOGIN_REQUIRED })
      },
    },
    activities: {
      beeping: () => {
        const beep = () => {
          void getTokenOrLogout()
        }
        const intervalID = setInterval(beep, 5 * 60 * 1000)
        return () => clearInterval(intervalID)
      },
    },
  },
)
