export interface IUploadedFilenameState {
  files: {
    proofOfIdentityFront?: string
    proofOfIdentityBack?: string
    proofOfOwnership?: string
    businessLicense?: string
  }
  intercomConversationId?: string
}

export const initialUploadedFilenameState: IUploadedFilenameState = {
  files: {
    proofOfIdentityFront: undefined,
    proofOfIdentityBack: undefined,
    proofOfOwnership: undefined,
    businessLicense: undefined,
  },
  intercomConversationId: undefined,
}
