import { Box, CurrencyInput, NumberInput } from '@northone/ui-components'
import { unformat } from 'accounting'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ContinueButton from '@/components/ContinueButton'
import GoBackButton from '@/components/GoBackButton'
import { analytics } from '@/core/analytics/events'
import { accountUsageActions } from '@/core/redux/account-usage-redux/actions'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'
import { numberStringToOptionalNumber } from '@/utils/numberStringToOptionalNumber'

export default function MonthlyCheckDeposits() {
  const t = useOnboardingTranslations()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const numberOfTransactions = useAppSelector((state) => state.accountUsage.numberOfCheckDeposits)
  const averageAmount = useAppSelector((state) => state.accountUsage.averageCheckDepositAmount)

  const transactionType = t('accountUsage.transactionTypes.checkDeposits')

  const setAverageAmount = (value?: number) => {
    // @ts-ignore TS2345
    dispatch(accountUsageActions.setTransactionUsageValue('averageCheckDepositAmount', value))
  }
  const setNumberOfTransactions = (value?: number) => {
    // @ts-ignore TS2345
    dispatch(accountUsageActions.setTransactionUsageValue('numberOfCheckDeposits', value))
  }

  const continueIsDisabled = !numberOfTransactions || !averageAmount
  const onContinue = () => {
    analytics.accountUsage.numberOfCheckDeposits({ value: unformat(numberOfTransactions) })
    analytics.accountUsage.averageCheckDepositAmount({ value: unformat(averageAmount) })
    navigate(Pathname.USAGE_QUESTION_ACH_PAYMENTS)
  }
  const onGoBack = () => {
    navigate(Pathname.USAGE_QUESTION_MONTHLY_DEPOSIT_AMOUNT)
  }

  const tertiaryButtonProps = {
    text: t('accountUsage.numberOf.button', {
      transactionType: transactionType + 's',
    }),
    onClick: () => navigate(Pathname.USAGE_QUESTION_ACH_PAYMENTS),
  }

  const title = t(`accountUsage.numberOf.title.checkDeposits`, {
    transactionType,
  })
  const numberOfChecksLabel = t('accountUsage.numberOf.inputLabel.checkDeposits')
  const numberOfChecksHelper = t('accountUsage.numberOf.subtitle')
  const averageCheckAmountLabel = t('accountUsage.averageAmount.title', {
    transactionType: transactionType,
  })
  const averageCheckAmountHelper = t('accountUsage.numberOf.subtitle')
  const averageCheckAmountPlaceholder = t('accountUsage.dollarSignPlaceholder')

  return (
    <BaseContentLayout
      headingText={title}
      primaryButton={
        <ContinueButton
          testID="usage-check-deposits-continue"
          onPress={onContinue}
          disabled={continueIsDisabled}
          fullWidth
        />
      }
      secondaryButton={<GoBackButton testID="usage-average-amount-go-back" onPress={onGoBack} fullWidth />}
      tertiaryButtonProps={tertiaryButtonProps}
    >
      <Box sx={{ gap: '$10' }}>
        <NumberInput
          testID="number-of-checks"
          labelText={numberOfChecksLabel}
          helperText={numberOfChecksHelper}
          onChange={setNumberOfTransactions}
          value={numberStringToOptionalNumber(numberOfTransactions)}
        />

        <CurrencyInput
          testID="average-check-amount"
          labelText={averageCheckAmountLabel}
          helperText={averageCheckAmountHelper}
          placeholder={averageCheckAmountPlaceholder}
          onChange={setAverageAmount}
          value={Number(averageAmount)}
          allowDecimals={false}
        />
      </Box>
    </BaseContentLayout>
  )
}
