import { CurrencyInput } from '@northone/ui-components'
import { unformat } from 'accounting'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ContinueButton from '@/components/ContinueButton'
import GoBackButton from '@/components/GoBackButton'
import { analytics } from '@/core/analytics/events'
import { accountUsageActions } from '@/core/redux/account-usage-redux/actions'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'
import { getIsTouchDevice } from '@/utils/responsive'

export const DebitCardAmount = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const debitCardAmount = useAppSelector((state) => state.accountUsage.debitCardAmount)
  const t = useOnboardingTranslations()

  const continueIsDisabled = debitCardAmount ? false : true
  const onContinue = () => {
    navigate(Pathname.USAGE_QUESTION_MONTHLY_DEPOSIT_AMOUNT)
    analytics.accountUsage.debitCardAmount({ value: unformat(debitCardAmount) })
  }
  const onGoBack = () => {
    navigate(Pathname.USAGE_QUESTIONS)
  }

  // @ts-ignore TS2345
  const onChange = (value: string) => dispatch(accountUsageActions.setTransactionUsageValue('debitCardAmount', value))

  const title = t('accountUsage.debitCardAmount.title')
  const debitCardAmountLabel = t('accountUsage.debitCardAmount.inputLabel')
  const debitCardAmountHelper = t('accountUsage.estimateSubtitle')
  const debitCardAmountPlaceholder = t('accountUsage.dollarSignPlaceholder')

  return (
    <BaseContentLayout
      headingText={title}
      primaryButton={
        <ContinueButton
          testID="usage-debit-card-amount-continue"
          onPress={onContinue}
          disabled={continueIsDisabled}
          fullWidth
        />
      }
      secondaryButton={<GoBackButton testID="usage-debit-card-amount-go-back" onPress={onGoBack} fullWidth />}
    >
      <CurrencyInput
        testID="debit-card-amount"
        labelText={debitCardAmountLabel}
        helperText={debitCardAmountHelper}
        type={'tel'}
        placeholder={debitCardAmountPlaceholder}
        // @ts-ignore TS2345
        onChange={onChange}
        value={Number(debitCardAmount)}
        maskType="AMOUNT"
        maxLength={12}
        autoFocus={!getIsTouchDevice()}
        allowDecimals={false}
      />
    </BaseContentLayout>
  )
}
