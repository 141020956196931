export const displayOnMobile = (display: boolean): string[] =>
  display ? ['block', 'block', 'none'] : ['none', 'none', 'block']

export const getIsTouchDevice = (): boolean => {
  try {
    document.createEvent('TouchEvent')
    return true
  } catch (e) {
    return false
  }
}
