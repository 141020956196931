import { Navigate } from 'react-router-dom'

import { OnboardingApplicationStatus, useApplicationStatusSuspenseQuery } from '../../generated/graphql'
import { Pathname } from '../constants'

/**
 * Ensures that only approved applicants can view this page
 */
export const RequireApprovedApplication = ({ children }: { children: JSX.Element }) => {
  const { data } = useApplicationStatusSuspenseQuery({ fetchPolicy: 'network-only' })
  const applicationStatus = data.me?.ownerBusinesses[0]?.onboarding?.application?.status

  const wasApplicationApproved = applicationStatus === OnboardingApplicationStatus.APPROVED

  if (!wasApplicationApproved) return <Navigate to={Pathname.ROOT} replace />

  return children
}
