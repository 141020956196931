import { LegalDisclaimerText } from '@northone/ui-components'

import { useOnboardingTranslations } from '@/i18n/locales/en/en'

export default function StandardDisclaimer() {
  const t = useOnboardingTranslations()
  const disclaimer = t('disclaimers.standard')

  return <LegalDisclaimerText>{disclaimer}</LegalDisclaimerText>
}
