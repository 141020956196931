import { ThunkDispatch } from 'redux-thunk'

import { createAction, IAction, IActionWithPayload, TActionsUnion } from '../../../core/redux/utils'
import { getIsSoleProp } from '../../../utils'
import { IAddressState } from '../application-redux/application-state'
import { IRootState } from '../root-state'
import { TMailingAddress } from './state'

export enum UnpersistedActionEnum {
  SET_SSN = 'SET_SSN',
  SET_SHOW_FORM_ERRORS = 'SET_SHOW_FORM_ERRORS',
  SET_HAS_HYDRATED_STATE = 'SET_HAS_HYDRATED_STATE',
  SET_FORM_SECTION_INDEX = 'SET_FORM_SECTION_INDEX',
  SET_FORM_SCROLL_TRANSITION_TIME = 'SET_FORM_SCROLL_TRANSITION_TIME',
  FORM_SCROLL_TO_NEXT = 'FORM_SCROLL_TO_NEXT',
  FORM_SCROLL_BACK = 'FORM_SCROLL_BACK',
  SET_SCROLL_IS_DISABLED = 'SET_SCROLL_IS_DISABLED',
  SET_ONBOARDING_UI_IMPROVEMENT_EXPERIMENT = 'SET_ONBOARDING_UI_IMPROVEMENT_EXPERIMENT',
  SET_CANNOT_SERVE_USER = 'SET_CANNOT_SERVE_USER',
  SET_CONFIRM_BUSINESS_TYPE_MODAL_OPEN = 'SET_CONFIRM_BUSINESS_TYPE_MODAL_OPEN',
  SET_ADDRESS = 'SET_ADDRESS',
  SET_MAILING_ADDRESS = 'SET_MAILING_ADDRESS',
}
export const wait = (amount = 0): Promise<unknown> => new Promise((resolve) => setTimeout(resolve, amount))

export const unpersistedActions = {
  setShowErrors: (showErrors: boolean): IActionWithPayload<UnpersistedActionEnum.SET_SHOW_FORM_ERRORS, boolean> =>
    createAction(UnpersistedActionEnum.SET_SHOW_FORM_ERRORS, showErrors),

  setSSN: (payload: {
    ownerId: string
    ssn: string
  }): IActionWithPayload<
    UnpersistedActionEnum.SET_SSN,
    {
      ownerId: string
      ssn: string
    }
  > => createAction(UnpersistedActionEnum.SET_SSN, payload),

  setHasHydratedState: (
    hasHydratedState: boolean,
  ): IActionWithPayload<UnpersistedActionEnum.SET_HAS_HYDRATED_STATE, boolean> =>
    createAction(UnpersistedActionEnum.SET_HAS_HYDRATED_STATE, hasHydratedState),

  formScrollToNext:
    () =>
    (dispatch: ThunkDispatch<IRootState, void, IAction<UnpersistedActionEnum>>, getState: () => IRootState): void => {
      const businessType = getState().application.businessType
      const isSoleProp = getIsSoleProp(businessType)
      dispatch({ type: UnpersistedActionEnum.FORM_SCROLL_TO_NEXT, payload: isSoleProp })
    },
  formScrollBack: (): IAction<UnpersistedActionEnum.FORM_SCROLL_BACK> =>
    createAction(UnpersistedActionEnum.FORM_SCROLL_BACK),

  setScrollIsDisabled: (
    scrollIsDisabled: boolean,
  ): IActionWithPayload<UnpersistedActionEnum.SET_SCROLL_IS_DISABLED, boolean> =>
    createAction(UnpersistedActionEnum.SET_SCROLL_IS_DISABLED, scrollIsDisabled),

  setCannotServeUser: (
    isNotUSVitizen: boolean,
  ): IActionWithPayload<UnpersistedActionEnum.SET_CANNOT_SERVE_USER, boolean> =>
    createAction(UnpersistedActionEnum.SET_CANNOT_SERVE_USER, isNotUSVitizen),

  setConfirmBusinessTypeModalOpen: (isOpen: boolean) =>
    createAction(UnpersistedActionEnum.SET_CONFIRM_BUSINESS_TYPE_MODAL_OPEN, isOpen),

  setAddress: (
    type: 'HOME' | 'BUSINESS',
    address: IAddressState,
  ): IActionWithPayload<
    UnpersistedActionEnum.SET_ADDRESS,
    {
      type: 'HOME' | 'BUSINESS'
      address: IAddressState
    }
  > => createAction(UnpersistedActionEnum.SET_ADDRESS, { type, address }),

  setMailingAddress: (
    address: TMailingAddress,
  ): IActionWithPayload<UnpersistedActionEnum.SET_MAILING_ADDRESS, TMailingAddress> =>
    createAction(UnpersistedActionEnum.SET_MAILING_ADDRESS, address),
}

export type TUnpersistedActions =
  | TActionsUnion<Omit<typeof unpersistedActions, 'scrollToSection' | 'formScrollToNext'>>
  | { type: UnpersistedActionEnum.SET_FORM_SCROLL_TRANSITION_TIME; payload: number }
  | { type: UnpersistedActionEnum.SET_FORM_SECTION_INDEX; payload: number }
  | { type: UnpersistedActionEnum.FORM_SCROLL_TO_NEXT; payload: boolean }
