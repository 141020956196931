import { createContext, useContext, useEffect, useState } from 'react'
import { Auth0UserData, getAuth0UserData } from '@/core/auth/auth-service'

export const Auth0UserContext = createContext<Auth0UserData | undefined>(undefined)

export const Auth0UserProvider = ({ children }: { children: JSX.Element }) => {
  const [auth0User, setAuth0User] = useState<Auth0UserData>()

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAuth0UserData()
      setAuth0User(data)
    }
    fetchData()
  }, [])

  if (!auth0User) return null

  return <Auth0UserContext.Provider value={auth0User}>{children}</Auth0UserContext.Provider>
}

export const useAuth0UserContext = () => {
  const auth0User = useContext(Auth0UserContext)
  return auth0User
}
