export interface IAccountUsageState {
  debitCardAmount: string
  averageDepositAmount: string
  numberOfCheckDeposits: string
  averageCheckDepositAmount: string
  numberOfACHs: string
  averageACHAmount: string
  numberOfWires: string
  averageWireAmount: string
  numberOfCheckPayments: string
  averageCheckPaymentAmount: string
  percentRevenueOutsideUS: string
  hasRevenueOutsideUS: boolean | null
}

export const initialAccountUsageState: IAccountUsageState = {
  debitCardAmount: '',
  averageDepositAmount: '',
  numberOfCheckDeposits: '',
  averageCheckDepositAmount: '',
  numberOfACHs: '',
  averageACHAmount: '',
  numberOfWires: '',
  averageWireAmount: '',
  numberOfCheckPayments: '',
  averageCheckPaymentAmount: '',
  percentRevenueOutsideUS: '',
  hasRevenueOutsideUS: null,
}
