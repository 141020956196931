import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { analytics } from '@/core/analytics/events'
import { displayIntercomWidget } from '@/core/intercom/intercom-settings'
import { IAddressState } from '@/core/redux/application-redux/application-state'
import { unpersistedActions } from '@/core/redux/unpersisted-redux/actions'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingApplicationAddressesQuery, useUpdateUserDataMutation } from '@/generated/graphql'
import { format } from '@/utils/format'

import { MailingAddressLayout } from '../finish-up/mailing-address/mailing-address-layout'
import { gqlLocationToAddressState } from '../funding/utils'
import { useListOwnerDetails } from './hooks'
import { gqlAddressStateToLocation, gqlAddressStateToTeamMemberAddress } from './utils'

interface IShippingAddressProps {
  onShippingAddressUpdated: () => void
}

const formatHomeAndBusinessAddresses = (
  businessMultiOwnerAddress: IAddressState | undefined,
  homeMultiOwnerAddress: IAddressState | undefined,
) => {
  const businessAddress = gqlAddressStateToLocation(businessMultiOwnerAddress)
  const homeAddress = gqlAddressStateToLocation(homeMultiOwnerAddress)
  return { businessAddress, homeAddress }
}

export const ShippingAddressAdditionalOwner = ({ onShippingAddressUpdated }: IShippingAddressProps) => {
  const dispatch = useDispatch()
  const [hasError, setHasError] = useState(false)
  const [businessMultiOwnerAddress, setBusinessMultiOwnerAddress] = useState<IAddressState>()
  const [shippingAddress, setShippingAddress] = useState<IAddressState>()
  const shippingAddressType = useAppSelector((state) => state.unpersisted.mailingAddress)

  displayIntercomWidget(true)
  const businessId = useAppSelector((state) => state.application.businessId)
  const userId = useAppSelector((state) => state.application.userId)

  useOnboardingApplicationAddressesQuery({
    onCompleted: (data) => {
      const applicationData = data.me?.ownerBusinesses[0]?.onboarding?.application
      const businessAddress = applicationData?.businessAddress
      if (businessAddress) {
        setBusinessMultiOwnerAddress(gqlLocationToAddressState(businessAddress))
      }
    },
  })

  const { loadingTeamMemberApplicationAddresses, homeAddress: homeMultiOwnerAddress, error } = useListOwnerDetails()

  const [updateShippingAddress, { loading: updateLoading }] = useUpdateUserDataMutation({
    onCompleted: () => {
      setHasError(false)
      onShippingAddressUpdated()
    },
    onError: () => setHasError(true),
  })

  const updateShippingAddressAction = () => {
    updateShippingAddress({
      variables: {
        businessId,
        userId,
        userData: {
          shippingAddress: gqlAddressStateToTeamMemberAddress(shippingAddress),
        },
      },
    })
  }

  const { businessAddress, homeAddress } = formatHomeAndBusinessAddresses(
    businessMultiOwnerAddress,
    homeMultiOwnerAddress,
  )

  useEffect(() => {
    shippingAddressType === 'BUSINESS'
      ? setShippingAddress(businessMultiOwnerAddress)
      : setShippingAddress(homeMultiOwnerAddress)
  }, [shippingAddressType, businessMultiOwnerAddress, homeMultiOwnerAddress])

  const formattedBusinessAddress = format.address.gql(businessAddress) ?? ''
  const formattedHomeAddress = format.address.gql(homeAddress) ?? ''

  const onSelectedAddressChanged = (addressType: string) => {
    if (addressType === 'BUSINESS') {
      analytics.funding.mailingAddress({ addressType: 'business' })
      dispatch(unpersistedActions.setMailingAddress('BUSINESS'))
    }
    if (addressType === 'HOME') {
      analytics.funding.mailingAddress({ addressType: 'personal' })
      dispatch(unpersistedActions.setMailingAddress('HOME'))
    }
  }

  if (error) {
    throw new Error('Error using list owner details')
  }

  return (
    <MailingAddressLayout
      hasError={hasError}
      loading={loadingTeamMemberApplicationAddresses}
      updateLoading={updateLoading}
      updateAddressAction={updateShippingAddressAction}
      isButtonDisabled={!businessAddress || !homeAddress}
      homeAddress={formattedHomeAddress}
      businessAddress={formattedBusinessAddress}
      selectedAddress={shippingAddressType}
      onSelectedAddressChanged={onSelectedAddressChanged}
    />
  )
}
