import {
  Body,
  Box,
  Modal,
  PageHeader,
  PageHeaderSizeEnum,
  PrimaryButton,
  SecondaryButton,
} from '@northone/ui-components'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import businessIconsImageUrl from '@/assets/images/business-icons.png'
import { analytics } from '@/core/analytics/events'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { funnelActions } from '@/core/redux/funnel-redux/actions'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'

import { Pathname } from '../constants'

export const SolePropDetectedModal = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const t = useOnboardingTranslations()
  const businessName = useAppSelector((state) => state.application.businessName)

  const titleText = t('businessDetails.solePropDetectedModal.title')
  const bodyText = t('businessDetails.solePropDetectedModal.subtitle', { businessName })
  const applyButtonText = t('businessDetails.solePropDetectedModal.applyButton')
  const dismissButtonText = t('businessDetails.solePropDetectedModal.dismissButton')
  const businessLogosAltText = t('businessDetails.solePropDetectedModal.businessLogosAltText')

  const onApplyPressed = () => {
    analytics.funnel.freelancePromptContinue({ businessName })
    dispatch(funnelActions.setFreelancerModalOpen(false))
    navigate(Pathname.WELCOME_BUSINESS_TYPE)
  }

  const onDismissPressed = () => {
    analytics.funnel.freelancePromptDismiss({ businessName })
    dispatch(applicationActions.setBusinessName(''))
    dispatch(funnelActions.setFreelancerModalOpen(false))
  }

  return (
    <Modal>
      <Box sx={{ gap: '$8', flexShrink: 1 }}>
        <img alt={businessLogosAltText} src={businessIconsImageUrl} />
        <PageHeader size={PageHeaderSizeEnum.MD} headingLevel={2}>
          {titleText}
        </PageHeader>

        <Body>{bodyText}</Body>

        <Box sx={{ gap: '$2' }}>
          <PrimaryButton testID="business-details-soleprop-detected-apply" onPress={onApplyPressed} fullWidth>
            {applyButtonText}
          </PrimaryButton>

          <SecondaryButton testID="business-details-soleprop-detected-dismiss" onPress={onDismissPressed} fullWidth>
            {dismissButtonText}
          </SecondaryButton>
        </Box>
      </Box>
    </Modal>
  )
}
