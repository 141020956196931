import {
  Box,
  DropdownSmall,
  ICONS,
  IconWithBackground,
  SelectionBoxInputGroup,
  SizeEnum,
} from '@northone/ui-components'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ContinueButton from '@/components/ContinueButton'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'
import { BusinessType } from '@/types'

enum BusinessTypeCategory {
  SOLEPROP = 'SOLEPROP',
  PARTNERSHIP = 'PARTNERSHIP',
  CORPORATION = 'CORPORATION',
}

type CorporationBusinessType = BusinessType.CCORP | BusinessType.LLC | BusinessType.SCORP

const businessTypeToBusinessTypeCategory = new Map([
  [BusinessType.CCORP, BusinessTypeCategory.CORPORATION],
  [BusinessType.LLC, BusinessTypeCategory.CORPORATION],
  [BusinessType.SCORP, BusinessTypeCategory.CORPORATION],
  [BusinessType.SOLEPROP, BusinessTypeCategory.SOLEPROP],
  [BusinessType.FREELANCER, BusinessTypeCategory.SOLEPROP],
  [BusinessType.PARTNERSHIP, BusinessTypeCategory.PARTNERSHIP],
  [null, undefined],
])

export const WelcomeBusinessType = () => {
  const navigate = useNavigate()
  const t = useOnboardingTranslations()
  const dispatch = useDispatch()
  const businessType = useAppSelector((state) => state.application.businessType)
  const [businessTypeCategory, setBusinessTypeCategory] = useState<BusinessTypeCategory | undefined>(
    businessTypeToBusinessTypeCategory.get(businessType),
  )

  const title = t('funnel.businessType.title')

  const isDisabled = !businessType
  const shouldShowCorporationSelect =
    businessTypeCategory === BusinessTypeCategory.CORPORATION &&
    businessType !== BusinessType.FREELANCER &&
    businessType !== BusinessType.PARTNERSHIP &&
    businessType !== BusinessType.SOLEPROP

  const options = [
    {
      action: t('funnel.businessType.options.corporation.title'),
      description: t('funnel.businessType.options.corporation.description'),
      leftItem: <IconWithBackground icon={ICONS.CertifiedIcon} backgroundColor="$receiveHoneydew" size={SizeEnum.MD} />,
      value: BusinessTypeCategory.CORPORATION,
      testID: 'corporation',
    },
    {
      action: t('funnel.businessType.options.partnership.title'),
      description: t('funnel.businessType.options.partnership.description'),
      leftItem: <IconWithBackground icon={ICONS.HandshakeIcon} size={SizeEnum.MD} backgroundColor="$receiveHoneydew" />,
      value: BusinessTypeCategory.PARTNERSHIP,
      testID: 'partnership',
    },
    {
      action: t('funnel.businessType.options.soleProprietorship.title'),
      description: t('funnel.businessType.options.soleProprietorship.description'),
      leftItem: (
        <IconWithBackground icon={ICONS.SoleProprietorIcon} size={SizeEnum.MD} backgroundColor="$receiveHoneydew" />
      ),
      value: BusinessTypeCategory.SOLEPROP,
      testID: 'soleProprietorship',
    },
  ]

  const corporationOptions: { value: CorporationBusinessType; text: string; testID: string }[] = [
    { value: BusinessType.SCORP, text: t('funnel.businessType.SCORP'), testID: 'scorp' },
    { value: BusinessType.LLC, text: t('funnel.businessType.LLC'), testID: 'llc' },
    { value: BusinessType.CCORP, text: t('funnel.businessType.CCORP'), testID: 'ccorp' },
  ] as const

  const handleSelectionChange = (value: BusinessTypeCategory) => {
    setBusinessTypeCategory(value)
    if (value === BusinessTypeCategory.SOLEPROP) {
      dispatch(applicationActions.setBusinessType(BusinessType.SOLEPROP))
    }
    if (value === BusinessTypeCategory.PARTNERSHIP) {
      dispatch(applicationActions.setBusinessType(BusinessType.PARTNERSHIP))
    }
    if (value === BusinessTypeCategory.CORPORATION) {
      dispatch(applicationActions.setBusinessType(null))
    }
  }

  const handleDropdownChange = (value: BusinessType.LLC | BusinessType.SCORP | BusinessType.CCORP) => {
    dispatch(applicationActions.setBusinessType(value))
  }

  const onContinue = () => {
    dispatch(applicationActions.setBusinessType(businessType))
    navigate(Pathname.WELCOME_QUALIFIERS)
  }

  return (
    <BaseContentLayout
      primaryButton={
        <ContinueButton testID="business-type-continue" onPress={onContinue} disabled={isDisabled} fullWidth />
      }
      headingText={title}
    >
      <>
        <Box sx={{ gap: '$5' }}>
          <SelectionBoxInputGroup
            labelText={t('funnel.businessType.label')}
            options={options}
            value={businessTypeCategory}
            onChange={handleSelectionChange}
          />
          {shouldShowCorporationSelect && (
            <DropdownSmall
              options={corporationOptions}
              placeholderProps={{ text: t('funnel.businessType.placeholder') }}
              value={businessType ?? undefined}
              onChange={handleDropdownChange}
              labelText="Corporation type"
            />
          )}
        </Box>
      </>
    </BaseContentLayout>
  )
}
