import { useEffect, useRef, useState } from 'react'
import { Pathname } from '../constants'
import './in-app-document-capture-screens/document-capture.css'
import {
  ArrowIcon,
  Body,
  BodySizeEnum,
  Box,
  CertifiedIcon,
  DocumentIcon,
  IconComponent,
  IdentificationIcon,
  Loader,
  Modal,
  PageHeader,
  PageHeaderSizeEnum,
  PrimaryButton,
  PrimaryButtonProps,
  ProtectedIcon,
  SizeEnum,
} from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'
import { Pressable } from 'react-native'
import { useLocation, useNavigate } from 'react-router-dom'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { useDocumentCapture } from './in-app-document-capture-screens/use-document-capture'

const BODY_HIDE_INTERCOM_BORDERLESS_FRAME_CLASS = 'in-app-document-capture-hide-borderless-intercom-frame'
export const WAIT_FALSE_SEARCH_PARAM = 'wait=false'

export const useHideIntercomBorderlessFrame = () => {
  useEffect(() => {
    document.body.classList.add(BODY_HIDE_INTERCOM_BORDERLESS_FRAME_CLASS)
    return () => document.body.classList.remove(BODY_HIDE_INTERCOM_BORDERLESS_FRAME_CLASS)
  }, [])
}

interface DocumentUploadBulletProps {
  icon: IconComponent
  heading: string
  subHeading: string
}
const DocumentUploadBullet = ({ icon: Icon, heading, subHeading }: DocumentUploadBulletProps) => {
  return (
    <Box sx={{ flexDirection: 'row', gap: '$2.5' }}>
      <Box sx={{ justifyContent: 'flex-start' }}>
        <Icon size={SizeEnum.MD} />
      </Box>
      <Box sx={{ maxWidth: 375 }}>
        <Body size={BodySizeEnum.MD} color={'$charcoal7'}>
          {heading}
        </Body>
        <Body size={BodySizeEnum.SM}>{subHeading}</Body>
      </Box>
    </Box>
  )
}

const UploadDocumentsOverviewWeb = ({ onContinueClick }: { onContinueClick: () => void }) => {
  const { bullets, copy } = useDocumentUploadCopy()

  return (
    <Box sx={{ flex: 1, justifyContent: 'space-between' }}>
      <Box sx={{ gap: '$8' }}>
        <Box sx={{ maxWidth: 350 }}>
          <PageHeader headingLevel={1} size={PageHeaderSizeEnum.MD}>
            {copy.headerWeb}
          </PageHeader>
        </Box>
        <Box sx={{ gap: '$2' }}>
          {bullets.map((props, idx) => (
            <DocumentUploadBullet key={idx} {...props} />
          ))}
        </Box>
      </Box>
      <Box sx={{ gap: '$6' }}>
        <PrimaryButton
          fullWidth
          testID="document-capture-finished"
          icon={ArrowIcon}
          iconPosition="right"
          onPress={onContinueClick}
        >
          {copy.button}
        </PrimaryButton>
        <Box sx={{ alignItems: 'center' }}>
          <Box sx={{ flexDirection: 'row', gap: '$2', alignItems: 'center', alignContent: 'center' }}>
            <ProtectedIcon size={SizeEnum.SM} color={'$charcoal4'} />
            <Body size={BodySizeEnum.SM} color={'$charcoal4'}>
              {copy.secureStorage}
            </Body>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

interface InAppDocumentCaptureIntercomProps {
  onContinueClick: () => void
  shouldWaitForIntercomBotChat: boolean
  intercomCustomBotChatWasShown: boolean
  setIntercomCustomBotChatWasShown: (value: boolean) => void
  setShowIntercomModal?: (showModal: boolean) => void
}

const UploadDocumentsOverviewMobile = (props: InAppDocumentCaptureIntercomProps) => {
  const { copy, bullets } = useDocumentUploadCopy()
  const [showIntercomModal, setShowIntercomModal] = useState(false)
  const continueButtonProps: PrimaryButtonProps = {
    children: copy.mobileLaunchIntercom,
    onPress: () => setShowIntercomModal(true),
    testID: '',
    fullWidth: true,
  }
  return (
    <>
      {showIntercomModal ? <DocumentCaptureModal {...props} setShowIntercomModal={setShowIntercomModal} /> : null}

      <BaseContentLayout
        headingText={copy.headerMobile}
        subHeadingText={copy.subHeaderMobile}
        primaryButton={<PrimaryButton {...continueButtonProps} />}
      >
        <Box sx={{ gap: '$2' }}>
          {bullets.map((props, idx) => (
            <DocumentUploadBullet key={idx} {...props} />
          ))}
        </Box>
      </BaseContentLayout>
    </>
  )
}

export const MAX_WIDTH_MANUAL_REVIEW_MODAL = '724px'

export const ManualReviewInAppDocumentCapture = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { isMobileSize } = useMobileScreenSize()
  const [intercomCustomBotChatWasShown, setIntercomCustomBotChatWasShown] = useState(false)

  const shouldWaitForIntercomBotChat = location.search.includes(WAIT_FALSE_SEARCH_PARAM) ? false : true

  const onContinueClick = () => navigate(Pathname.DOCUMENT_UPLOAD_THANK_YOU)

  return (
    <div className="in-app-document-capture-intercom-page">
      {isMobileSize ? (
        <UploadDocumentsOverviewMobile
          onContinueClick={onContinueClick}
          intercomCustomBotChatWasShown={intercomCustomBotChatWasShown}
          shouldWaitForIntercomBotChat={!intercomCustomBotChatWasShown && shouldWaitForIntercomBotChat}
          setIntercomCustomBotChatWasShown={setIntercomCustomBotChatWasShown}
        />
      ) : (
        <DocumentCaptureModal
          intercomCustomBotChatWasShown={intercomCustomBotChatWasShown}
          shouldWaitForIntercomBotChat={!intercomCustomBotChatWasShown && shouldWaitForIntercomBotChat}
          onContinueClick={onContinueClick}
          setIntercomCustomBotChatWasShown={setIntercomCustomBotChatWasShown}
        />
      )}
    </div>
  )
}

export const DocumentCaptureModal = (props: InAppDocumentCaptureIntercomProps) => {
  const { copy } = useDocumentUploadCopy()
  const { onContinueClick, intercomCustomBotChatWasShown, setShowIntercomModal } = props
  const intercomContainerRef = useRef<HTMLDivElement>(null)

  useDocumentCapture({ ...props, intercomContainerRef })
  const { isMobileSize } = useMobileScreenSize()
  const containerSx = isMobileSize ? { gap: '$6', height: '80vh' } : { flexDirection: 'row', gap: '$10', height: 520 }
  const intercomMaxWidth = isMobileSize ? undefined : 350
  const hideIntercomModal = () => setShowIntercomModal?.(false)

  return (
    <Modal isVisible={true} maxWidth={900}>
      <Box sx={containerSx}>
        {/* ~~~~~~~INTERCOM CONTAINER~~~~~~~~ */}
        <div
          style={{
            width: '100%',
            maxWidth: intercomMaxWidth,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          ref={intercomContainerRef}
        >
          {!intercomCustomBotChatWasShown ? <Loader size={SizeEnum.LG} /> : null}
        </div>
        {/* ~~~~~~~INTERCOM CONTAINER~~~~~~~~ */}

        {isMobileSize ? (
          <Box sx={{ gap: '$6' }}>
            <PrimaryButton
              testID="in-app-document-capture.upload-done.primary-button"
              icon={ArrowIcon}
              iconPosition="right"
              onPress={onContinueClick}
              fullWidth
            >
              {copy.button}
            </PrimaryButton>
            <Pressable onPress={hideIntercomModal}>
              <Box sx={{ flexDirection: 'row', gap: '$2', alignItems: 'center', justifyContent: 'center' }}>
                <Body>{copy.viewDocumentList}</Body>
                <ArrowIcon size={SizeEnum.MD} />
              </Box>
            </Pressable>
            <Box sx={{ gap: '$0.5', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <ProtectedIcon size={SizeEnum.SM} color={'$charcoal5'} />
              <Body size={BodySizeEnum.SM}>{copy.secureStorage}</Body>
            </Box>
          </Box>
        ) : (
          <UploadDocumentsOverviewWeb onContinueClick={onContinueClick} />
        )}
      </Box>
    </Modal>
  )
}

const useDocumentUploadCopy = () => {
  const t = useOnboardingTranslations()
  const copy = {
    headerWeb: t('inAppDocumentCapture.intercom.title'),
    headerMobile: t('inAppDocumentCapture.uploadDocuments.title'),
    subHeaderMobile: t('inAppDocumentCapture.uploadDocuments.verify'),
    mobileLaunchIntercom: t('inAppDocumentCapture.uploadDocuments.imReady'),
    button: t('inAppDocumentCapture.intercom.button'),
    secureStorage: t('inAppDocumentCapture.intercom.secureDocumentStorage'),
    viewDocumentList: t('inAppDocumentCapture.intercom.viewDocumentList'),
  }

  const bullets: DocumentUploadBulletProps[] = [
    {
      icon: IdentificationIcon,
      heading: t('inAppDocumentCapture.uploadDocuments.documentBullets.id.heading'),
      subHeading: t('inAppDocumentCapture.uploadDocuments.documentBullets.id.subHeading'),
    },
    {
      icon: DocumentIcon,
      heading: t('inAppDocumentCapture.uploadDocuments.documentBullets.formationOrIncorporation.heading'),
      subHeading: t('inAppDocumentCapture.uploadDocuments.documentBullets.formationOrIncorporation.subHeading'),
    },
    {
      icon: CertifiedIcon,
      heading: t('inAppDocumentCapture.uploadDocuments.documentBullets.businessLicense.heading'),
      subHeading: t('inAppDocumentCapture.uploadDocuments.documentBullets.businessLicense.subHeading'),
    },
  ]
  return { copy, bullets }
}
