import { Box, CardText, CardTextSizeEnum, DiagonalMutedGradient2 } from '@northone/ui-components'
import { useNavigate } from 'react-router-dom'
import northoneDebitCardUrl from '@/assets/images/north-one-debit-card.svg'
import ContinueButton from '@/components/ContinueButton'
import ResponsiveOnboardingHeader from '@/components/ResponsiveOnboardingHeader'
import StandardDisclaimer from '@/components/StandardDisclaimer'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import {
  ExpectationScreenCardHalf,
  ExpectationScreenCardLayout,
  ExpectationScreenLayout,
} from '@/layouts/ExpectationScreenLayout'
import { Pathname } from '../constants'

export default function FinishUpLanding() {
  const navigate = useNavigate()
  const t = useOnboardingTranslations()

  const title = t('finishUp.landing.title')
  const subtitle = t('finishUp.landing.subtitle')

  const onContinue = () => navigate(Pathname.DEBIT_CARD_DELIVERY_ADDRESS)
  const primaryButton = <ContinueButton testID="finish-up-landing-continue" onPress={onContinue} fullWidth />

  return (
    <ExpectationScreenLayout
      primaryButton={primaryButton}
      disclaimerComponent={<StandardDisclaimer />}
      showProgressAutoSavedNote
    >
      <ExpectationScreenCardLayout>
        <ExpectationScreenCardHalf>
          <Box sx={{ gap: '$6' }}>
            <ResponsiveOnboardingHeader largerSize="md">{title}</ResponsiveOnboardingHeader>
            <CardText size={CardTextSizeEnum.XLG}>{subtitle}</CardText>
          </Box>
        </ExpectationScreenCardHalf>
        <ExpectationScreenCardHalf>
          <Box sx={{ borderRadius: 4, overflow: 'hidden' }}>
            <DiagonalMutedGradient2>
              <Box sx={{ paddingVertical: '$8', alignItems: 'center' }}>
                <img
                  src={northoneDebitCardUrl}
                  style={{ maxHeight: 350, maxWidth: '100%' }}
                  width="401"
                  height="350"
                  alt="North One debit card"
                />
              </Box>
            </DiagonalMutedGradient2>
          </Box>
        </ExpectationScreenCardHalf>
      </ExpectationScreenCardLayout>
    </ExpectationScreenLayout>
  )
}
