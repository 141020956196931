import {
  ActionItem,
  AddContactIcon,
  AlertTypeEnum,
  Box,
  InlineAlert,
  PlusIcon,
  SecondaryButton,
  SecondaryButtonProps,
  SizeEnum,
} from '@northone/ui-components'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ContinueButton from '@/components/ContinueButton'
import { analytics } from '@/core/analytics/events'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { CONTROL_PERSON_CANDIDATE_ID } from '@/core/redux/application-redux/application-state'
import { selectOwnerIds } from '@/core/redux/application-redux/owners-selectors'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'
import { OwnerProfileCard } from './OwnerProfileCard'
import { useOwnerProfileListIsValid } from './utils/owner-validation-hooks'
import { useTotalOwnershipPercentageIsValid } from './utils/use-ownership-percentage-validation'

const AddCoOwnerIcon = () => (
  <Box
    sx={{
      backgroundColor: '$receiveHoneydew',
      height: '$8',
      width: '$8',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '$sm',
    }}
  >
    <AddContactIcon size={SizeEnum.MD} />
  </Box>
)

const useCopy = (businessName: string) => {
  const t = useOnboardingTranslations()
  return {
    heading: t('ownership.profile.pageHeading', { businessName }),
    subHeading: t('ownership.profile.subHeading'),
    addCoOwner: t('ownership.profile.addCoOwner'),
    addCoOwnerDescription: t('ownership.profile.addCoOwnerDescription'),
    goBack: t('button.goBack'),
  }
}

export default function OwnershipOwnerProfilesNonSoleprop() {
  const dispatch = useDispatch()
  const businessName = useAppSelector((state) => state.application.businessName)
  const copy = useCopy(businessName)
  const navigate = useNavigate()
  const { valid: ownerProfilesAreValid } = useOwnerProfileListIsValid()
  const isOwnerBeingEdited = useAppSelector((state) => Object.entries(state.application.owners)).some(
    ([ownerId, owner]) => ownerId !== CONTROL_PERSON_CANDIDATE_ID && owner.isEditing === true,
  )

  const onContinue = () => {
    navigate(Pathname.OWNERSHIP_DETAILS_CONTROLLER)
  }
  const onBack = () => {
    navigate(Pathname.OWNERSHIP_DETAILS)
  }

  const continueButtonProps = {
    fullWidth: true,
    onPress: onContinue,
    testID: 'ownership-owner-profiles.add-coowner.continue',
    disabled: !ownerProfilesAreValid || isOwnerBeingEdited,
  }

  const secondaryButtonProps: SecondaryButtonProps = {
    fullWidth: true,
    onPress: onBack,
    children: copy.goBack,
    testID: 'ownership-owner-profiles.add-coowner.go-back',
  }

  const onPressAddOwner = () => {
    analytics.application.coOwnerAdd()
    dispatch(applicationActions.addNewOwner())
  }

  return (
    <BaseContentLayout
      headingText={copy.heading}
      subHeadingText={copy.subHeading}
      primaryButton={<ContinueButton {...continueButtonProps} />}
      secondaryButton={<SecondaryButton {...secondaryButtonProps} />}
      alertComponent={<OwnershipAlert />}
    >
      <Box sx={{ gap: '$8' }}>
        <OwnerProfiles />
        <ActionItem
          testID="ownership-owner-profiles.add-coowner.action-item"
          action={copy.addCoOwner}
          description={copy.addCoOwnerDescription}
          onPress={onPressAddOwner}
          leftItem={<AddCoOwnerIcon />}
          rightItem={<PlusIcon size={SizeEnum.SM} />}
        />
      </Box>
    </BaseContentLayout>
  )
}

const OwnerProfiles = () => {
  const ownerIds = useAppSelector(selectOwnerIds)
  // Arranges cards in descending z-index so the first card's state dropdown will appear over the second card
  return (
    <>
      {ownerIds
        .filter((id) => id !== CONTROL_PERSON_CANDIDATE_ID)
        .map((ownerId, index) => (
          <OwnerProfileCard ownerId={ownerId} key={ownerId} zIndex={ownerIds.length - index} />
        ))}
    </>
  )
}

const OwnershipAlert = () => {
  const t = useOnboardingTranslations()
  const totalOwnershipPercentageIsValid = useTotalOwnershipPercentageIsValid()
  if (totalOwnershipPercentageIsValid) return null
  return (
    <InlineAlert type={AlertTypeEnum.Error} showIcon>
      {t('ownership.profile.errors.ownershipPercentage')}
    </InlineAlert>
  )
}
