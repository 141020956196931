import { createAction, IActionWithPayload, TActionsUnion } from '../../../core/redux/utils'
import { IAccountUsageState } from './state'

export enum AccountUsageActionEnum {
  SET_TRANSACTION_USAGE_VALUE = 'ACCOUNT_USAGE_SET_TRANSACTION_USAGE_VALUE',
  SET_HAS_REVENUE_OUTSIDE_US = 'ACCOUNT_USAGE_SET_HAS_REVENUE_OUTSIDE_US',
  SET_PERCENT_REVENUE_OUTSIDE_US = 'ACCOUNT_USAGE_SET_PERCENT_REVENUE_OUTSIDE_US',
}

export type TTransactionUsageKey = keyof Omit<
  IAccountUsageState,
  'percentRevenueOutsideUS' | 'hasRevenueOutsideUS'
>

export const accountUsageActions = {
  setTransactionUsageValue: (key: TTransactionUsageKey, value: string): unknown =>
    createAction(AccountUsageActionEnum.SET_TRANSACTION_USAGE_VALUE, { key, value }),
  setHasRevenueOutsideUS: (
    hasRevenueOutsideUS: boolean,
  ): IActionWithPayload<AccountUsageActionEnum.SET_HAS_REVENUE_OUTSIDE_US, boolean> =>
    createAction(AccountUsageActionEnum.SET_HAS_REVENUE_OUTSIDE_US, hasRevenueOutsideUS),
  setPercentRevenueOutsideUS: (
    percentRevenueOutsideUS: string,
  ): IActionWithPayload<AccountUsageActionEnum.SET_PERCENT_REVENUE_OUTSIDE_US, string> =>
    createAction(AccountUsageActionEnum.SET_PERCENT_REVENUE_OUTSIDE_US, percentRevenueOutsideUS),
}

export type TAccountUsageActions = TActionsUnion<typeof accountUsageActions>
