import {
  Body,
  BodySizeEnum,
  Box,
  FlatButton,
  ICONS,
  Modal,
  OnboardingHeader,
  OnboardingHeaderSizeEnum,
  PrimaryButton,
} from '@northone/ui-components'

import { useOnboardingTranslations } from '@/i18n/locales/en/en'

interface ProofOfOwnershipModalProps {
  isProofOfOwnershipModalVisible: boolean
  onDoNotHaveAnyOfThesePressed: () => void
  onUploadButtonPressed: () => void
}

export const ProofOfOwnershipModal = ({
  isProofOfOwnershipModalVisible = false,
  onDoNotHaveAnyOfThesePressed,
  onUploadButtonPressed,
}: ProofOfOwnershipModalProps) => {
  const t = useOnboardingTranslations()

  const title = t('inAppDocumentCapture.proofOfOwnershipModal.title')
  const preferredSubHeading = t('inAppDocumentCapture.proofOfOwnershipModal.preferred.subHeading')
  const incorporationArticle = t('inAppDocumentCapture.proofOfOwnershipModal.preferred.incorporationArticle')
  const charter = t('inAppDocumentCapture.proofOfOwnershipModal.preferred.charter')
  const association = t('inAppDocumentCapture.proofOfOwnershipModal.preferred.association')
  const incorporationCertificate = t('inAppDocumentCapture.proofOfOwnershipModal.preferred.incorporationCertificate')

  const otherSubHeading = t('inAppDocumentCapture.proofOfOwnershipModal.other.otherSubHeading')
  const nameRegistration = t('inAppDocumentCapture.proofOfOwnershipModal.other.nameRegistration')
  const partnership = t('inAppDocumentCapture.proofOfOwnershipModal.other.partnership')
  const limitedPartnership = t('inAppDocumentCapture.proofOfOwnershipModal.other.limitedPartnership')
  const resolution = t('inAppDocumentCapture.proofOfOwnershipModal.other.resolution')
  const operatingAgreement = t('inAppDocumentCapture.proofOfOwnershipModal.other.operatingAgreement')
  const estate = t('inAppDocumentCapture.proofOfOwnershipModal.other.estate')
  const trust = t('inAppDocumentCapture.proofOfOwnershipModal.other.trust')
  const businessLicense = t('inAppDocumentCapture.proofOfOwnershipModal.other.businessLicense')
  const governmentCertificate = t('inAppDocumentCapture.proofOfOwnershipModal.other.governmentCertificate')

  const uploadNowButtonText = t('inAppDocumentCapture.proofOfIdentityModal.uploadNowButtonText')
  const noneOfTheseButtonText = t('inAppDocumentCapture.proofOfIdentityModal.noneOfTheseButtonText')

  return (
    <Modal isVisible={isProofOfOwnershipModalVisible}>
      <Box sx={{ gap: '$6', flexShrink: 1 }}>
        <OnboardingHeader headingLevel={1} size={OnboardingHeaderSizeEnum.MD}>
          {title}
        </OnboardingHeader>
        <Box sx={{ gap: '$6' }}>
          <Box sx={{ gap: '$2' }}>
            <Body color={'$charcoal7'} size={BodySizeEnum.LG}>
              {preferredSubHeading}
            </Body>
            <Body>
              <ul style={{ marginBlockStart: 0, marginBlockEnd: 0, paddingInlineStart: 24 }}>
                <li>{incorporationArticle}</li>
                <li>{charter}</li>
                <li>{association}</li>
                <li>{incorporationCertificate}</li>
              </ul>
            </Body>
          </Box>
          <Box sx={{ gap: '$2' }}>
            <Body color={'$charcoal7'} size={BodySizeEnum.LG}>
              {otherSubHeading}
            </Body>
            <Box>
              <Body>
                <ul style={{ marginBlockStart: 0, marginBlockEnd: 0, paddingInlineStart: 24 }}>
                  <li>{nameRegistration}</li>
                  <li>{partnership}</li>
                  <li>{limitedPartnership}</li>
                  <li>{resolution}</li>
                  <li>{operatingAgreement}</li>
                  <li>{estate}</li>
                  <li>{trust}</li>
                  <li>{businessLicense}</li>
                  <li>{governmentCertificate}</li>
                </ul>
              </Body>
            </Box>
          </Box>
        </Box>
        <Box sx={{ gap: '$2' }}>
          <PrimaryButton testID="types-of-ownership-modal-upload-now-button" onPress={onUploadButtonPressed} fullWidth>
            {uploadNowButtonText}
          </PrimaryButton>
          <FlatButton
            icon={ICONS.ArrowIcon}
            iconPosition="right"
            testID="types-of-ownership-modal-dismiss"
            onPress={onDoNotHaveAnyOfThesePressed}
            fullWidth
          >
            {noneOfTheseButtonText}
          </FlatButton>
        </Box>
      </Box>
    </Modal>
  )
}
