import { Modal, Box, PageHeader, BodySizeEnum, Body, PageHeaderSizeEnum, PrimaryButton } from '@northone/ui-components'

import { useOnboardingTranslations } from '@/i18n/locales/en/en'

const modalContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',
}

interface EINModalProps {
  isEINVisible: boolean
  onClose: () => void
}

export const EINModal = ({ isEINVisible = false, onClose }: EINModalProps) => {
  const t = useOnboardingTranslations()
  return (
    <Modal isVisible={isEINVisible}>
      <Box sx={modalContainerStyle}>
        <PageHeader color={'$charcoal7'} headingLevel={2} size={PageHeaderSizeEnum.SM}>
          {t('gettingStarted.modal.EIN.title')}
        </PageHeader>
        <Box sx={{ gap: '$2' }}>
          <Body size={BodySizeEnum.MD}>{t('gettingStarted.modal.EIN.checkLetter')}</Body>
          <Body size={BodySizeEnum.MD}>{t('gettingStarted.modal.EIN.checkDocuments')}</Body>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Body size={BodySizeEnum.MD}>
              {t('gettingStarted.modal.EIN.contactInstruction')} {t('gettingStarted.modal.EIN.contactNumber')}
            </Body>
          </Box>
        </Box>
        <PrimaryButton fullWidth testID={'done-button'} onPress={onClose}>
          {t('button.done')}
        </PrimaryButton>
      </Box>
    </Modal>
  )
}
