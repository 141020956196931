import { useState } from 'react'
import LoadingScreen from '@/components/LoadingScreen'
import { useFeatureFlag } from '@/core/feature-flags/use-feature-flag'
import { displayIntercomWidget } from '@/core/intercom/intercom-settings'
import { getAccountUsageSubmitVariables } from '@/core/redux/account-usage-redux/selectors'
import { useAppSelector } from '@/core/redux/utils'
import {
  OnboardingAccountStatus,
  PlaidFundingStatusEnum,
  useCelebrationScreenLazyQuery,
  useOnboardingMeQuery,
} from '@/generated/graphql'
import { navigateToWebBanking } from '../funding/utils'
import { WelcomeContent } from './welcome-content'

export const WelcomeScreen = () => {
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const variables = useAppSelector(getAccountUsageSubmitVariables)
  const isPlaidEnabled = useFeatureFlag('joindot-initial-funding') ?? true

  const { data: meData } = useOnboardingMeQuery({ fetchPolicy: 'network-only' })
  const status = meData?.me?.ownerBusinesses[0]?.onboarding?.application?.status
  const applicationId = meData?.me?.ownerBusinesses[0]?.onboarding?.application?.id

  const [getPlaidFundingStatus, { stopPolling: stopPollingCelebrationScreenQuery }] = useCelebrationScreenLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    pollInterval: 2000,
    onCompleted: (data) => {
      const accountStatus = data.business.onboarding?.accountStatus
      const fundingStatus = data.getFundingStatus?.status
      if (fundingStatus === undefined || accountStatus === undefined) return
      if (isPlaidEnabled && fundingStatus === PlaidFundingStatusEnum.UNKNOWN_ERROR) {
        return
      }
      if (!isPlaidEnabled && accountStatus !== OnboardingAccountStatus.OPENED) {
        return
      }
      stopPollingAndNavigateToWebBanking()
    },
    onError: () => {
      setIsLoading(false)
      setHasError(true)
    },
    variables: { businessId: variables.data.businessId },
  })

  displayIntercomWidget(true)

  const stopPollingAndNavigateToWebBanking = () => {
    stopPollingCelebrationScreenQuery()
    setIsLoading(false)
    navigateToWebBanking()
  }

  const handleOnSubmit = () => {
    setIsLoading(true)
    getPlaidFundingStatus()
  }

  if (!status || !applicationId) return <LoadingScreen />

  return <WelcomeContent hasError={hasError} loading={isLoading} submit={handleOnSubmit} />
}
