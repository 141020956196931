import { AlertTypeEnum, ArrowIcon, Body, Box, FlatButton, InlineAlert, SecondaryButton } from '@northone/ui-components'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import LoadingScreen from '@/components/LoadingScreen'
import { analytics } from '@/core/analytics/events'
import { getTokenOrLogout } from '@/core/auth/auth-service'
import { Intercom } from '@/core/intercom'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { PRIMARY_OWNER_ID } from '@/core/redux/application-redux/application-state'
import { useAppSelector } from '@/core/redux/utils'
import { useEmailVerifiedQuery, useResendVerificationEmailMutation } from '@/generated/graphql'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'

import { Pathname } from '../constants'
import { useAuth0UserContext } from '../providers/auth0-user'

const ErrorBanner = () => {
  const t = useOnboardingTranslations()
  return <InlineAlert type={AlertTypeEnum.Error}>{t('emailVerify.error')}</InlineAlert>
}

interface VerifyDetailsProps {
  email: string
}

const VerifyDetails = ({ email }: VerifyDetailsProps) => {
  const t = useOnboardingTranslations()

  return (
    <Box>
      <Body>{t('emailVerify.details')}</Body>
      <Body>{email}</Body>
    </Box>
  )
}

interface ResendButtonProps {
  onResendEmail: () => void
  loading: boolean
}

const ResendButton = ({ onResendEmail, loading }: ResendButtonProps) => {
  const t = useOnboardingTranslations()

  return (
    <SecondaryButton
      testID={'resend-email-verification-button'}
      fullWidth={true}
      onPress={onResendEmail}
      loading={loading}
    >
      {t('emailVerify.resend')}
    </SecondaryButton>
  )
}

const customerCareLayoutStyles = {
  display: 'flex',
  flexDirection: 'row',
  gap: '$2',
  justifyContent: 'center',
}

const ContactCustomerCare = () => {
  const t = useOnboardingTranslations()

  const onPress = () => {
    Intercom.show()
    analytics.emailVerify.support()
  }

  return (
    <Box sx={customerCareLayoutStyles}>
      <Body>{t('emailVerify.csHelp')}</Body>
      <FlatButton fullWidth={false} testID={'contact-customer-care-button'} icon={ArrowIcon} onPress={onPress}>
        {t('emailVerify.cs')}
      </FlatButton>
    </Box>
  )
}

export const EmailVerify = () => {
  const t = useOnboardingTranslations()
  // Throw an error if owner or owner email are undefined
  const email = useAppSelector((state) => {
    const owner = state.application.owners[PRIMARY_OWNER_ID]
    if (!owner) {
      throw new Error(`Owner with ID ${PRIMARY_OWNER_ID} not found`)
    }
    if (!owner.email) {
      throw new Error('Email is undefined for the primary owner')
    }
    return owner.email
  })
  const [hasResendError, setHasResendError] = useState(false)
  const [shouldShowLoadingScreen, setShouldShowLoadingScreen] = useState(true)
  const [resendEmail, { loading }] = useResendVerificationEmailMutation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth0UserData = useAuth0UserContext()

  const { error, stopPolling } = useEmailVerifiedQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 2_000,
    notifyOnNetworkStatusChange: true,
    skip: auth0UserData?.emailVerified,
    onCompleted: (data) => {
      if (!data.me?.emailVerified) {
        setShouldShowLoadingScreen(false)
        return
      }

      stopPolling()
      getTokenOrLogout({ ignoreCache: true }).then(() => {
        // This is currently required for the useApplyPromoCode hook
        // Now that the email verified screen redirects to JoinDot,
        // this means there can be two JoinDot browser sessions open when the email verified link is clicked.
        // This dispatch ensures that the promo code can be applied
        // without refresh when two browser tabs are open
        dispatch(applicationActions.setEmailVerified(true))
        navigate(Pathname.WELCOME_GETTING_STARTED)
      })
    },
  })

  const onResendEmail = () => {
    analytics.emailVerify.resend()
    setHasResendError(false)
    resendEmail({
      variables: { email },
    }).catch(() => {
      setHasResendError(true)
    })
  }

  useEffect(() => {
    if (auth0UserData?.emailVerified) {
      navigate(Pathname.WELCOME_GETTING_STARTED)
      return
    }
  }, [auth0UserData, navigate])

  if (shouldShowLoadingScreen || !email) return <LoadingScreen />

  const title = t('emailVerify.title')

  if (error) {
    throw new Error(error.message)
  }

  return (
    <BaseContentLayout
      subHeadingText={<VerifyDetails email={email} />}
      alertComponent={hasResendError ? <ErrorBanner /> : null}
      headingText={title}
    >
      <ResendButton onResendEmail={onResendEmail} loading={loading} />
      <ContactCustomerCare />
    </BaseContentLayout>
  )
}
