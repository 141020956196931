import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { addSourceToAnalytics } from '@/core/analytics/middleware'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { useAppSelector } from '@/core/redux/utils'

import { SOCURE_SCRIPT_URL } from './constants'
import { config } from './environment'

const BODY_HIDE_INTERCOM_BORDERLESS_FRAME_CLASS = 'in-app-document-capture-hide-borderless-intercom-frame'
export const useHideIntercomBorderlessFrame = () => {
  useEffect(() => {
    document.body.classList.add(BODY_HIDE_INTERCOM_BORDERLESS_FRAME_CLASS)
    return () => document.body.classList.remove(BODY_HIDE_INTERCOM_BORDERLESS_FRAME_CLASS)
  }, [])
}

interface WindowDimensions {
  width: number
  height: number
}

export const useWindowDimensions = (): WindowDimensions => {
  const getWindowDimensions = (): WindowDimensions => {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  useEffect(() => {
    function handleResize(): void {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return (): void => window.removeEventListener('resize', handleResize)
  }, [])
  return windowDimensions
}

interface KeyboardEventName {
  onArrowUp?: () => void
  onArrowRight?: () => void
  onArrowDown?: () => void
  onArrowLeft?: () => void
  onTab?: () => void
  onEnter?: () => void
  enabled?: boolean
}

export const useKeyboardEvent = ({
  onArrowUp,
  onArrowDown,
  onArrowLeft,
  onArrowRight,
  onTab,
  onEnter,
  enabled = true,
}: KeyboardEventName): void => {
  const onKeyDown = (event: KeyboardEvent): void => {
    if (!enabled) {
      return
    }
    if (event.key === 'ArrowUp' && onArrowUp) {
      event.preventDefault()
      onArrowUp()
    }
    if (event.key === 'ArrowRight' && onArrowRight) {
      event.preventDefault()
      onArrowRight()
    }
    if (event.key === 'ArrowDown' && onArrowDown) {
      event.preventDefault()
      onArrowDown()
    }
    if (event.key === 'ArrowLeft' && onArrowLeft) {
      event.preventDefault()
      onArrowLeft()
    }
    if (event.key === 'Tab' && onTab) {
      event.preventDefault()
      onTab()
    }
    if (event.key === 'Enter' && onEnter) {
      onEnter()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return (): void => document.removeEventListener('keydown', onKeyDown)
  })
}

export const useBrowserOnBackClick = (onBackClick: () => void): void => {
  useEffect(() => {
    const onBackPress = (ev: { preventDefault: () => void }): void => {
      ev.preventDefault()
      onBackClick()
    }

    window.addEventListener('popstate', onBackPress)
    return (): void => {
      window.removeEventListener('popstate', onBackPress)
    }
  })
}

export const useAddBusinessIdFieldToAnalytics = (): void => {
  const businessId = useAppSelector((state) => state.application.businessId)
  useEffect(() => {
    if (!businessId) return
    addSourceToAnalytics({ key: 'businessId', value: businessId }, 'properties')
  }, [businessId])
}

export const useScript = (url: string, scriptId?: string): void => {
  useEffect(() => {
    if (!url) return
    const script = document.createElement('script')

    script.src = url
    script.async = true
    script.id = scriptId ?? ''
    script.type = 'text/javascript'

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url, scriptId])
}

export const useSetSocureDeviceSessionId = (): void => {
  useScript(SOCURE_SCRIPT_URL, 'socure-script')

  const dispatch = useDispatch()

  useEffect(() => {
    const socureFigmaScript = document.getElementById('socure-script')

    if (!socureFigmaScript) return

    const deviceFPOptions = {
      publicKey: config.socureSdkKey,
      endpoint: config.northoneSocureEndpoint,
      userConsent: true,
      context: 'signup',
    }

    socureFigmaScript.onload = () =>
      devicer.run(deviceFPOptions, (response) => {
        dispatch(applicationActions.setSocureDeviceSessionId(response.sessionId))
      })
  }, [dispatch])
}
