import { PlaidFundingActionEnum, TPlaidFundingActions } from './actions'
import { IPlaidFundingState, initialPlaidFundingState } from './state'

export const plaidFundingReducer = (
  state: IPlaidFundingState = initialPlaidFundingState,
  action: TPlaidFundingActions,
): IPlaidFundingState => {
  switch (action.type) {
    case PlaidFundingActionEnum.SET_PLAID_FUNDING_ITEM_ID:
      return {
        ...state,
        itemId: action.payload,
      }
    case PlaidFundingActionEnum.SET_PLAID_FUNDING_LINK_TOKEN:
      return {
        ...state,
        linkToken: action.payload,
      }
    case PlaidFundingActionEnum.SET_PLAID_FUNDING_ACCOUNT_ID:
      return {
        ...state,
        accountId: action.payload,
      }
    case PlaidFundingActionEnum.SET_PLAID_FUNDING_PUBLIC_TOKEN:
      return {
        ...state,
        publicToken: action.payload,
      }
    case PlaidFundingActionEnum.SET_PLAID_FUNDING_INSTITUTION_NAME:
      return {
        ...state,
        institutionName: action.payload,
      }
    default:
      return state
  }
}
