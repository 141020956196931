/* eslint-disable no-unused-vars */
import { PRIMARY_OWNER_ID } from '@/core/redux/application-redux/application-state'
import { useAppSelector } from '@/core/redux/utils'

export const useOwnerHasDuplicateEmail = (email: string, ownerId: string) => {
  const ownerProfileMap = useAppSelector((state) => state.application.owners)
  // Users should change co-owner email if it is the primary owner's email
  if (ownerId === PRIMARY_OWNER_ID) return false
  const emailLowerCase = email.toLowerCase()

  const { [ownerId]: _currentOwner, ...otherOwners } = ownerProfileMap
  const otherOwnerLowerCaseEmails = Object.values(otherOwners).map((ownerProfile) => ownerProfile.email.toLowerCase())
  const hasDuplicateEmail = otherOwnerLowerCaseEmails.some(
    (otherOwnerLowerCaseEmail) => emailLowerCase === otherOwnerLowerCaseEmail,
  )

  return hasDuplicateEmail
}
