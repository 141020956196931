import {
  AlertTypeEnum,
  Body,
  BodySizeEnum,
  Box,
  InlineAlert,
  Link,
  PrimaryButton,
  SecondaryButton,
  SizeEnum,
  UploadIcon,
} from '@northone/ui-components'
import { ChangeEvent } from 'react'

import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { MAX_FILE_SIZE_MB } from '@/routes/document-upload/use-base64-file-conversion'

import Loader from './Loader'

export interface UploadAttachmentProps {
  onChange: (file: File) => void
  hasError?: boolean
  buttonType?: 'primary' | 'secondary'
  isLoading?: boolean
}

export const UploadAttachment = ({ onChange, hasError, buttonType = 'primary', isLoading }: UploadAttachmentProps) => {
  const t = useOnboardingTranslations()
  const copy = {
    header: t('inAppDocumentCapture.uploadAttachment.header'),
    or: t('inAppDocumentCapture.uploadAttachment.or'),
    browseFiles: t('inAppDocumentCapture.uploadAttachment.browseFiles'),
    acceptedFiles: t('inAppDocumentCapture.uploadAttachment.acceptedFiles', { sizeLimit: `${MAX_FILE_SIZE_MB}mb` }),
    error: t('inAppDocumentCapture.uploadAttachment.error', { sizeLimit: `${MAX_FILE_SIZE_MB}mb` }),
    contactSupport: t('inAppDocumentCapture.uploadAttachment.contactSupport'),
  }
  const onFileInputChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0)
    if (!file) return

    //analytics
    onChange(file)
  }
  const onPressBrowseFiles = () => {
    // intentional noop
    // user cannot click on this button because the file input is on top
  }
  const onPressContactCustomerCare = () => {
    window.Intercom('show')
  }

  const errorBox = hasError ? (
    <Box sx={{ zIndex: 3, p: '$4' }}>
      <InlineAlert type={AlertTypeEnum.Error} showIcon={true}>
        <Body color="$error5">
          {copy.error}
          <Link onPress={onPressContactCustomerCare}>{copy.contactSupport}</Link>
        </Body>
      </InlineAlert>
    </Box>
  ) : null
  const BrowseFilesButton = buttonType === 'secondary' ? SecondaryButton : PrimaryButton

  return (
    <Box
      sx={{
        flex: 1,
        bg: '$white1',
        borderWidth: 1,
        borderColor: '$charcoal1',
        borderRadius: '$sm',
        position: 'relative',
      }}
    >
      <input
        type="file"
        accept=".pdf,.jpg,.jpeg,.png"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: 0,
          cursor: 'pointer',
          zIndex: 2,
        }}
        onChange={onFileInputChanged}
      />
      {errorBox}
      <Box sx={{ p: '$6', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        {isLoading ? (
          <Loader />
        ) : (
          <Box sx={{ gap: '$2', alignItems: 'center', py: '$4' }}>
            <XLUploadIcon />
            <Box sx={{ gap: '$4', alignItems: 'center' }}>
              <Box sx={{ gap: '$1', maxWidth: 304, alignItems: 'center' }}>
                <Body size={BodySizeEnum.LG} color="$charcoal7">
                  {copy.header}
                </Body>
                <Body size={BodySizeEnum.MD} color="$charcoal5">
                  {copy.or}
                </Body>
              </Box>
              <Box>
                <BrowseFilesButton onPress={onPressBrowseFiles} testID="upload-attachment.browse-files-button">
                  {copy.browseFiles}
                </BrowseFilesButton>
              </Box>
              <Body size={BodySizeEnum.XS}>{copy.acceptedFiles}</Body>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const XLUploadIcon = () => {
  // consider adding XL to the allowed sizes for icons in ui-components
  // though this may be a "snowflake" icon size, so perhaps not
  return <UploadIcon color="$charcoal7" size={SizeEnum.XL as SizeEnum.LG} />
}
