import {
  ActionItem,
  Body,
  BodySizeEnum,
  Box,
  CurrencyInput,
  FlatButton,
  ICONS,
  LegalDisclaimerText,
} from '@northone/ui-components'
import { formatMoney, unformat } from 'accounting'
import { useEffect, useState } from 'react'

import ContinueButton from '@/components/ContinueButton'
import { ErrorBanner } from '@/components/ErrorBanner'
import GoBackButton from '@/components/GoBackButton'
import { analytics } from '@/core/analytics/events'
import { useAppSelector } from '@/core/redux/utils'
import { PlaidItemStatus, useGetPlaidFundingScreenDataQuery } from '@/generated/graphql'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'

import { AccountLogo } from './accountLogo.component'
import { TPlaidInitialFundingAmounts } from './useGetFundingAmounts'

interface TPlaidFundingAmountComponent {
  onSubmit: (amount: number) => void
  onGoBack: () => void
  onRecheckBalance?: () => void
  fundingAmounts: TPlaidInitialFundingAmounts
  isContinueButtonLoading: boolean
  hasError?: boolean
  errorMessageProps?: { error: string; appendContactCustomerCareLink: boolean }
}

export const PlaidFundingAmountComponent = ({
  onSubmit,
  onGoBack,
  onRecheckBalance,
  fundingAmounts,
  isContinueButtonLoading,
  hasError,
  errorMessageProps,
}: TPlaidFundingAmountComponent) => {
  const t = useOnboardingTranslations()
  const { defaultAmount, minimumAmount, maximumAmount } = fundingAmounts

  const [fundingAmount, setFundingAmount] = useState<number>(defaultAmount)

  useEffect(() => {
    setFundingAmount(defaultAmount)
  }, [fundingAmounts])

  const isOutsideFundingLimits = () => {
    return fundingAmount < minimumAmount || fundingAmount > maximumAmount
  }

  const onContinueButtonPress = () => {
    if (!fundingAmount) return
    analytics.funding.fundingAmount({ amount: fundingAmount })
    onSubmit(fundingAmount)
  }
  const isContinueButtonDisabled = isOutsideFundingLimits() || hasError

  const onAmountChange = (amount: number) => {
    setFundingAmount(unformat(amount.toString()))
  }

  const title = t('plaidFunding.amount.title')
  const subtitle = t('plaidFunding.amount.subtitle')
  const inputLabel = t('plaidFunding.amount.inputLabel')
  const inputHelper = t('plaidFunding.amount.inputHelper', {
    minimumAmountDisplay: formatMoney(minimumAmount, '$', 0),
    maximumAmountDisplay: formatMoney(maximumAmount, '$', 0),
  })
  const transferLabel = t('plaidFunding.amount.transferLabel')
  const inputError = isOutsideFundingLimits() ? inputHelper : ''
  const legalDisclaimer = t('disclaimers.standard')
  const recheckBalance = t('plaidFunding.amount.recheckBalance')

  const { businessId } = useAppSelector((state) => state.application)

  const { data } = useGetPlaidFundingScreenDataQuery({
    variables: { businessId },
  })

  const activePlaidItem = data?.plaidItems?.data.find((item) => item.status === PlaidItemStatus.ACTIVE)
  const institutionName = activePlaidItem?.institution?.name ?? 'External Bank Account'
  const accountNumber = activePlaidItem?.maskedAccountNumber ? `•• ${activePlaidItem.maskedAccountNumber}` : undefined
  const logo = activePlaidItem?.institution?.logo ?? undefined

  const availableBalance = data?.getPlaidBalance?.data?.accounts?.[0]?.balances?.available
  const availableBalanceDisplay = availableBalance ? formatMoney(availableBalance, '$', 2) : ''

  return (
    <BaseContentLayout
      headingText={title}
      subHeadingText={subtitle}
      alertComponent={errorMessageProps ? <ErrorBanner {...errorMessageProps} /> : null}
      primaryButton={
        <ContinueButton
          testID="plaid-funding-amount-continue"
          onPress={onContinueButtonPress}
          loading={isContinueButtonLoading}
          disabled={isContinueButtonDisabled}
          fullWidth
        />
      }
      secondaryButton={<GoBackButton testID="plaid-funding-amount-go-back" onPress={onGoBack} fullWidth />}
      disclaimerComponent={<LegalDisclaimerText color={'$charcoal5'}>{legalDisclaimer}</LegalDisclaimerText>}
    >
      <Box sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '$4' }}>
        <CurrencyInput
          testID="funding-amount"
          labelText={inputLabel}
          helperText={inputHelper}
          errorText={inputError}
          placeholder={defaultAmount.toString()}
          onChange={onAmountChange}
          value={fundingAmount}
          allowDecimals={false}
          isDisabled={hasError}
        />
        <Box sx={{ gap: '$1', width: '$full' }}>
          <Body size={BodySizeEnum.XS}>{transferLabel}</Body>
          <ActionItem
            testID="plaid-transfer-bank-info"
            leftItem={<AccountLogo logo={logo} institutionName={institutionName} />}
            action={institutionName}
            description={accountNumber}
            label={availableBalanceDisplay}
          />
        </Box>

        {onRecheckBalance ? (
          <Box>
            <FlatButton
              onPress={onRecheckBalance}
              icon={ICONS.ExchangeIcon}
              testID="plaid-funding-amount-recheck-balance"
            >
              {recheckBalance}
            </FlatButton>
          </Box>
        ) : null}
      </Box>
    </BaseContentLayout>
  )
}
