import { applyMiddleware, compose, createStore, Store } from 'redux'
import thunk from 'redux-thunk'
import { createRootReducer } from './root-reducer'
import { IRootState } from './root-state'
import { persistDataToBackendMiddleware } from '../persist-state'

export const configureStore = (preloadedState?: IRootState): { store: Store } => {
  const rootReducer = createRootReducer()

  const typedWindow: any = window
  const composeEnhancers =
    typeof typedWindow === 'object' && typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose

  const enhancers = composeEnhancers(applyMiddleware(thunk, persistDataToBackendMiddleware))

  const store = createStore(rootReducer, preloadedState, enhancers)

  return { store }
}
