import { createContext, ReactNode } from 'react'
import { Pathname } from '../../constants'
import { FileUploadNavigation, useFileUploadNavigation } from '../use-file-upload-navigation'

export interface FileUploadNavigationState {
  getFileUploadNavigation: (currentPath: Pathname) => FileUploadNavigation
}

export const FileUploadNavigationContext = createContext<FileUploadNavigationState | null>(null)

export const FileUploadNavigationContextProvider = ({ children }: { children: ReactNode }) => {
  const getFileUploadNavigation = useFileUploadNavigation()
  return (
    <FileUploadNavigationContext.Provider value={{ getFileUploadNavigation }}>
      {children}
    </FileUploadNavigationContext.Provider>
  )
}
