import { useAppSelector } from '@/core/redux/utils'
import useBusinessType from '@/hooks/useBusinessType'
import { validate } from '@/utils/validate'

export const useIsBusinessProfileValid = () => {
  const { isSoleProp } = useBusinessType()
  const {
    businessAddress,
    businessDescription,
    businessName,
    businessPhoneNumber,
    businessIndustry,
    yearsInOperation,
    ein,
  } = useAppSelector((state) => state.application)

  const businessAddressIsValid = validate.address(businessAddress)
  const businessDescriptionIsValid = validate.description(businessDescription)
  const businessPhoneIsValid = validate.phoneNumber(businessPhoneNumber)
  const businessIndustryIsValid = Boolean(businessIndustry)
  const yearsInOperationIsValid = Boolean(yearsInOperation)
  const businessNameIsValid = isSoleProp ? true : validate.businessName(businessName)
  const einIsValid = isSoleProp ? true : validate.ein(ein)

  const checks = {
    businessAddressIsValid,
    businessDescriptionIsValid,
    businessPhoneIsValid,
    businessIndustryIsValid,
    businessNameIsValid,
    einIsValid,
    yearsInOperationIsValid,
  }

  const businessProfileIsValid = Object.values(checks).every((check) => check)

  return {
    valid: businessProfileIsValid,
    checks,
  } as const
}
