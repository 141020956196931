import { useState } from 'react'
import { useAppSelector } from '@/core/redux/utils'
import { errorMessages } from '@/utils/error-messages'

interface BusinessContactInformationArgs {
  businessPhoneNumber?: string
  streetAddress?: string
  suiteNumber?: string
  city?: string
  americanState?: string
  zipCode?: string
  yearsAtBusinessAddress?: string
}
type FieldErrors = BusinessContactInformationArgs

export const useBusinessContactInformationValidation = (): {
  hasError: boolean
  setShowErrors: (showErrors: boolean) => void
  fieldErrors: FieldErrors
} => {
  const {
    businessAddress: { streetAddress, suite, city, state, zipCode },
    businessPhoneNumber,
    yearsAtLocation,
  } = useAppSelector((state) => state.application)
  const [showErrors, setShowErrors] = useState(false)

  const fieldErrors = {
    businessPhoneNumber: errorMessages.phoneNumber(businessPhoneNumber),
    streetAddress: errorMessages.streetAddress(streetAddress),
    suiteNumber: errorMessages.suite(suite),
    city: errorMessages.city(city),
    americanState: errorMessages.state(state),
    zipCode: errorMessages.zipCode(zipCode),
    yearsAtBusinessAddress: errorMessages.yearsAtLocation(yearsAtLocation),
  }
  const hasError = Object.values(fieldErrors).some((value) => Boolean(value))

  return {
    fieldErrors: showErrors ? fieldErrors : {},
    hasError,
    setShowErrors,
  }
}
