import { Box, CurrencyInput, NumberInput } from '@northone/ui-components'
import { unformat } from 'accounting'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ContinueButton from '@/components/ContinueButton'
import GoBackButton from '@/components/GoBackButton'
import LoadingScreen from '@/components/LoadingScreen'
import { analytics } from '@/core/analytics/events'
import { accountUsageActions } from '@/core/redux/account-usage-redux/actions'
import { getAccountUsageSubmitVariables } from '@/core/redux/account-usage-redux/selectors'
import { useAppSelector } from '@/core/redux/utils'
import { useAccountUsageSubmitMutation } from '@/generated/graphql'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'
import { numberStringToOptionalNumber } from '@/utils/numberStringToOptionalNumber'

export default function MonthlyWirePayments() {
  const t = useOnboardingTranslations()
  const dispatch = useDispatch()
  const [showLoadingScreen, setShouldShowLoadingScreen] = useState(false)
  const navigate = useNavigate()
  const variables = useAppSelector(getAccountUsageSubmitVariables)
  const [submitAccountUsage] = useAccountUsageSubmitMutation({ variables })

  const numberOfTransactions = useAppSelector((state) => state.accountUsage.numberOfWires)
  const averageAmount = useAppSelector((state) => state.accountUsage.averageWireAmount)

  const transactionType = t('accountUsage.transactionTypes.wires')

  const setAverageAmount = (value: number) =>
    // @ts-ignore TS2345
    dispatch(accountUsageActions.setTransactionUsageValue('averageWireAmount', value.toString()))
  const setNumberOfTransactions = (value?: number) =>
    // @ts-ignore TS2345
    dispatch(accountUsageActions.setTransactionUsageValue('numberOfWires', value?.toString()))

  const continueIsDisabled = !numberOfTransactions || !averageAmount
  const onContinue = () => {
    setShouldShowLoadingScreen(true)
    analytics.accountUsage.numberOfWires({ value: unformat(numberOfTransactions) })
    analytics.accountUsage.averageWireAmount({ value: unformat(averageAmount) })
    submitAccountUsage().then(() => {
      navigate(Pathname.APPLICATION_STATUS, { replace: true })
    })
  }
  const onGoBack = () => {
    navigate(Pathname.USAGE_QUESTION_ACH_PAYMENTS)
  }

  const tertiaryButtonProps = {
    text: t('accountUsage.numberOf.button', {
      transactionType: transactionType + 's',
    }),
    onClick: onContinue,
  }

  const title = t('accountUsage.numberOf.title.wires', {
    transactionType,
  })
  const numberOfWiresLabel = t('accountUsage.numberOf.inputLabel.wires')
  const numberOfWiresHelper = t('accountUsage.numberOf.subtitle')
  const averageWireAmountLabel = t('accountUsage.averageAmount.title', {
    transactionType,
  })
  const averageWireAmountHelper = t('accountUsage.numberOf.subtitle')
  const averageWireAmountPlaceholder = t('accountUsage.dollarSignPlaceholder')
  const loadingScreenTitle = t('accountUsage.loadingScreenTitle')

  if (showLoadingScreen) {
    return <LoadingScreen title={loadingScreenTitle} />
  }

  return (
    <BaseContentLayout
      headingText={title}
      primaryButton={
        <ContinueButton
          testID="usage-wire-payments-continue"
          onPress={onContinue}
          disabled={continueIsDisabled}
          fullWidth
        />
      }
      secondaryButton={<GoBackButton testID="usage-average-amount-go-back" onPress={onGoBack} fullWidth />}
      tertiaryButtonProps={tertiaryButtonProps}
    >
      <Box sx={{ gap: '$10' }}>
        <NumberInput
          testID="number-of-wires"
          labelText={numberOfWiresLabel}
          helperText={numberOfWiresHelper}
          onChange={setNumberOfTransactions}
          value={numberStringToOptionalNumber(numberOfTransactions)}
        />

        <CurrencyInput
          testID="average-wire-amount"
          labelText={averageWireAmountLabel}
          helperText={averageWireAmountHelper}
          placeholder={averageWireAmountPlaceholder}
          onChange={setAverageAmount}
          value={Number(averageAmount)}
          allowDecimals={false}
        />
      </Box>
    </BaseContentLayout>
  )
}
