import useBusinessType from '@/hooks/useBusinessType'

import CorporationPartnershipLanding from './CorporationPartnershipLanding'
import SolePropLanding from './SolePropLanding'

export default function OwnershipLanding() {
  const { isSoleProp } = useBusinessType()

  if (isSoleProp) return <SolePropLanding />

  return <CorporationPartnershipLanding />
}
