import { Box, DropdownSmall, TextArea } from '@northone/ui-components'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ContinueButton from '@/components/ContinueButton'
import GoBackButton from '@/components/GoBackButton'
import { analytics } from '@/core/analytics/events'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { useAppSelector } from '@/core/redux/utils'
import { GroupedIndustries, KybIndustry, useNaicsSuspenseQuery } from '@/generated/graphql'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'

import { Pathname } from '../constants'
import { IKYBIndustry } from './industry-select/types'

const transformSubIndustryData = ({
  keywords,
  naicsCategory,
  naicsCode,
  subCategory,
  vertical,
}: KybIndustry): IKYBIndustry => ({
  vertical,
  subCategory,
  keywords,
  NAICSCategory: naicsCategory,
  NAICSCode: naicsCode,
})

const getInitialGroupedIndustryFromState = (
  selectedIndustryState?: IKYBIndustry | null,
  groupedIndustries?: GroupedIndustries[],
): GroupedIndustries | undefined => {
  if (!selectedIndustryState) return
  const verticalFromState = selectedIndustryState.vertical
  const initialVertical = groupedIndustries?.find((groupedIndustry) => groupedIndustry.vertical === verticalFromState)
  return initialVertical
}

const MAX_DESCRIPTION_LENGTH = 200
const MIN_DESCRIPTION_LENGTH = 16

const copy = {
  heading: 'What does your business do?',
  businessDescription: {
    label: 'Describe what your business does',
    charLimit: `Your description must be ${MIN_DESCRIPTION_LENGTH} to ${MAX_DESCRIPTION_LENGTH} characters`,
  },
  industry: {
    labelText: 'Industry',
    placeholder: 'Select industry',
  },
  subIndustry: {
    labelText: 'Sub-industry',
    placeholder: 'Select sub-industry',
  },
}

export default function BusinessDetailsDescription() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const t = useOnboardingTranslations()

  const { data } = useNaicsSuspenseQuery()
  const selectedIndustry = useAppSelector((state) => state.application.businessIndustry) ?? undefined
  const businessDescription = useAppSelector((state) => state.application.businessDescription)
  const initialGroupedIndustry = getInitialGroupedIndustryFromState(selectedIndustry, data.naics?.groupedIndustries)

  const [showCharLimitError, setShowCharLimitError] = useState(false)
  const [showVerticalError, setShowVerticalError] = useState(false)
  const [showSubIndustryError, setShouldShowSubIndustryError] = useState(false)

  const requiredErrorText = t('error.required')

  const hasCharLimitError = Boolean(
    businessDescription.trim().length > MAX_DESCRIPTION_LENGTH ||
      businessDescription.trim().length < MIN_DESCRIPTION_LENGTH,
  )

  const onChangeBusinessDescription = (description: string) => {
    if (showCharLimitError && !hasCharLimitError) setShowCharLimitError(false)
    dispatch(applicationActions.setBusinessDescription(description))
  }

  const industries = data.naics?.groupedIndustries
  const industryVerticalOptions = industries?.map(({ vertical }) => ({ text: vertical, value: vertical })) ?? []

  const [selectedIndustryVertical, setSelectedIndustryVertical] = useState<GroupedIndustries | undefined>(
    initialGroupedIndustry,
  )

  const onChangeVertical = (vertical: string) => {
    setShowVerticalError(false)
    const groupedIndustry = industries?.find((industry) => industry.vertical === vertical)
    if (!groupedIndustry) return
    setSelectedIndustryVertical(groupedIndustry)
    dispatch(applicationActions.setBusinessIndustry(null))
  }

  const subIndustryOptions = selectedIndustryVertical
    ? selectedIndustryVertical.industries.map(({ subCategory }) => ({ text: subCategory, value: subCategory }))
    : []

  const onChangeSubIndustry = (subCategory: string) => {
    if (!selectedIndustryVertical) return
    const subIndustry = selectedIndustryVertical.industries.find((industry) => industry.subCategory === subCategory)
    if (!subIndustry) return
    setShouldShowSubIndustryError(false)
    dispatch(applicationActions.setBusinessIndustry(transformSubIndustryData(subIndustry)))
  }

  const onContinue = () => {
    if (hasCharLimitError) {
      setShowCharLimitError(true)
    }
    if (!selectedIndustryVertical?.vertical) {
      setShowVerticalError(true)
    }
    if (selectedIndustryVertical?.vertical && !selectedIndustry) {
      setShouldShowSubIndustryError(true)
    }
    if (!businessDescription || !selectedIndustry || hasCharLimitError) return
    analytics.application.fillBusinessDescription()
    analytics.funnel.selectIndustry({
      category: selectedIndustryVertical?.vertical ?? '',
      industry: selectedIndustry?.subCategory ?? '',
      naicsCode: selectedIndustry?.NAICSCode ?? 0,
    })
    navigate(Pathname.BUSINESS_DETAILS_CONTACT_INFO)
  }
  const onBack = () => {
    navigate(Pathname.BUSINESS_DETAILS_ABOUT_YOUR_BUSINESS)
  }
  const continueButton = (
    <ContinueButton
      onPress={onContinue}
      testID={'business-details-description-and-industry.primary-button'}
      fullWidth
    />
  )

  const goBackButton = (
    <GoBackButton testID={'business-details-description-and-industry.secondary-button'} onPress={onBack} fullWidth />
  )

  return (
    <BaseContentLayout headingText={copy.heading} primaryButton={continueButton} secondaryButton={goBackButton}>
      <Box sx={{ gap: '$4' }}>
        <TextArea
          testID="description"
          value={businessDescription ?? ''}
          labelText={copy.businessDescription.label}
          helperText={copy.businessDescription.charLimit}
          errorText={showCharLimitError ? copy.businessDescription.charLimit : undefined}
          onChange={onChangeBusinessDescription}
          placeholder=""
        />
        <Box sx={{ zIndex: 2 }}>
          <DropdownSmall
            labelText={copy.industry.labelText}
            value={selectedIndustryVertical?.vertical}
            options={industryVerticalOptions}
            onChange={onChangeVertical}
            errorText={showVerticalError ? requiredErrorText : undefined}
            placeholderProps={{ text: copy.industry.placeholder, textColor: '$charcoal5' }}
          />
        </Box>
        {selectedIndustryVertical ? (
          <Box sx={{ zIndex: 2 }}>
            <DropdownSmall
              labelText={copy.subIndustry.labelText}
              value={selectedIndustry?.subCategory}
              options={subIndustryOptions}
              onChange={onChangeSubIndustry}
              errorText={showSubIndustryError ? requiredErrorText : undefined}
              placeholderProps={{ text: copy.subIndustry.placeholder, textColor: '$charcoal5' }}
            />
          </Box>
        ) : null}
      </Box>
    </BaseContentLayout>
  )
}
