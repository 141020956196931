import { AccountUsageActionEnum } from './actions'
import { initialAccountUsageState, IAccountUsageState } from './state'

export const accountUsageReducer = (
  state: IAccountUsageState = initialAccountUsageState,
  action: any,
): IAccountUsageState => {
  switch (action.type) {
    case AccountUsageActionEnum.SET_TRANSACTION_USAGE_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }
    case AccountUsageActionEnum.SET_HAS_REVENUE_OUTSIDE_US:
      return {
        ...state,
        hasRevenueOutsideUS: action.payload,
      }
    case AccountUsageActionEnum.SET_PERCENT_REVENUE_OUTSIDE_US:
      return {
        ...state,
        percentRevenueOutsideUS: action.payload,
      }
    default:
      return state
  }
}
