import { useNavigate } from 'react-router-dom'

import { Pathname } from '../constants'
import { ShippingAddressAdditionalOwner } from './shipping-address'

export const ShippingAddressScreen = () => {
  const navigate = useNavigate()
  return (
    <>
      <ShippingAddressAdditionalOwner
        onShippingAddressUpdated={() => {
          navigate(Pathname.JOIN_TEAM_TERMS_AND_CONDITIONS)
        }}
      />
    </>
  )
}
