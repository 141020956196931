import { gql } from '@apollo/client'

export const APPLICATION_ADDRESSES_QUERY = gql`
  query onboardingApplicationAddresses {
    me {
      id
      ownerBusinesses {
        id
        onboarding {
          id: businessId
          businessId
          application {
            id
            status
            homeAddress {
              streetAddressLine1
              streetAddressLine2
              city
              postalCode
              provinceState
            }
            businessAddress {
              streetAddressLine1
              streetAddressLine2
              city
              postalCode
              provinceState
            }
          }
        }
      }
    }
  }
`

export const MAILING_ADDRESS_QUERY = gql`
  query onboardingMailingAddress {
    me {
      id
      ownerBusinesses {
        id
        onboardingMailingAddress {
          streetAddressLine1
          streetAddressLine2
          city
          provinceState
          country
          postalCode
        }
      }
    }
  }
`

export const UPDATE_MAILING_ADDRESS = gql`
  mutation onboardingMailingAddressUpdate($data: OnboardingMailingAddressUpdateData!) {
    onboardingMailingAddressUpdate(data: $data) {
      success
      code
      message
    }
  }
`
