import { Navigate } from 'react-router-dom'

import { OnboardingApplicationStatus, useApplicationStatusSuspenseQuery } from '../../generated/graphql'
import { Pathname } from '../constants'

/**
 * Prevents rejected/refused applicants from accessing this page
 */
export const RequireNonDeclinedApplication = ({ children }: { children: JSX.Element }) => {
  const { data } = useApplicationStatusSuspenseQuery({ fetchPolicy: 'network-only' })
  const applicationStatus = data.me?.ownerBusinesses[0]?.onboarding?.application?.status

  if (!applicationStatus) return children

  const wasApplicationDeclined = [OnboardingApplicationStatus.REFUSED, OnboardingApplicationStatus.REJECTED].includes(
    applicationStatus,
  )

  if (wasApplicationDeclined) return <Navigate to={Pathname.REJECTED} replace />

  return children
}
