import { ApolloClient, InMemoryCache, from, HttpLink, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { osName, osVersion, mobileModel, browserName, browserVersion } from 'react-device-detect'
import { config, isProduction, RELEASE_VERSION } from '../../utils/environment'
import { traceId } from '../../utils/logger'
import * as Sentry from '@sentry/react'
import { getTokenOrLogout } from '../auth/auth-service'

export const CLIENT_NAME = 'northone-onboarding'

const withTokenLink = setContext(async () => ({
  accessToken: await getTokenOrLogout(),
}))

const authLink = new ApolloLink((operation, forward) => {
  const accessToken = operation.getContext().accessToken
  if (!accessToken) {
    return null
  }
  operation.setContext({
    headers: {
      ...(accessToken ? { authorization: `Bearer ${accessToken}` } : {}),
      client: CLIENT_NAME,
      app_version: RELEASE_VERSION,
      os_name: osName,
      os_version: `${osName} ${osVersion}`,
      ...(mobileModel !== 'none' && { device_model: mobileModel }),
      browser: `${browserName} ${browserVersion}`,
      trace_id: traceId,
    },
  })
  return forward ? forward(operation) : null
})

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const operationString = operation.query.loc?.source.body && operation.query.loc.source.body
  const operationVariables = isProduction() ? {} : operation.variables
  Sentry.configureScope((scope) => {
    scope.setTags({
      operation: operation.operationName,
      client: CLIENT_NAME,
      trace_id: traceId,
    })
    scope.setExtras({
      ...(operationString && { operationString }),
      ...(operationVariables && { operationVariables }),
    })
  })
  if (networkError) {
    Sentry.withScope((scope) => {
      scope.setExtras({
        error: networkError,
      })
      Sentry.captureMessage(`[GQL-ERROR]: ${operation.operationName} - Network Error`)
    })
    if (!isProduction()) {
      console.error(`[Network error]: ${networkError}`)
    }
  }
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      if (!isProduction()) {
        console.error(error)
      }
      Sentry.withScope((scope) => {
        scope.setExtras({
          trace_id: traceId,
        })
        Sentry.captureMessage(`[GQL-ERROR]: ${operation.operationName} - ${error.message}`)
      })
    })
  }
})

const httpLink = new HttpLink({
  credentials: 'same-origin',
  fetch,
  uri: config.graphQlUri,
})

const link = from([withTokenLink, authLink, errorLink, httpLink])

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: !isProduction(),
  link,
})
