import { useMachine } from '@xstate/react'
import { useEffect, useState } from 'react'
import LoadingScreen from '@/components/LoadingScreen'
import { BaseLayout } from '@/layouts/BaseLayout'
import { Auth0UserProvider } from '@/routes/providers/auth0-user'
import { getURLSearchParameter } from '../../utils/url-helpers'
import { authStateMachine } from '../state-machines/auth'
import { Auth0Error, redirectToLogin } from './auth-service'
import { PostAuthWrapper } from './post-auth-wrapper'

const getLogoutVariant = (isAccountBlocked: boolean) => {
  if (isAccountBlocked) return Auth0Error.BLOCKED
  const isLogout = Boolean(getURLSearchParameter('logout'))
  const hasMode = getURLSearchParameter('mode')
  return isLogout ? 'login' : hasMode ? hasMode : undefined
}

export const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [state, send] = useMachine(authStateMachine)

  useEffect(() => {
    send('SESSION.START')
    const checkAuthSession = async () => {
      const logOutAs = getLogoutVariant(state.context.account === Auth0Error.BLOCKED)
      if (state.matches('loggedOut') || Boolean(getURLSearchParameter('logout'))) {
        return await redirectToLogin(logOutAs)
      } else if (state.matches('loggedIn')) {
        setIsAuthenticated(true)
      }
    }
    checkAuthSession()
  })

  return isAuthenticated ? (
    <Auth0UserProvider>
      <PostAuthWrapper>{children}</PostAuthWrapper>
    </Auth0UserProvider>
  ) : (
    <BaseLayout>
      <LoadingScreen />
    </BaseLayout>
  )
}
