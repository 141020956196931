import { createSelector } from 'reselect'
import { IRootState } from '../root-state'

import { AccountUsageSubmitMutationVariables } from '../../../generated/graphql'
import { unformat } from 'accounting'
import { IAccountUsageState } from './state'

const getAccountUsage = (state: IRootState): IAccountUsageState => state.accountUsage
const getBusinessId = (state: IRootState): string => state.application.businessId

export const getAccountUsageSubmitVariables = createSelector(
  [getAccountUsage, getBusinessId],
  (
    {
      averageACHAmount,
      averageCheckDepositAmount,
      averageCheckPaymentAmount,
      averageDepositAmount,
      averageWireAmount,
      debitCardAmount,
      hasRevenueOutsideUS,
      numberOfACHs,
      numberOfCheckDeposits,
      numberOfCheckPayments,
      numberOfWires,
      percentRevenueOutsideUS,
    },
    businessId,
  ): AccountUsageSubmitMutationVariables => ({
    data: {
      businessId,
      averageACHAmount: unformat(averageACHAmount),
      averageCheckDepositAmount: unformat(averageCheckDepositAmount),
      averageCheckPaymentAmount: unformat(averageCheckPaymentAmount),
      averageDepositAmount: unformat(averageDepositAmount),
      averageWireAmount: unformat(averageWireAmount),
      debitCardAmount: unformat(debitCardAmount),
      numberOfACHs: unformat(numberOfACHs),
      numberOfCheckDeposits: unformat(numberOfCheckDeposits),
      numberOfCheckPayment: unformat(numberOfCheckPayments),
      numberOfWires: unformat(numberOfWires),
      percentRevenueOutsideUS: hasRevenueOutsideUS ? unformat(percentRevenueOutsideUS) : 0,
    },
  }),
)
