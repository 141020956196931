import {
  Body,
  Box,
  ButtonGroup,
  PageHeader,
  PageHeaderSizeEnum,
  PrimaryButton,
  SecondaryButton,
} from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'
import { Modal } from 'react-native'

import { logout } from '@/core/auth/auth-service'

export const LogoutModal = ({ isVisible, onPressGoBack }: { isVisible: boolean; onPressGoBack: () => void }) => {
  return (
    <Modal animationType="fade" transparent={true} visible={isVisible}>
      <Box
        sx={{
          flex: 1,
          width: '$full',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0,0,0,0.5)',
        }}
      >
        <LogoutBox onPressLogout={logout} onPressGoBack={onPressGoBack} maxWidth={510} />
      </Box>
    </Modal>
  )
}

interface LogoutBoxProps {
  onPressLogout: () => void
  onPressGoBack: () => void
  maxWidth?: number
}

const copy = {
  header: 'Log out of your North One session',
  subHeader: 'You will be logged out of your North One account. All unsaved changes you made will be lost.',
  goBack: 'Go back',
  logOut: 'Log out',
}

export const LogoutBox = ({ onPressGoBack, onPressLogout, maxWidth }: LogoutBoxProps) => {
  const { isMobileSize } = useMobileScreenSize()
  const buttonFullWidth = !isMobileSize
  const headerSize = isMobileSize ? PageHeaderSizeEnum.SM : PageHeaderSizeEnum.MD
  const gap = isMobileSize ? '$4' : '$8'
  return (
    <Box sx={{ gap, px: 40, py: 45, backgroundColor: '$white1', borderRadius: '$md', maxWidth }}>
      <PageHeader headingLevel={1} size={headerSize}>
        {copy.header}
      </PageHeader>
      <Body>{copy.subHeader}</Body>
      <ButtonGroup>
        <PrimaryButton fullWidth={buttonFullWidth} onPress={onPressLogout} testID="logout-box-log-out">
          {copy.logOut}
        </PrimaryButton>
        <SecondaryButton fullWidth={buttonFullWidth} onPress={onPressGoBack} testID="logout-box-go-back">
          {copy.goBack}
        </SecondaryButton>
      </ButtonGroup>
    </Box>
  )
}
