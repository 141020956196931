import { IAddressState } from '../../core/redux/application-redux/application-state'
import { Location, UserAddressData } from '../../generated/graphql'

export const gqlTeamMemberAddressToAddressState = (gqlUserAddress?: UserAddressData | null): IAddressState => ({
  city: gqlUserAddress?.city ?? '',
  state: gqlUserAddress?.state ?? '',
  streetAddress: gqlUserAddress?.line1 ?? '',
  suite: gqlUserAddress?.line2 ?? '',
  zipCode: gqlUserAddress?.postalCode ?? '',
  description: '',
  coordinates: null,
})

export const gqlAddressStateToTeamMemberAddress = (gqlAddressState?: IAddressState | null): UserAddressData => ({
  city: gqlAddressState?.city,
  state: gqlAddressState?.state,
  line1: gqlAddressState?.streetAddress,
  line2: gqlAddressState?.suite,
  postalCode: gqlAddressState?.zipCode,
  country: 'US',
})

export const gqlAddressStateToLocation = (gqlUserAddress?: IAddressState | null): Location => ({
  city: gqlUserAddress?.city,
  provinceState: gqlUserAddress?.state,
  streetAddressLine1: gqlUserAddress?.streetAddress,
  streetAddressLine2: gqlUserAddress?.suite,
  postalCode: gqlUserAddress?.zipCode,
})
