import { ICONS, PrimaryButton } from '@northone/ui-components'

import { useOnboardingTranslations } from '@/i18n/locales/en/en'

export interface ContinueButtonProps {
  testID: string
  onPress: () => void
  fullWidth?: boolean
  disabled?: boolean
  loading?: boolean
}

export default function ContinueButton({
  testID,
  onPress,
  loading = false,
  disabled = false,
  fullWidth = false,
}: ContinueButtonProps) {
  const t = useOnboardingTranslations()
  const continueText = t('button.continue')

  return (
    <PrimaryButton
      testID={testID}
      onPress={onPress}
      disabled={disabled}
      loading={loading}
      fullWidth={fullWidth}
      icon={ICONS.ArrowIcon}
      iconPosition="right"
    >
      {continueText}
    </PrimaryButton>
  )
}
