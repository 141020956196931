import {
  AddContactIcon,
  Box,
  CardText,
  CardTextSizeEnum,
  IconWithBackground,
  Link,
  SelectionBoxInputGroup,
  SelectionBoxInputGroupProps,
  SizeEnum,
} from '@northone/ui-components'
import emailMisspelled, { top100 } from 'email-misspelled'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ContinueButton from '@/components/ContinueButton'
import GoBackButton from '@/components/GoBackButton'
import LoadingScreen from '@/components/LoadingScreen'
import { useFeatureFlag } from '@/core/feature-flags/use-feature-flag'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { CONTROL_PERSON_CANDIDATE_ID, IOwner } from '@/core/redux/application-redux/application-state'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { DATE_INPUT_FORMAT, DEPOSIT_ACCOUNT_AGREEMENT_URL } from '@/utils'
import { validate } from '@/utils/validate'

import { Pathname } from '../constants'
import { OwnerProfileForm } from './owner-profiles/OwnerProfileForm'
import { useOwnerProfileListIsValid } from './owner-profiles/utils/owner-validation-hooks'

const testIDs = {
  ownerSelection: (ownerId: string) => `ownership-control.person-selection.box.input.group.option-${ownerId}`,
  candidateSelection: 'ownership-control.person-selection.box.input.group.option-other',
  primaryButton: 'ownership.control-person.primary-button',
  secondaryButton: 'ownership.control-person.secondary-button',
}

const OwnershipControllerSubheader = () => {
  const t = useOnboardingTranslations()

  return (
    <>
      {t('applicationForm.ownerProfiles.controlPerson.subtitle')}
      <Link href={DEPOSIT_ACCOUNT_AGREEMENT_URL} isExternal={true}>
        {t('applicationForm.ownerProfiles.controlPerson.accountAgreementLinkText')}
      </Link>
      {t('applicationForm.ownerProfiles.controlPerson.forMoreDetails')}
    </>
  )
}

const useValidateFinancialAdministrator = () => {
  const controlPersonId = useAppSelector((state) => state.application.controlPersonId)
  const owners = useAppSelector((state) => state.application.owners)
  const financialAdmin = owners[controlPersonId]
  const ssn = useAppSelector((state) => state.unpersisted.ssns[controlPersonId])

  if (!financialAdmin) {
    throw new Error('No financial admin found')
  }
  const isFirstNameValid = validate.firstName(financialAdmin.firstName)
  const isLastNameValid = validate.lastName(financialAdmin.lastName)
  const isBirthdateValid = validate.birthdate(DateTime.fromFormat(financialAdmin.birthdate, DATE_INPUT_FORMAT))
  const isEmailValid = validate.email(financialAdmin.email) && validate.emailUnique(controlPersonId, owners)
  const isAddressValid = validate.address(financialAdmin.address)
  const isPhoneNumberValid = validate.phoneNumber(financialAdmin.phoneNumber)
  const isSSNValid = validate.ssn(ssn)

  return (
    isFirstNameValid &&
    isLastNameValid &&
    isBirthdateValid &&
    isEmailValid &&
    isAddressValid &&
    isPhoneNumberValid &&
    isSSNValid
  )
}

export default function OwnershipController() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const t = useOnboardingTranslations()
  const [shouldShowValidationErrors, setShouldShowValidationErrors] = useState(false)
  const isPlaidEnabled = useFeatureFlag('joindot-initial-funding')
  const nextPage = isPlaidEnabled ? Pathname.ACCOUNT_FUNDING : Pathname.APPLICATION_SUBMIT

  const [shouldShowLoadingScreen, setShouldShowLoadingScreen] = useState(false)
  const controlPersonId = useAppSelector((state) => state.application.controlPersonId)
  const { valid: areOwnerProfilesValid } = useOwnerProfileListIsValid()
  const financialAdmin = useAppSelector((state) => state.application.owners.CONTROL_PERSON_CANDIDATE_ID)
  const enteredEmail = financialAdmin?.email
  const isFinancialAdministratorValid = useValidateFinancialAdministrator()

  const [shouldDisableContinueButton, setShouldDisableContinueButton] = useState(false)
  const emailChecker = emailMisspelled({ maxMisspelled: 1, domains: top100 })
  const [emailSuggestion, setEmailSuggestion] = useState<string | undefined>(undefined)
  const [wasAlertDismissed, setWasAlertDismissed] = useState(false)

  const onEmailSuggestionAccepted = () => {
    setEmailSuggestion(undefined)
    setShouldDisableContinueButton(false)
  }

  const onEmailSuggestionDismiss = () => {
    setEmailSuggestion(undefined)
    setWasAlertDismissed(true)
    setShouldDisableContinueButton(false)
  }

  const removeControlPersonCandidateState = () => dispatch(applicationActions.deleteOwner(CONTROL_PERSON_CANDIDATE_ID))
  const setControlPersonId = (id: string) => {
    if (id === CONTROL_PERSON_CANDIDATE_ID) {
      // Adds other control person to owners and sets control person ID as CONTROL_PERSON_CANDIDATE_ID
      dispatch(applicationActions.addControlPersonCandidate())
    } else {
      removeControlPersonCandidateState()
      dispatch(applicationActions.setControlPersonId(id))
    }
  }

  const copy = {
    header: t('applicationForm.ownerProfiles.controlPerson.title'),
    candidate: {
      actionItemHeader: t('applicationForm.ownerProfiles.controlPerson.otherCandidate.actionItemHeader'),
      actionItemDescription: t('applicationForm.ownerProfiles.controlPerson.otherCandidate.actionItemDescription'),
      formHeader: t('applicationForm.ownerProfiles.controlPerson.otherCandidate.formTitle'),
    },
    loadingTitle: t('ownership.profile.reviewingYourOwnership'),
  }

  const ownerMap = useAppSelector((state) => state.application.owners)
  const documentedOwners = Object.entries(ownerMap)
    .map(([id, owner]) => ({ id, ...owner }))
    .filter((owner) => owner.id !== CONTROL_PERSON_CANDIDATE_ID)

  const ownerOptions: SelectionBoxInputGroupProps<string>['options'] = documentedOwners.map((owner) => ({
    action: `${owner.firstName} ${owner.lastName}`,
    description: owner.jobTitle,
    value: owner.id,
    leftItem: <OwnerIcon owner={owner} />,
    testID: testIDs.ownerSelection(owner.id),
  }))
  const otherControlPersonOption = {
    action: copy.candidate.actionItemHeader,
    description: copy.candidate.actionItemDescription,
    value: CONTROL_PERSON_CANDIDATE_ID,
    leftItem: IconWithBackground({
      backgroundColor: '$receiveHoneydew',
      icon: AddContactIcon,
      size: SizeEnum.MD,
    }),
    testID: testIDs.candidateSelection,
    overflowHidden: false,
  }
  const options = [...ownerOptions, otherControlPersonOption]

  const onContinue = () => {
    setShouldShowLoadingScreen(true)

    // Validate non-owner financial admin
    if (controlPersonId === CONTROL_PERSON_CANDIDATE_ID && !isFinancialAdministratorValid) {
      setShouldShowLoadingScreen(false)
      setShouldShowValidationErrors(true)
      return
    }
    // Navigate back to owner details if control person isn't valid, for example if their SSN is missing
    if (!areOwnerProfilesValid) {
      navigate(Pathname.OWNERSHIP_DETAILS_OWNER_PROFILES)
      return
    }
    // Check if email has suggestion or is alert has been dismissed
    const suggestion = enteredEmail ? emailChecker(enteredEmail).map((result) => result.corrected)[0] : undefined
    if (enteredEmail && suggestion && !wasAlertDismissed) {
      setShouldShowLoadingScreen(false)
      setShouldDisableContinueButton(true)
      setEmailSuggestion(suggestion)
      return
    }

    setTimeout(() => {
      navigate(nextPage)
    }, 1_000)
  }

  const continueButton = (
    <ContinueButton
      onPress={onContinue}
      testID={testIDs.primaryButton}
      fullWidth
      disabled={shouldDisableContinueButton}
    />
  )
  const goBackButton = (
    <GoBackButton
      onPress={() => {
        navigate(Pathname.OWNERSHIP_DETAILS_OWNER_PROFILES)
      }}
      testID={testIDs.secondaryButton}
      fullWidth
    />
  )

  if (shouldShowLoadingScreen) {
    return <LoadingScreen title={copy.loadingTitle} />
  }

  return (
    <BaseContentLayout
      headingText={copy.header}
      subHeadingText={<OwnershipControllerSubheader />}
      primaryButton={continueButton}
      secondaryButton={goBackButton}
    >
      <Box sx={{ gap: '$9' }}>
        <SelectionBoxInputGroup options={options} value={controlPersonId} onChange={setControlPersonId} />
        {controlPersonId === CONTROL_PERSON_CANDIDATE_ID && (
          <OwnerProfileForm
            ownerId={controlPersonId}
            shouldShowValidationErrors={shouldShowValidationErrors}
            isControllerForm
            emailSuggestion={emailSuggestion}
            onEmailSuggestionAccepted={onEmailSuggestionAccepted}
            onEmailSuggestionDismiss={onEmailSuggestionDismiss}
          />
        )}
      </Box>
    </BaseContentLayout>
  )
}

const OwnerIcon = ({ owner }: { owner: IOwner }) => {
  const getInitial = (name: string) => name.slice(0, 1).toLocaleUpperCase()
  const initials = `${getInitial(owner.firstName)}${getInitial(owner.lastName)}`
  return (
    <Box
      sx={{
        height: '$9',
        width: '$9',
        borderRadius: '$md',
        backgroundColor: '$receiveHoneydew',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CardText size={CardTextSizeEnum.MD} color={'$charcoal7'}>
        {initials}
      </CardText>
    </Box>
  )
}
