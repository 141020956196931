import { Pathname } from '@/routes/constants'

export type AdditionalOwnerMachinePath = 'shipping_address' | 'terms_and_conditions' | 'celebration'
export type AdditionalOwnerPath =
  | Pathname.JOIN_TEAM_SHIPPING_ADDRESS
  | Pathname.JOIN_TEAM_TERMS_AND_CONDITIONS
  | Pathname.JOIN_TEAM_CELEBRATION

export interface IAdditionalOwnerRouteScreen {
  path: AdditionalOwnerPath
}

export const additionalOwnerRoutes: Record<AdditionalOwnerMachinePath, IAdditionalOwnerRouteScreen> = {
  shipping_address: {
    path: Pathname.JOIN_TEAM_SHIPPING_ADDRESS,
  },
  terms_and_conditions: {
    path: Pathname.JOIN_TEAM_TERMS_AND_CONDITIONS,
  },
  celebration: {
    path: Pathname.JOIN_TEAM_CELEBRATION,
  },
}
