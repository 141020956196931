import { useAppSelector } from '@/core/redux/utils'

export const useTotalOwnershipPercentageIsValid = () => {
  const ownerProfileMap = useAppSelector((state) => state.application.owners)

  const totalOwnershipPercentage = Object.values(ownerProfileMap)
    .map((owner) => Number(owner.ownershipPercentage))
    .reduce((prev, curr) => prev + curr, 0)

  return totalOwnershipPercentage <= 100
}
