import { useCallback, useEffect, useState } from 'react'
import { analytics } from '@/core/analytics/events'
import { Intercom } from '@/core/intercom'
import { useAppSelector } from '@/core/redux/utils'
import { isNodeHTMLDivElement, isNodeHTMLIFrameElement } from '@/utils/dom-helpers'
import { useHideIntercomBorderlessFrame, useWindowDimensions } from '@/utils/hooks'

export interface InAppDocumentCaptureIntercomProps {
  onContinueClick: () => void
  shouldWaitForIntercomBotChat: boolean
  intercomCustomBotChatWasShown: boolean
  setIntercomCustomBotChatWasShown: (value: boolean) => void
}

export const BODY_INTERCOM_CLASS = 'in-app-document-capture-intercom-page'
export const INTERCOM_BORDERLESS_FRAME_CLASS = 'intercom-borderless-frame'
const observerConfig = { attributes: false, childList: true, subtree: true }

const doesNodeContainIntercomBorderlessFrame = (node: Node): boolean => {
  if (!isNodeHTMLDivElement(node)) return false
  for (const childNode of node.childNodes) {
    if (!isNodeHTMLIFrameElement(childNode)) continue
    if (childNode.classList.contains(INTERCOM_BORDERLESS_FRAME_CLASS)) return true
  }
  return false
}

export const useDocumentCapture = ({
  intercomCustomBotChatWasShown,
  setIntercomCustomBotChatWasShown,
  shouldWaitForIntercomBotChat,
  intercomContainerRef,
}: InAppDocumentCaptureIntercomProps & { intercomContainerRef: React.RefObject<HTMLDivElement> }) => {
  const businessId = useAppSelector((state) => state.application.businessId)

  const windowDimensions = useWindowDimensions()
  const [hasError, setHasError] = useState(false)

  useHideIntercomBorderlessFrame()

  const showIntercomMessageWindowWhenIntercomBorderlessFrameAppears = useCallback(
    (mutationsList: MutationRecord[]) => {
      for (const mutation of mutationsList) {
        for (const node of mutation.addedNodes) {
          if (!isNodeHTMLDivElement(node)) continue
          if (doesNodeContainIntercomBorderlessFrame(node)) {
            Intercom.show()
            setIntercomCustomBotChatWasShown(true)
            return
          }
        }
      }
    },
    [setIntercomCustomBotChatWasShown],
  )

  // Open the Intercom borderless window if already present
  useEffect(() => {
    const intercomBorderlessFrame = document.querySelector(`.${INTERCOM_BORDERLESS_FRAME_CLASS}`)
    if (!intercomBorderlessFrame) return
    Intercom.show()
    setIntercomCustomBotChatWasShown(true)
  }, [setIntercomCustomBotChatWasShown, intercomCustomBotChatWasShown])

  useEffect(() => {
    if (!intercomCustomBotChatWasShown) return
    Intercom.show()
  }, [intercomCustomBotChatWasShown])

  // Show the Intercom window when the borderless chat appears
  useEffect(() => {
    if (!shouldWaitForIntercomBotChat) return
    const observer = new MutationObserver(showIntercomMessageWindowWhenIntercomBorderlessFrameAppears)
    observer.observe(document.body, observerConfig)

    return () => observer.disconnect()
  }, [shouldWaitForIntercomBotChat, showIntercomMessageWindowWhenIntercomBorderlessFrameAppears])

  // Handle returning applicants who have indicated that they are finished uploading
  const [intercomFinishedUploadingTimeout, setIntercomFinishedUploadingTimeout] = useState<number>()
  useEffect(() => {
    if (intercomCustomBotChatWasShown) {
      intercomFinishedUploadingTimeout && window.clearTimeout(intercomFinishedUploadingTimeout)
      return
    }
    if (shouldWaitForIntercomBotChat) return
    if (intercomFinishedUploadingTimeout) return
    setIntercomFinishedUploadingTimeout(
      window.setTimeout(() => {
        Intercom.showMessages()
        setIntercomCustomBotChatWasShown(true)
      }, 2000),
    )

    return () => {
      intercomFinishedUploadingTimeout && window.clearTimeout(intercomFinishedUploadingTimeout)
    }
  }, [
    shouldWaitForIntercomBotChat,
    intercomCustomBotChatWasShown,
    setIntercomCustomBotChatWasShown,
    intercomFinishedUploadingTimeout,
  ])

  // Handle partly-uploaded returning applicants who have not indicated that they are finished uploading
  const [intercomPartlyUploadedTimeout, setIntercomPartlyUploadedTimeout] = useState<number>()
  useEffect(() => {
    if (intercomCustomBotChatWasShown) {
      intercomPartlyUploadedTimeout && window.clearTimeout(intercomPartlyUploadedTimeout)
      return
    }
    if (intercomPartlyUploadedTimeout) return
    setIntercomPartlyUploadedTimeout(
      window.setTimeout(() => {
        Intercom.showMessages()
        setIntercomCustomBotChatWasShown(true)
      }, 5000),
    )

    return () => {
      intercomPartlyUploadedTimeout && window.clearTimeout(intercomPartlyUploadedTimeout)
    }
  }, [intercomCustomBotChatWasShown, setIntercomCustomBotChatWasShown, intercomPartlyUploadedTimeout])

  // Apply custom styling to Intercom messaging window
  useEffect(() => {
    if (!intercomCustomBotChatWasShown || !intercomContainerRef.current) return
    const rect = intercomContainerRef.current.getBoundingClientRect()
    document.body.style.setProperty('--dynamic-intercom-top', `${rect.top}px`)
    document.body.style.setProperty('--dynamic-intercom-left', `${rect.left}px`)
    document.body.style.setProperty('--dynamic-intercom-width', `${rect.width}px`)
    document.body.classList.add(BODY_INTERCOM_CLASS)

    return () => document.body.classList.remove(BODY_INTERCOM_CLASS)
  }, [intercomContainerRef.current, windowDimensions, intercomCustomBotChatWasShown])

  // Poll Intercom for updates to be notified of custom bot conversation
  useEffect(() => {
    Intercom.update()
    const updateIntercomIfNecessary = () => !intercomCustomBotChatWasShown && Intercom.update()
    const firstIntercomUpdateTimeout = window.setTimeout(updateIntercomIfNecessary, 500)
    const secondIntercomUpdateTimeout = window.setTimeout(updateIntercomIfNecessary, 1000)
    const thirdIntercomUpdateTimeout = window.setTimeout(updateIntercomIfNecessary, 1500)

    return () => {
      Intercom.hide()
      window.clearTimeout(firstIntercomUpdateTimeout)
      window.clearTimeout(secondIntercomUpdateTimeout)
      window.clearTimeout(thirdIntercomUpdateTimeout)
    }
  }, [intercomCustomBotChatWasShown])

  useEffect(() => {
    analytics.inAppDocumentUpload.intercomScreenLoaded({ businessId })
  }, [businessId])

  const [intercomErrorTimeout, setIntercomErrorTimeout] = useState<number>()
  useEffect(() => {
    if (intercomErrorTimeout && intercomCustomBotChatWasShown) {
      setHasError(false)
      return window.clearTimeout(intercomErrorTimeout)
    }

    if (intercomErrorTimeout) return
    setIntercomErrorTimeout(
      window.setTimeout(() => {
        if (!intercomCustomBotChatWasShown) {
          analytics.inAppDocumentUpload.intercomScreenPageError({ businessId })
          setHasError(true)
        }
      }, 10000),
    )
    return () => {
      intercomErrorTimeout && window.clearTimeout(intercomErrorTimeout)
    }
  }, [businessId, intercomCustomBotChatWasShown, intercomErrorTimeout])

  return { hasError, intercomContainerRef }
}
