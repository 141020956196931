import {
  CurrencyInput,
  ICONS,
  IconWithBackground,
  NumberInput,
  SelectionBoxInputGroup,
  SizeEnum,
} from '@northone/ui-components'
import { unformat } from 'accounting'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ContinueButton from '@/components/ContinueButton'
import Fieldset from '@/components/Fieldset'
import GoBackButton from '@/components/GoBackButton'
import { analytics } from '@/core/analytics/events'
import { accountUsageActions } from '@/core/redux/account-usage-redux/actions'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'
import { numberStringToOptionalNumber } from '@/utils/numberStringToOptionalNumber'
import { maxLengths, validate } from '@/utils/validate'

export default function BusinessDetailsExpectedRevenue() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const t = useOnboardingTranslations()
  const businessRevenue = useAppSelector((state) => state.application.businessRevenue)
  const hasRevenueOutsideUS = useAppSelector((state) => state.accountUsage.hasRevenueOutsideUS)
  const percentRevenueOutsideUS = useAppSelector((state) => state.accountUsage.percentRevenueOutsideUS)
  const [percentageOfUSRevenueError, setPercentageOfUSRevenueError] = useState<string>()
  const [expectedRevenueError, setExpectedRevenueError] = useState<string>()

  const title = t('businessDetails.revenue.title')
  const percentageOfUSRevenueLabel = t('businessDetails.revenue.percentageOfUSRevenue')
  const revenueOriginLabel = t('businessDetails.revenue.expectedRevenueOrigin')
  const expectedRevenueNext12MonthsLabel = t('businessDetails.revenue.expectedRevenueNext12Months')
  const bestEstimate = t('businessDetails.revenue.bestEstimate')
  const percentageOfUSRevenueErrorText = t('businessDetails.revenue.percentageOfUSRevenueErrorText')
  const expectedRevenueErrorText = t('businessDetails.revenue.expectedRevenueErrorText')

  const onBack = () => {
    navigate(Pathname.BUSINESS_DETAILS_CONTACT_INFO)
  }
  const onContinue = () => {
    analytics.accountUsage.hasRevenueOutsideUS({ value: Boolean(hasRevenueOutsideUS) })
    if (hasRevenueOutsideUS) {
      analytics.accountUsage.percentRevenueOutsideUS({ value: unformat(percentRevenueOutsideUS) })
    }
    analytics.funnel.revenue({ revenue: businessRevenue })
    navigate(Pathname.BUSINESS_DETAILS_ONLINE_PRESENCE)
  }

  const revenueOriginOptions = [
    {
      action: t('businessDetails.revenue.revenueOriginUSA'),
      leftItem: IconWithBackground({ icon: ICONS.MapPinIcon, backgroundColor: '$receiveHoneydew', size: SizeEnum.MD }),
      value: 'no',
      testID: 'business-details-revenue-origin-usa',
    },
    {
      action: t('businessDetails.revenue.revenueOriginMultipleCountries'),
      leftItem: IconWithBackground({ icon: ICONS.GlobeIcon, backgroundColor: '$receiveHoneydew', size: SizeEnum.MD }),
      value: 'yes',
      testID: 'business-details-revenue-origin-multiple-countries',
    },
  ]

  const onExpectedRevenueOriginChanged = (value: string) => {
    const newHasRevenueOutsideUS = value === 'yes'
    dispatch(accountUsageActions.setHasRevenueOutsideUS(newHasRevenueOutsideUS))
    if (newHasRevenueOutsideUS) {
      return
    }
    dispatch(accountUsageActions.setPercentRevenueOutsideUS(''))
  }
  const onExpectedRevenueChanged = (value: number) => {
    setExpectedRevenueError(undefined)
    dispatch(applicationActions.setBusinessRevenue(value.toString()))
    if (!validate.maxLength(value.toString(), maxLengths.annualRevenue)) {
      setExpectedRevenueError(expectedRevenueErrorText)
    }
  }
  const onPercentageRevenueInsideUSChanged = (value?: number) => {
    setPercentageOfUSRevenueError(undefined)
    if (value === undefined) {
      dispatch(accountUsageActions.setPercentRevenueOutsideUS(''))
      return
    }
    if (value >= 100) {
      setPercentageOfUSRevenueError(percentageOfUSRevenueErrorText)
    }
    const percentageOfRevenueOutsideUS = 100 - value
    dispatch(accountUsageActions.setPercentRevenueOutsideUS(percentageOfRevenueOutsideUS.toString()))
  }

  const hasRevenueOutsideUSSelectionGroupValue = hasRevenueOutsideUS ? 'yes' : 'no'
  const percentageOfUSRevenueValue =
    numberStringToOptionalNumber(percentRevenueOutsideUS) !== undefined
      ? String(100 - parseInt(percentRevenueOutsideUS))
      : undefined
  const isInvalidRevenuePercentage = Boolean(
    hasRevenueOutsideUS && (!percentageOfUSRevenueValue || percentRevenueOutsideUS === '100'),
  )

  const isContinueButtonDisabled =
    Boolean(percentageOfUSRevenueError ?? expectedRevenueError) || isInvalidRevenuePercentage || businessRevenue === '0'

  return (
    <BaseContentLayout
      headingText={title}
      primaryButton={
        <ContinueButton
          testID="business-details-continue"
          onPress={onContinue}
          disabled={isContinueButtonDisabled}
          fullWidth
        />
      }
      secondaryButton={<GoBackButton testID="business-details-back" onPress={onBack} fullWidth />}
    >
      <Fieldset>
        <SelectionBoxInputGroup
          labelText={revenueOriginLabel}
          value={hasRevenueOutsideUSSelectionGroupValue}
          options={revenueOriginOptions}
          onChange={onExpectedRevenueOriginChanged}
        />
        <CurrencyInput
          testID="expected-revenue"
          labelText={expectedRevenueNext12MonthsLabel}
          value={parseInt(businessRevenue)}
          helperText={bestEstimate}
          errorText={expectedRevenueError}
          onChange={onExpectedRevenueChanged}
          allowDecimals={false}
        />
        {hasRevenueOutsideUS ? (
          <NumberInput
            testID="percentage-of-us-revenue"
            labelText={percentageOfUSRevenueLabel}
            value={numberStringToOptionalNumber(percentageOfUSRevenueValue)}
            placeholder="%"
            helperText={bestEstimate}
            errorText={percentageOfUSRevenueError}
            onChange={onPercentageRevenueInsideUSChanged}
          />
        ) : null}
      </Fieldset>
    </BaseContentLayout>
  )
}
