import { unformat } from 'accounting'
import { DateTime } from 'luxon'
import phone from 'phone'
import jsValidator from 'validator'

import { IAddressState, IOwners } from '../core/redux/application-redux/application-state'
import { americanStates, DATE_MONTH_YEAR_FORMAT, MINIMUM_CLIENT_AGE } from './constants'
import { regex } from './regex'

export const minLengths = {
  businessName: 2,
}

export const maxLengths = {
  businessName: 50,
  addressField: 30,
  name: 30,
  website: 50,
  annualRevenue: 15,
  yearsAtLocation: 3,
  employees: 15,
  email: 50,
  jobTitle: 50,
}

export const amounts = {
  funding: {
    minValue: 50,
    maxValue: 250,
  },
}

export const validate = {
  maxLength: (text: string, limit: number): boolean => text.trim().length <= limit,
  minLength: (text: string, minLength: number): boolean => text.trim().length >= minLength,
  onlyAlphabeticalLetters: (text: string): boolean => regex.onlyLetters.test(text),
  firstName: (firstName: string): boolean =>
    Boolean(
      validate.required(firstName) &&
        validate.maxLength(firstName, maxLengths.name) &&
        validate.onlyAlphabeticalLetters(firstName),
    ),
  lastName: (lastName: string): boolean =>
    Boolean(
      validate.minLength(lastName, 2) &&
        validate.maxLength(lastName, maxLengths.name) &&
        validate.onlyAlphabeticalLetters(lastName),
    ),
  businessName: (businessName: string): boolean =>
    validate.maxLength(businessName, maxLengths.businessName) && validate.minLength(businessName, 2),
  dba: (dba: string | undefined): boolean =>
    !dba || (dba.length >= minLengths.businessName && dba.length <= maxLengths.businessName),
  description: (description: string): boolean => validate.required(description) && description.length >= 16,
  employees: (employees: string | undefined): boolean =>
    Boolean(
      employees &&
        unformat(employees ?? '') &&
        validate.maxLength(unformat(employees).toString(), maxLengths.employees),
    ),
  ein: (ein: string): boolean => Boolean(ein && ein.replace(regex.notNumber, '').length === 9),
  ssn: (ssn: string | undefined): boolean => Boolean(ssn?.match(regex.ssn)),
  phoneNumber: (phoneNumber: string): boolean => {
    return Boolean(phone(phoneNumber).length)
  },
  minPercentage: (percentage: string): boolean => {
    const value = Number(percentage)
    return !isNaN(value) && value !== 0
  },
  maxPercentage: (percentage: string): boolean => {
    const value = Number(percentage)
    return !isNaN(value) && value <= 100
  },
  percentage: (percentage: string): boolean =>
    Boolean(validate.minPercentage(percentage) && validate.maxPercentage(percentage)),
  yearsAtLocation: (yearsAtLocation: string): boolean =>
    Boolean(unformat(yearsAtLocation)) && validate.maxLength(yearsAtLocation, maxLengths.yearsAtLocation),
  phoneNumberLength: (phoneNumber: string): boolean => phoneNumber.replace(regex.notNumber, '').length === 10,
  isValidDate: (date: DateTime): boolean => {
    return Boolean(date) && date.isValid
  },
  isNotFutureDate: (date: DateTime): boolean => {
    const currentTime = DateTime.fromJSDate(new Date())
    const differenceBetweenDateAndToday = currentTime.diff(date, 'days').as('days')

    return differenceBetweenDateAndToday >= 0
  },
  isOver18: (birthDate: DateTime): boolean => {
    const currentTime = DateTime.fromJSDate(new Date())
    const age = currentTime.diff(birthDate, 'years').as('years')

    return age >= MINIMUM_CLIENT_AGE
  },
  incorpDate: (date: DateTime): boolean => {
    return validate.isValidDate(date) && validate.isNotFutureDate(date) && validate.isAfter1900(date)
  },
  isNotPOBox: (streetAddress: string): boolean =>
    !streetAddress
      .toUpperCase()
      .replace(/[^0-9A-Z]/g, '')
      .match(regex.notPoBox),
  isAfter1900: (date: DateTime): boolean => {
    const year1900 = DateTime.fromObject({ year: 1900 })
    return date > year1900
  },
  streetAddress: (streetAddress?: string | null): boolean =>
    Boolean(
      streetAddress &&
        validate.required(streetAddress) &&
        streetAddress.length <= maxLengths.addressField &&
        validate.isNotPOBox(streetAddress),
    ),
  suite: (suite?: string | null): boolean =>
    !suite || (validate.maxLength(suite, maxLengths.addressField) && validate.isNotPOBox(suite)),
  city: (city?: string | null): boolean =>
    Boolean(
      city &&
        validate.required(city) &&
        validate.maxLength(city, maxLengths.addressField) &&
        validate.minLength(city, 2),
    ),
  zipCode: (zipCode?: string | null): boolean =>
    Boolean(zipCode && jsValidator.isPostalCode(zipCode, 'US') && zipCode.length === 5),
  state: (state?: string | null): boolean => Boolean(state && americanStates.find(({ value }) => value === state)),
  address: (address?: IAddressState): boolean =>
    Boolean(
      address &&
        validate.streetAddress(address.streetAddress) &&
        validate.suite(address.suite) &&
        validate.city(address.city) &&
        validate.state(address.state) &&
        validate.zipCode(address.zipCode),
    ),
  required: (value: string | undefined): boolean => Boolean(value?.trim()),
  cardDetails: {
    cardNumber: (cardNumber: string): boolean =>
      validate.required(cardNumber) && cardNumber.replace(/\s/g, '').length === 16,
    cvv: (cvv: string): boolean | string => cvv && cvv.length === 3,
    expirationDate: (expirationDate: string): boolean | string => expirationDate && expirationDate.trim().length === 5,
    isNotExpired: (expirationDate: string): boolean =>
      DateTime.fromFormat(expirationDate, DATE_MONTH_YEAR_FORMAT).endOf('month').diffNow('days').as('days') > 0,
  },
  email: (email: string): boolean =>
    regex.email.test(email) &&
    email.length <= maxLengths.email &&
    regex.emailRules.TLD.test(email) &&
    passesGmailCheck(email),
  emailUnique: (ownerId: string, owners: IOwners): boolean => {
    const currentOwner = owners[ownerId]
    return !Object.keys(owners)
      .filter((id) => id != ownerId)
      .some((id) => owners[id]?.email.toLowerCase() === currentOwner?.email.toLowerCase())
  },
  birthdate: (birthdate?: DateTime): boolean =>
    Boolean(
      birthdate && validate.isValidDate(birthdate) && validate.isOver18(birthdate) && validate.isAfter1900(birthdate),
    ),
  minFunding: (amount: number): boolean => amount >= amounts.funding.minValue,
  maxFunding: (amount: number): boolean => amount <= amounts.funding.maxValue,
  jobTitle: (jobTitle: string): boolean =>
    Boolean(validate.required(jobTitle) && validate.maxLength(jobTitle, maxLengths.jobTitle)),
  website: (url: string): boolean => jsValidator.isURL(url),
  optionalWebsite: (url?: string): boolean => (url ? jsValidator.isURL(url) : true),
}

export const passesGmailCheck = (email: string): boolean => {
  const isGmail = email.includes('@gmail.')
  return !isGmail || regex.emailRules.gmail.test(email)
}
