export const replaceURLWith = (url: string): void => window.history.pushState({}, '', url + window.location.search)

export const removeQueryParams = (params: string[]): void => {
  const searchParams = new URLSearchParams(window.location.search)
  params.forEach((param) => {
    searchParams.delete(param)
  })
  const searchString = searchParams.toString().length > 0 ? '?' + searchParams.toString() : ''
  const newUrl =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname +
    searchString +
    window.location.hash
  window.history.replaceState(null, '', newUrl)
}

export const reload = () => (window.location.href = window.location.href)
