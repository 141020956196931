import { LDProvider, LDSingleKindContext } from 'launchdarkly-react-client-sdk'
import { ReactNode, useEffect } from 'react'
import { useEmailSuspenseQuery } from '@/generated/graphql'
import { config, isProduction } from '@/utils/environment'
import { analytics } from '../analytics/events'
import { useAppSelector } from '../redux/utils'
import { featureFlagFallbacks } from './use-feature-flag'

export const LaunchDarklyWrapper = ({ children }: { children: ReactNode }) => {
  const businessId = useAppSelector((state) => state.application.businessId)
  const userId = useAppSelector((state) => state.application.userId)
  const { data } = useEmailSuspenseQuery({ skip: isProduction() })
  const email = data?.me?.email

  const context: LDSingleKindContext = {
    kind: 'user',
    key: userId,
    businessId,
    email: isProduction() || !email ? undefined : email,
  }

  useEffect(() => {
    if (userId && businessId) void analytics.featureFlags.identify({ userId, businessId })
  }, [businessId, userId])

  if (!userId || !businessId) return null

  return (
    <LDProvider
      clientSideID={config.launchDarklyClientSideId}
      flags={featureFlagFallbacks}
      reactOptions={{ useCamelCaseFlagKeys: false }}
      context={context}
    >
      {children}
    </LDProvider>
  )
}
