import {
  Modal,
  Box,
  PageHeader,
  Body,
  BodySizeEnum,
  Link,
  PrimaryButton,
  PageHeaderSizeEnum,
} from '@northone/ui-components'

import { useOnboardingTranslations } from '@/i18n/locales/en/en'

import { LinkURL } from '../constants'

const modalContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',
}

interface SSNModalProps {
  isSSNVisible: boolean
  onClose: () => void
}

export const SSNModal = ({ isSSNVisible = false, onClose }: SSNModalProps) => {
  const t = useOnboardingTranslations()
  return (
    <Modal isVisible={isSSNVisible}>
      <Box sx={modalContainerStyle}>
        <PageHeader color={'$charcoal7'} headingLevel={2} size={PageHeaderSizeEnum.SM}>
          {t('gettingStarted.modal.SSN.title')}
        </PageHeader>
        <Box sx={{ gap: '$2' }}>
          <Body size={BodySizeEnum.MD}>{t('gettingStarted.modal.SSN.whereToFindSSN')}</Body>
          <Body size={BodySizeEnum.MD}>{t('gettingStarted.modal.SSN.contactInstruction')}</Body>
          <Link href={LinkURL.REPLACECARD} isExternal={true}>
            {t('gettingStarted.modal.SSN.contactLink')}
          </Link>
        </Box>
        <PrimaryButton fullWidth testID={'done-button'} onPress={onClose}>
          {t('button.done')}
        </PrimaryButton>
      </Box>
    </Modal>
  )
}
