import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { fileUploadActions } from '@/core/redux/file-upload-redux/actions'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { Pathname } from '@/routes/constants'

import { DocumentUploadLayout } from './DocumentUploadLayout'
import { ProofOfIdentityModal } from './components/ProofOfIdentityModal'
import { useFileUploadNavigationContext } from './file-upload-context/use-file-upload-context'
import { useUploadFileToIntercomConversation } from './intercom'
import { useBase64FileConversion } from './use-base64-file-conversion'

export const DocumentUploadProofOfIdentityBack = () => {
  const navigate = useNavigate()
  const t = useOnboardingTranslations()
  const dispatch = useDispatch()
  const [isProofOfIdentityModalVisible, setIsProofOfIdentityModalVisible] = useState(false)
  const copy = {
    heading: t('inAppDocumentCapture.proofOfIdentityBack.heading'),
    subHeading: t('inAppDocumentCapture.proofOfIdentityBack.subHeading'),
    viewAcceptedDocs: t('inAppDocumentCapture.proofOfIdentityFront.viewAcceptedDocsText'),
  }
  const testIDs = {
    continueButton: 'upload-proof-of-identity-back-goback-button',
    backButton: 'upload-proof-of-identity-back-continue-button',
  }

  const { base64EncodedFile, objectURL, isLoading, onFileInput, fileName, fileContentType, hasUploadError, resetFile } =
    useBase64FileConversion()
  const previouslyUploadedFileName = useAppSelector((state) => state.uploadedFileNames.files.proofOfIdentityBack)
  const { uploadFileToIntercom, uploadFileLoading } = useUploadFileToIntercomConversation({
    fileName,
    fileContentType,
    fileData: base64EncodedFile,
  })
  const { getFileUploadNavigation } = useFileUploadNavigationContext()
  const { nextPath, prevPath } = getFileUploadNavigation(Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_BACK)

  const continueButtonProps = {
    testID: testIDs.continueButton,
    onPress: () => {
      if (previouslyUploadedFileName) {
        return navigate(nextPath)
      }
      if (!fileName) return
      uploadFileToIntercom?.().then(() => {
        dispatch(fileUploadActions.setFileName({ fileType: 'proofOfIdentityBack', name: fileName }))
        navigate(nextPath)
      })
    },
    disabled: !base64EncodedFile && !previouslyUploadedFileName && !fileName,
    loading: uploadFileLoading,
  }

  const backButtonProps = {
    testID: testIDs.backButton,
    onPress: () => navigate(prevPath),
  }

  const handleDeleteFile = () => {
    dispatch(fileUploadActions.deleteFile('proofOfIdentityBack'))
    resetFile()
  }

  const dismissProofOfIdentityModal = () => {
    setIsProofOfIdentityModalVisible(false)
    navigate(nextPath)
  }
  return (
    <>
      <ProofOfIdentityModal
        isProofOfIdentityModalVisible={isProofOfIdentityModalVisible}
        onDoNotHaveAnyOfThesePressed={dismissProofOfIdentityModal}
        onUploadButtonPressed={() => setIsProofOfIdentityModalVisible(false)}
      />
      <DocumentUploadLayout
        headingText={copy.heading}
        subHeadingText={copy.subHeading}
        viewAcceptedDocsText={copy.viewAcceptedDocs}
        onPressViewAcceptedDocs={() => setIsProofOfIdentityModalVisible(true)}
        continueButtonProps={continueButtonProps}
        backButtonProps={backButtonProps}
        onFileInput={onFileInput}
        onDeleteFile={handleDeleteFile}
        uploadedFileName={previouslyUploadedFileName}
        fileURL={objectURL}
        fileContentType={fileContentType}
        isUploadLoading={isLoading}
        hasUploadError={hasUploadError}
      />
    </>
  )
}
