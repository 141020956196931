import { useBackgroundQuery } from '@apollo/client'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import LoadingScreen from '@/components/LoadingScreen'
import { useAppSelector } from '@/core/redux/utils'
import { ConfirmBusinessTypeModal } from '@/routes/business-details/ConfirmBusinessTypeModal'
import { DisallowNonPrimaryOwner } from '../guards/disallow-non-primary-owner'
import { NAICS_QUERY } from './operations.gql'
import { SolePropDetectedModal } from './SolePropDetectedModal'

export default function BusinessDetailsRoot() {
  const freelancerModalOpen = useAppSelector((state) => state.funnel.freelancerModalOpen)
  const isConfirmBusinessTypeModalOpen = useAppSelector((state) => state.unpersisted.confirmBusinessTypeModalOpen)

  // Start fetching industries early
  useBackgroundQuery(NAICS_QUERY)

  if (isConfirmBusinessTypeModalOpen) {
    return <ConfirmBusinessTypeModal />
  }

  if (freelancerModalOpen) {
    return <SolePropDetectedModal />
  }

  return (
    <DisallowNonPrimaryOwner>
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
      </Suspense>
    </DisallowNonPrimaryOwner>
  )
}
