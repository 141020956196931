import { Outlet } from 'react-router-dom'
import { DisallowNonPrimaryOwner } from '../guards/disallow-non-primary-owner'
import { RequireActivePlaidItem } from '../guards/require-active-plaid-item'
import { RequireFundingEnabled } from '../guards/require-funding-enabled'
import { TopUpOptInScreenGuard } from './TopUpOptInScreen'

export default function TopUpRoot() {
  return (
    <DisallowNonPrimaryOwner>
      <RequireFundingEnabled>
        <RequireActivePlaidItem>
          <TopUpOptInScreenGuard>
            <Outlet />
          </TopUpOptInScreenGuard>
        </RequireActivePlaidItem>
      </RequireFundingEnabled>
    </DisallowNonPrimaryOwner>
  )
}
