import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import LoadingScreen from '@/components/LoadingScreen'
import { BaseLayout } from '@/layouts/BaseLayout'

import { persistState, REDUX_VERSION_NUMBER } from '.'
import { Pathname } from '../../routes/constants'
import { applicationActions } from '../redux/application-redux/application-actions'
import { unpersistedActions } from '../redux/unpersisted-redux/actions'
import { useAppSelector } from '../redux/utils'
import { persistStateActions } from './redux/actions'

export const PersistGate = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState(true)
  const businessId = useAppSelector((state) => state.application.businessId)
  const dispatch = useDispatch()

  const getAndSetState = useCallback(async () => {
    const storedState = await persistState.get()
    if (storedState) {
      // we ignore appVersion variable in the persisted state
      // eslint-disable-next-line no-unused-vars
      const { reduxVersion, appVersion: _appVersion, ...restOfState } = storedState
      if (reduxVersion === REDUX_VERSION_NUMBER && businessId === restOfState.application.businessId) {
        dispatch(persistStateActions.hydrate(restOfState))
        const {
          application: { hasStartedApplication },
        } = restOfState
        if (!hasStartedApplication && window.location.pathname !== Pathname.WELCOME_GETTING_STARTED) {
          dispatch(applicationActions.setHasStartedApplication(true))
        }
      }

      if (businessId) {
        setIsLoading(false)
        dispatch(unpersistedActions.setHasHydratedState(true))
      }
    } else {
      setIsLoading(false)
      dispatch(unpersistedActions.setHasHydratedState(true))
    }
  }, [dispatch, businessId])

  useEffect(() => {
    getAndSetState()
  }, [getAndSetState])

  return isLoading ? (
    <BaseLayout>
      <LoadingScreen />
    </BaseLayout>
  ) : (
    <>{children}</>
  )
}
