import { Box, WordmarkIcon, SizeEnum } from '@northone/ui-components'

import { AppMenu } from './DropdownMenu'

const headerStyles = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '$full',
  height: '$16',
  paddingHorizontal: 57,
  backgroundColor: '$white1',
  borderBottomWidth: 1,
  borderColor: '$charcoal1',
}

export function HeaderWithMenu() {
  return (
    <>
      <Box testID={'onboarding-header'} sx={headerStyles}>
        <WordmarkIcon size={SizeEnum.SM} />
        <AppMenu />
      </Box>
    </>
  )
}
