import { FunnelActionEnum, TFunnelActions } from './actions'
import { initialFunnelState, IFunnelState } from './state'

export const funnelReducer = (state: IFunnelState = initialFunnelState, action: TFunnelActions): IFunnelState => {
  switch (action.type) {
    case FunnelActionEnum.SET_FREELANCER_MODAL_OPEN:
      return {
        ...state,
        freelancerModalOpen: action.payload,
      }
    default:
      return state
  }
}
