import {
  Body,
  Box,
  FlatButton,
  ICONS,
  Modal,
  OnboardingHeader,
  OnboardingHeaderSizeEnum,
  PrimaryButton,
} from '@northone/ui-components'

import { useOnboardingTranslations } from '@/i18n/locales/en/en'

interface FindYourDBAModalProps {
  isFindYourDBAModalVisible: boolean
  onStillCantFindThesePressed: () => void
  onUploadButtonPressed: () => void
}

export const FindYourDBAModal = ({
  isFindYourDBAModalVisible = false,
  onStillCantFindThesePressed,
  onUploadButtonPressed,
}: FindYourDBAModalProps) => {
  const t = useOnboardingTranslations()

  const title = t('inAppDocumentCapture.DBAModal.title')
  const subHeading = t('inAppDocumentCapture.DBAModal.subHeading')
  const couldInclude = t('inAppDocumentCapture.DBAModal.couldInclude')
  const businessLicense = t('inAppDocumentCapture.DBAModal.list.businessLicense')
  const dba = t('inAppDocumentCapture.DBAModal.list.dba')
  const tradeName = t('inAppDocumentCapture.DBAModal.list.tradeName')

  const uploadNowButtonText = t('inAppDocumentCapture.proofOfIdentityModal.uploadNowButtonText')
  const stillCantFindButtonText = t('inAppDocumentCapture.DBAModal.stillCantFind')

  return (
    <Modal isVisible={isFindYourDBAModalVisible}>
      <Box sx={{ gap: '$6', flexShrink: 1 }}>
        <OnboardingHeader headingLevel={1} size={OnboardingHeaderSizeEnum.MD}>
          {title}
        </OnboardingHeader>
        <Box sx={{ gap: '$2' }}>
          <Body>{subHeading}</Body>
          <Body>{couldInclude}</Body>
          <Body>
            <ul style={{ marginBlockStart: 0, marginBlockEnd: 0, paddingInlineStart: 24 }}>
              <li>{businessLicense}</li>
              <li>{dba}</li>
              <li>{tradeName}</li>
            </ul>
          </Body>
        </Box>
        <Box sx={{ gap: '$2' }}>
          <PrimaryButton testID="find-DBA-modal-upload-now-button" onPress={onUploadButtonPressed} fullWidth>
            {uploadNowButtonText}
          </PrimaryButton>
          <FlatButton
            icon={ICONS.ArrowIcon}
            iconPosition="right"
            testID="find-DBA-modal-dismiss"
            onPress={onStillCantFindThesePressed}
            fullWidth
          >
            {stillCantFindButtonText}
          </FlatButton>
        </Box>
      </Box>
    </Modal>
  )
}
