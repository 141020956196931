import { FileUploadActionEnum, TFileUploadActions } from './actions'
import { initialUploadedFilenameState, IUploadedFilenameState } from './state'

export const fileUploadReducer = (
  state: IUploadedFilenameState = initialUploadedFilenameState,
  action: TFileUploadActions,
): IUploadedFilenameState => {
  switch (action.type) {
    case FileUploadActionEnum.SET_FILE_NAME:
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.fileType]: action.payload.name,
        },
      }
    case FileUploadActionEnum.SET_INTERCOM_CONVERSATION_ID:
      return {
        ...state,
        intercomConversationId: action.payload,
      }
    case FileUploadActionEnum.DELETE_FILE:
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload]: undefined,
        },
      }
    default:
      return state
  }
}
