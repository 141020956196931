import { gql } from '@apollo/client'

export const CREATE_LINK_TOKEN = gql`
  mutation createLinkToken($businessId: ID!, $itemId: String, $redirectUri: String) {
    createLinkToken(businessId: $businessId, itemId: $itemId, redirectUri: $redirectUri) {
      success
      code
      message
      data {
        linkToken
        expirationTimestamp
      }
    }
  }
`

export const CAPTURE_INITIAL_FUNDING_AMOUNT = gql`
  mutation captureInitialFundingAmount($businessId: ID!, $amountInCents: Int!, $businessType: BusinessType) {
    captureInitialFundingAmount(businessId: $businessId, amountInCents: $amountInCents, businessType: $businessType) {
      success
      code
      message
    }
  }
`

export const EXCHANGE_PUBLIC_TOKEN = gql`
  mutation exchangePublicToken(
    $businessId: ID!
    $plaidAccountId: String!
    $publicToken: String!
    $institutionName: String
    $accountType: PlaidAccountType
    $identityCheckInputs: IdentityCheckInputs
  ) {
    exchangePublicToken(
      businessId: $businessId
      plaidAccountId: $plaidAccountId
      publicToken: $publicToken
      institutionName: $institutionName
      accountType: $accountType
      identityCheckInputs: $identityCheckInputs
    ) {
      data {
        itemId
      }
      success
      code
      message
    }
  }
`

export const GET_FUNDING_STATUS = gql`
  query getFundingStatus($businessId: ID!) {
    getFundingStatus(businessId: $businessId) {
      status
    }
  }
`

export const GET_FUNDING_AMOUNTS = gql`
  query getFundingAmounts($businessId: ID!, $businessType: BusinessType) {
    getFundingAmounts(businessId: $businessId, businessType: $businessType) {
      maximumAmount
      minimumAmount
      defaultAmount
    }
  }
`

export const GET_PLAID_FUNDING_SCREEN_DATA = gql`
  query getPlaidFundingScreenData($businessId: ID!) {
    plaidItems(businessId: $businessId) {
      data {
        status
        institution {
          name
          logo
        }
        maskedAccountNumber
      }
    }
    getPlaidBalance(businessId: $businessId) {
      data {
        accounts {
          account_id
          balances {
            available
          }
        }
      }
    }
  }
`

export const FUNDING_LANDING_SCREEN = gql`
  query fundingLandingScreen($businessId: ID!) {
    plaidItems(businessId: $businessId) {
      data {
        id
        status
      }
    }
  }
`

export const FUNDING_AMOUNT_SCREEN = gql`
  query fundingAmountScreen($businessId: ID!, $businessType: BusinessType) {
    fundingAmounts: getFundingAmounts(businessId: $businessId, businessType: $businessType) {
      maximumAmount
      minimumAmount
      defaultAmount
    }

    plaidItems(businessId: $businessId) {
      data {
        id
        status
        accountId
        institution {
          name
        }
      }
    }
  }
`

export const PERFORM_INTERCOM_COMMAND = gql`
  mutation PerformIntercomAPICommand($data: PerformIntercomAPICommandData!) {
    performIntercomAPICommand(data: $data) {
      code
      data
      message
      success
    }
  }
`
