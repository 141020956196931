import {
  Body,
  Box,
  Modal,
  PageHeader,
  PageHeaderSizeEnum,
  PrimaryButton,
  SecondaryButton,
} from '@northone/ui-components'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { analytics } from '@/core/analytics/events'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { unpersistedActions } from '@/core/redux/unpersisted-redux/actions'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'

import { Pathname } from '../constants'

export const ConfirmBusinessTypeModal = () => {
  const t = useOnboardingTranslations()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const title = t('businessDetails.confirmBusinessTypeModal.title')
  const subtitle = t('businessDetails.confirmBusinessTypeModal.subtitle')
  const editButtonText = t('businessDetails.confirmBusinessTypeModal.editButton')
  const updateButtonText = t('businessDetails.confirmBusinessTypeModal.updateButton')

  const onUpdateBusinessType = () => {
    analytics.application.updateBusinessType()
    dispatch(unpersistedActions.setConfirmBusinessTypeModalOpen(false))
    dispatch(applicationActions.setBusinessType(null))
    navigate(Pathname.WELCOME_BUSINESS_TYPE)
  }
  const onEditBusinessName = () => {
    dispatch(applicationActions.setBusinessName(''))
    dispatch(unpersistedActions.setConfirmBusinessTypeModalOpen(false))
    navigate(Pathname.BUSINESS_DETAILS_ABOUT_YOUR_BUSINESS)
  }

  return (
    <Modal>
      <Box sx={{ gap: '$8' }}>
        <PageHeader size={PageHeaderSizeEnum.MD} headingLevel={2}>
          {title}
        </PageHeader>

        <Body>{subtitle}</Body>

        <Box sx={{ gap: '$2' }}>
          <PrimaryButton testID="business-type-modal-update" onPress={onUpdateBusinessType} fullWidth>
            {updateButtonText}
          </PrimaryButton>
          <SecondaryButton testID="business-type-modal-edit" onPress={onEditBusinessName} fullWidth>
            {editButtonText}
          </SecondaryButton>
        </Box>
      </Box>
    </Modal>
  )
}
