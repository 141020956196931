import { gql } from '@apollo/client'

export const NAICS_QUERY = gql`
  query naics {
    naics {
      groupedIndustries {
        vertical
        industries {
          naicsCategory
          naicsCode
          subCategory
          keywords
          vertical
        }
      }
      defaultIndustry {
        naicsCategory
        naicsCode
        subCategory
        keywords
        vertical
      }
    }
  }
`
export const CHECK_ONBOARDING_APPLICATION_ELIGIBILITY_MUTATION = gql`
  mutation CheckOnboardingApplicationEligibility($data: PartialOnboardingApplicationDataWithoutSSN!) {
    checkOnboardingApplicationEligibility(data: $data) {
      code
      success
      message
      data {
        isEligible
      }
    }
  }
`
