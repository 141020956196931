import { ApplicationActionEnum, TApplicationActions } from '../application-redux/application-actions'
import { TUnpersistedActions, UnpersistedActionEnum } from './actions'
import { initialUnpersistedState, ISSNsState, IUnpersistedState } from './state'

export const reducer = (
  state: IUnpersistedState = initialUnpersistedState,
  action: TUnpersistedActions | TApplicationActions,
): IUnpersistedState => {
  switch (action.type) {
    case UnpersistedActionEnum.SET_SHOW_FORM_ERRORS:
      return {
        ...state,
        showFormErrors: action.payload,
      }
    case UnpersistedActionEnum.SET_SSN:
      return {
        ...state,
        ssns: { ...state.ssns, [action.payload.ownerId]: action.payload.ssn },
      }
    case UnpersistedActionEnum.SET_HAS_HYDRATED_STATE:
      return {
        ...state,
        hasHydratedState: action.payload,
      }
    case ApplicationActionEnum.DELETE_OWNER: {
      const newSsns = Object.keys(state.ssns).reduce<ISSNsState>((ssns, ownerId) => {
        if (ownerId !== action.payload) {
          const ssn = state.ssns[ownerId]
          if (ssn === undefined) {
            throw new Error(`SSN for ownerId ${ownerId} is undefined`)
          }
          ssns[ownerId] = ssn
        }
        return ssns
      }, {})
      return {
        ...state,
        ssns: newSsns,
      }
    }
    case UnpersistedActionEnum.SET_FORM_SCROLL_TRANSITION_TIME:
      return {
        ...state,
        formScrollTransitionTime: action.payload,
      }
    case UnpersistedActionEnum.SET_FORM_SECTION_INDEX:
      return {
        ...state,
        formSectionIndex: action.payload,
      }
    case UnpersistedActionEnum.FORM_SCROLL_BACK:
      return {
        ...state,
        formSectionIndex: state.formSectionIndex > 0 ? state.formSectionIndex - 1 : state.formSectionIndex,
      }
    case UnpersistedActionEnum.SET_SCROLL_IS_DISABLED:
      return {
        ...state,
        scrollIsDisabled: action.payload,
      }
    case UnpersistedActionEnum.SET_CANNOT_SERVE_USER:
      return {
        ...state,
        cannotServeUser: action.payload,
      }
    case UnpersistedActionEnum.SET_CONFIRM_BUSINESS_TYPE_MODAL_OPEN: {
      return {
        ...state,
        confirmBusinessTypeModalOpen: action.payload,
      }
    }
    case UnpersistedActionEnum.SET_ADDRESS:
      return {
        ...state,
        [action.payload.type === 'HOME' ? 'homeAddress' : 'businessAddress']: action.payload.address,
      }
    case UnpersistedActionEnum.SET_MAILING_ADDRESS: {
      return {
        ...state,
        mailingAddress: action.payload,
      }
    }
    default:
      return state
  }
}
