import * as Sentry from '@sentry/react'
import { SearchField } from '@northone/ui-components'
import { SearchInputOption } from '@northone/ui-components/build/components/Fields/Search/hooks/useSearchOptions'
import { useState } from 'react'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete'

import { IAddressState } from '@/core/redux/application-redux/application-state'

export interface AddressAutocompleteProps {
  placeholder: string
  label?: string
  helperText?: string
  errorText?: string
  onSelect: (address: IAddressState) => void
}

export default function AddressAutocompleteInput({
  helperText,
  errorText,
  placeholder,
  label,
  onSelect,
}: AddressAutocompleteProps) {
  const [searchTerm, setSearchTerm] = useState('')

  const onSuggestionSelect = async (description: string, placeId: string) => {
    try {
      setSearchTerm('')
      const geocodeResults = await geocodeByPlaceId(placeId)
      if (geocodeResults.length === 0 || !geocodeResults[0]) {
        Sentry.captureMessage('[Maps-Error]: No valid geocode results returned')
        return
      }
      const geocodeResult = geocodeResults[0]
      const { address_components, geometry } = geocodeResult

      if (!address_components || !geometry) {
        Sentry.captureMessage('[Maps-Error]: Geocode result missing required properties')
        return
      }
      const {
        location: { lat, lng },
      } = geometry
      onSelect({
        city: getAddressComponent(address_components, ['locality', 'sublocality_level_1'])?.long_name ?? '',
        state: getAddressComponent(address_components, 'administrative_area_level_1')?.short_name ?? '',
        description,
        streetAddress: `${getAddressComponent(address_components, 'street_number')?.long_name} ${
          getAddressComponent(address_components, 'route')?.long_name
        }`,
        coordinates: { longitude: lng(), latitude: lat() },
        suite: '',
        zipCode: getAddressComponent(address_components, 'postal_code')?.short_name ?? '',
      })
    } catch (e) {
      Sentry.captureMessage(`[Maps-Error]: ${e}`)
    }
  }

  const onOptionSelected = (option: SearchInputOption) => {
    onSuggestionSelect(option.text, option.value)
  }

  return (
    <GooglePlacesAutocomplete
      searchOptions={{
        types: ['address'],
        componentRestrictions: { country: 'us' },
      }}
      onSelect={onSuggestionSelect}
      value={searchTerm}
      onChange={(addressPart) => setSearchTerm(addressPart)}
    >
      {({ getInputProps, suggestions }) => {
        const inputProps = getInputProps()
        return (
          <SearchField
            searchText={searchTerm}
            labelText={label}
            helperText={helperText}
            errorText={errorText}
            placeholderText={placeholder}
            options={suggestions.map((suggestion) => ({ value: suggestion.placeId, text: suggestion.description }))}
            onChangeSearchText={(text) => text && inputProps.onChange({ target: { value: text } })}
            onSelectOption={onOptionSelected}
          />
        )
      }}
    </GooglePlacesAutocomplete>
  )
}

const getAddressComponent = (components: google.maps.GeocoderAddressComponent[], componentName: string | string[]) =>
  components.find((component) =>
    typeof componentName === 'string'
      ? component.types.includes(componentName)
      : getIntersection(component.types, componentName).length,
  )

const getIntersection = <T extends unknown>(arr1: T[], arr2: T[]) => arr1.filter((value) => arr2.includes(value))
