import { AlertTypeEnum, Box, Checkbox, InlineAlert, Link } from '@northone/ui-components'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ContinueButton from '@/components/ContinueButton.tsx'
import GoBackButton from '@/components/GoBackButton.tsx'
import Loader from '@/components/Loader.tsx'
import LoadingScreen from '@/components/LoadingScreen.tsx'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { LinkURL } from '@/routes/welcome/constants'

import { analytics } from '../../core/analytics/events'
import { auth, Auth0BusinessClaim, getBusinessClaims } from '../../core/auth/auth-service'
import { useAppSelector } from '../../core/redux/utils'
import {
  TermsAndConditionsScreenQuery,
  useOnboardingAgreementsTermsAndConditionsAcceptMutation,
  useTermsAndConditionsScreenQuery,
} from '../../generated/graphql'
import { Pathname } from '../constants'

const PDF_HEIGHT = 274
const PDF_MAX_WIDTH = 548

function pause(miliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, miliseconds))
}

const iframeStyles = {
  minHeight: PDF_HEIGHT,
  width: PDF_MAX_WIDTH,
  maxWidth: '100%',
  height: '100%',
}

const termsContainerStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderColor: '$charcoal1',
  borderWidth: 1,
  borderRadius: '$sm',
  height: PDF_HEIGHT,
  width: '$full',
  maxWidth: PDF_MAX_WIDTH,
}

interface TermsAndConditionsPDFProps {
  termsAndConditionsPDFUrl: string | undefined | null
}

const TermsPDF = ({ termsAndConditionsPDFUrl }: TermsAndConditionsPDFProps) => {
  return (
    <Box sx={termsContainerStyles}>
      {termsAndConditionsPDFUrl ? <iframe style={iframeStyles} src={termsAndConditionsPDFUrl} /> : <Loader />}
    </Box>
  )
}

interface TermAndConditionsLinksProps {
  accountAgreementPDFURL: string | undefined
  esignAgreementPDFURL: string | undefined
  mobilePaymentServicesAgreementPDFURL: string | undefined
}

const TermAndConditionsLinksSection = ({
  accountAgreementPDFURL,
  esignAgreementPDFURL,
  mobilePaymentServicesAgreementPDFURL,
}: TermAndConditionsLinksProps) => {
  const t = useOnboardingTranslations()
  return (
    <Box sx={{ marginLeft: '$8', gap: '$0.5' }}>
      <Link isExternal={true} href={esignAgreementPDFURL}>
        {t('funnel.termsAndConditions.checkBoxText.esign')}
      </Link>
      <Link isExternal={true} href={accountAgreementPDFURL}>
        {t('funnel.termsAndConditions.checkBoxText.accountAgreement')}
      </Link>
      <Link isExternal={true} href={LinkURL.PRIVACY_AND_COOKIE_POLICY}>
        {t('funnel.termsAndConditions.checkBoxText.privacyCookiePolicy')}
      </Link>
      <Link isExternal={true} href={mobilePaymentServicesAgreementPDFURL}>
        {t('funnel.termsAndConditions.checkBoxText.walletServices')}
      </Link>
    </Box>
  )
}

export default function WelcomeTermsAndConditions({ nextPage }: { nextPage: Pathname }) {
  const businessId = useAppSelector((state) => state.application.businessId)
  const navigate = useNavigate()
  const t = useOnboardingTranslations()
  const dispatch = useDispatch()

  const title = t('funnel.termsAndConditions.title')

  const isCheckBoxSelected = useAppSelector((state) => state.application.hasAcceptedTermsAndConditions)
  const handleCheckboxChange = () => {
    dispatch(applicationActions.setTermsAndConditionsAccepted(!isCheckBoxSelected))
  }

  const [accountAgreementPDFURL, setAccountAgreementPDFURL] = useState<string>()
  const [esignAgreementPDFURL, setEsignAgreementPDFURL] = useState<string>()
  const [mobilePaymentServicesAgreementPDFURL, setMobilePaymentServiceAgreementPDFURL] = useState<string>()
  const [termsAndConditionsData, setTermsAndConditionsData] =
    useState<TermsAndConditionsScreenQuery['termsAndConditionsPDF']>()
  const businessClaims = useRef<Auth0BusinessClaim[]>([])

  const [shouldShowError, setShouldShowError] = useState(false)
  const [shouldShowLoadingScreen, setShouldShowLoadingScreen] = useState(false)
  const showError = () => setShouldShowError(true)
  const ErrorBanner = () => {
    return <InlineAlert type={AlertTypeEnum.Error}>{t('error.generic')}</InlineAlert>
  }

  const { loading: queryLoading, error: queryError } = useTermsAndConditionsScreenQuery({
    onError: showError,
    onCompleted: (data) => {
      setAccountAgreementPDFURL(data.accountAgreement.pdfURL)
      setEsignAgreementPDFURL(data.esignAgreement.pdfURL)
      setMobilePaymentServiceAgreementPDFURL(data.mobilePaymentServicesAgreement.pdfURL)
      setTermsAndConditionsData(data.termsAndConditionsPDF)
    },
  })

  const termsDocumentReference = termsAndConditionsData?.documentReference

  // Check for business claim on mount to speed up the continue function if the claim is present
  useEffect(() => {
    const getClaims = async () => {
      const claims = await getBusinessClaims({ ignoreCache: true })
      if (!claims.length) return
      businessClaims.current = claims
    }
    getClaims()
  }, [])

  const [acceptTermsAndConditions] = useOnboardingAgreementsTermsAndConditionsAcceptMutation({
    onError: showError,
  })

  if (queryError) {
    return <InlineAlert type={AlertTypeEnum.Error}>{t('funnel.termsAndConditions.error')}</InlineAlert>
  }

  const termsAndConditionsPDFUrl = termsAndConditionsData?.url
    ? `${termsAndConditionsData?.url}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0&view=FitH`
    : undefined

  const onContinue = async () => {
    setShouldShowError(false)
    if (!termsDocumentReference) {
      return showError()
    }
    setShouldShowLoadingScreen(true)
    analytics.funnel.termsConditions()
    // Ensure we have the required business claim before accepting terms
    while (!businessClaims.current.length) {
      // Add a pause to reduce the odds we hit our Auth0 rate limit
      await pause(1_000)
      const claims = await getBusinessClaims({ ignoreCache: true })
      if (claims.length) businessClaims.current = claims
    }
    try {
      await acceptTermsAndConditions({
        variables: { documentReference: termsDocumentReference, businessId },
      })
      await auth.getTokenSilently({ ignoreCache: true })
      navigate(nextPage)
    } catch {
      showError()
      setShouldShowLoadingScreen(false)
    }
  }

  if (shouldShowLoadingScreen) {
    return <LoadingScreen title="Saving your information" />
  }

  const onGoBack = () => {
    navigate(Pathname.WELCOME_QUALIFIERS)
  }

  return (
    <BaseContentLayout
      alertComponent={shouldShowError ? <ErrorBanner /> : null}
      headingText={title}
      primaryButton={
        <ContinueButton
          testID="terms-continue"
          onPress={onContinue}
          fullWidth
          loading={queryLoading || !termsAndConditionsPDFUrl}
          disabled={!isCheckBoxSelected || !termsDocumentReference}
        />
      }
      secondaryButton={<GoBackButton testID="terms-go-back" onPress={onGoBack} fullWidth />}
    >
      <TermsPDF termsAndConditionsPDFUrl={termsAndConditionsPDFUrl} />
      <Box sx={{ gap: '$1' }}>
        <Checkbox
          size="LG"
          testID="checkbox"
          isChecked={isCheckBoxSelected}
          value={'welcome-terms-declaration'}
          labelText={t('funnel.termsAndConditions.checkBoxText.acknowledge')}
          onChange={handleCheckboxChange}
        />
        <TermAndConditionsLinksSection
          accountAgreementPDFURL={accountAgreementPDFURL}
          esignAgreementPDFURL={esignAgreementPDFURL}
          mobilePaymentServicesAgreementPDFURL={mobilePaymentServicesAgreementPDFURL}
        />
      </Box>
    </BaseContentLayout>
  )
}
