import { createAction, IActionWithPayload, TActionsUnion } from '../utils'
import { IUploadedFilenameState } from './state'

export enum FileUploadActionEnum {
  SET_FILE_NAME = 'SET_FILE_NAME',
  SET_INTERCOM_CONVERSATION_ID = 'SET_INTERCOM_CONVERSATION_ID',
  DELETE_FILE = 'DELETE_FILE',
}

interface FileUpload {
  fileType: keyof IUploadedFilenameState['files']
  name: string
}

export const fileUploadActions = {
  setFileName: (value: FileUpload): IActionWithPayload<FileUploadActionEnum.SET_FILE_NAME, FileUpload> =>
    createAction(FileUploadActionEnum.SET_FILE_NAME, value),
  setIntercomConversationId: (
    value: string,
  ): IActionWithPayload<FileUploadActionEnum.SET_INTERCOM_CONVERSATION_ID, string> =>
    createAction(FileUploadActionEnum.SET_INTERCOM_CONVERSATION_ID, value),
  deleteFile: (
    fileType: keyof IUploadedFilenameState['files'],
  ): IActionWithPayload<FileUploadActionEnum.DELETE_FILE, keyof IUploadedFilenameState['files']> =>
    createAction(FileUploadActionEnum.DELETE_FILE, fileType),
}

export type TFileUploadActions = TActionsUnion<typeof fileUploadActions>
