import { Maybe } from 'graphql/jsutils/Maybe'
import {
  IAddressState,
  IApplicationState,
  initialAddressState,
  initialOwnerState,
  initialSocialMediaState,
  IOwner,
  PRIMARY_OWNER_ID,
} from '../../core/redux/application-redux/application-state'
import { IAddress, IApplicantBusiness, IPerson, PartnershipsAPIApplication } from './types'

const transformAddress = (address: Maybe<IAddress> | undefined) => {
  if (!address) {
    return initialAddressState
  }

  return {
    streetAddress: address.line1,
    suite: address.line2,
    city: address.city,
    state: address.state,
    zipCode: address.zipCode,
  } as IAddressState
}

const transformBusinessDetails = (business: Maybe<IApplicantBusiness> | undefined) => {
  return {
    businessType: business?.businessType,
    businessName: getDataOrDefault(business?.legalName),
    businessDescription: getDataOrDefault(business?.description),
    fullTimeEmployees: getDataOrDefault(business?.numberOfEmployees),
    businessPhoneNumber: getDataOrDefault(business?.phone),
    businessRevenue: getDataOrDefault(business?.annualRevenue),
    yearsAtLocation: getDataOrDefault(business?.yearsAtLocation),
    businessAddress: transformAddress(business?.address),
    ein: getDataOrDefault(business?.ein),
    tradeName: getDataOrDefault(business?.dba),
    socialMedia: { ...initialSocialMediaState, website: getDataOrDefault(business?.website) },
  } as Partial<IApplicationState>
}

const transformOwners = (persons: Maybe<Maybe<IPerson>[]> | undefined) => {
  if (!persons) {
    return initialOwnerState
  }

  const owners = persons.map((person) => {
    return {
      firstName: person?.firstName,
      lastName: person?.lastName,
      ownershipPercentage: person?.ownershipPercentage?.toString(),
      email: person?.email,
      phoneNumber: person?.phone,
      hasEnteredSSNBefore: false,
      jobTitle: person?.title,
      address: transformAddress(person?.address),
      birthdate: person?.dateOfBirth,
    } as IOwner
  })

  const primaryOwner = owners.find((owner) => owner.ownershipPercentage === '100')
  const otherOwners = owners.filter((owner) => owner.ownershipPercentage !== '100')

  if (!primaryOwner) {
    return initialOwnerState
  }

  const response = {
    owners: {
      [PRIMARY_OWNER_ID]: { ...primaryOwner },
      ...otherOwners,
    },
  }

  return response
}

export const transformApplication = (data: PartnershipsAPIApplication) => {
  const businessDetails = transformBusinessDetails(data.business)
  const owners = transformOwners(data.persons)

  return {
    ...businessDetails,
    ...owners,
    applicationId: undefined,
    prohibitedIndustriesCheck: false,
    controlPersonId: PRIMARY_OWNER_ID,
  } as IApplicationState
}

const getDataOrDefault = <T extends Maybe<string | number>>(data: T) => {
  return data?.toString() ?? ''
}
