import { IAddressState, initialAddressState } from '../application-redux/application-state'

export type TMailingAddress = 'BUSINESS' | 'HOME'

export interface IUnpersistedState {
  ssns: ISSNsState
  showFormErrors: boolean
  hasHydratedState: boolean
  formSectionIndex: number
  formScrollTransitionTime: number
  scrollIsDisabled: boolean
  cannotServeUser: boolean
  confirmBusinessTypeModalOpen: boolean
  mailingAddress: TMailingAddress | undefined
  homeAddress: IAddressState
  businessAddress: IAddressState
}
export const FORM_ANIMATION_TIME_IN_MS = 600

export type ISSNsState = Record<string, string>;

export const initialUnpersistedState: IUnpersistedState = {
  ssns: {},
  showFormErrors: false,
  hasHydratedState: false,
  formSectionIndex: 0,
  formScrollTransitionTime: FORM_ANIMATION_TIME_IN_MS,
  scrollIsDisabled: false,
  cannotServeUser: false,
  confirmBusinessTypeModalOpen: false,
  mailingAddress: undefined,
  homeAddress: initialAddressState,
  businessAddress: initialAddressState,
}
