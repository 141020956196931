import { Auth0Client, GetTokenSilentlyOptions } from '@auth0/auth0-spa-js'
import { analytics as segmentAnalytics } from '@northone/segment-js'
import { isMobile } from 'react-device-detect'

import { AUTH0_USER_METADATA_URL } from '../../utils'
import { config } from '../../utils/environment'
import { getURLSearchParameter, getUTMSearchParameters } from '../../utils/url-helpers'
import { analytics } from '../analytics/events'
import { client } from '../apollo/apollo-client'

// https://auth0.com/docs/libraries/common-auth0-library-authentication-errors
export enum Auth0Error {
  LOGIN_REQUIRED = 'login_required',
  BLOCKED = 'unauthorized',
}

export interface Auth0UserContext {
  email: string
  email_verified: boolean
  'https://northone.com/businesses'?: Auth0BusinessClaim[]
  [AUTH0_USER_METADATA_URL]?: Auth0UserMetadata
}

export interface Auth0BusinessClaim {
  id: string
  scopes: [string]
}

interface Auth0UserMetadata {
  papi_otp?: string
  partner_id?: string
  utm_campaign?: string
  mobile_phone?: string
  signup_promo_code?: string
  utm_source?: string
  utm_term?: string
  utm_medium?: string
  utm_content?: string
  utm_creative?: string
}

export const BASE_SCOPES = 'openid email profile email address phone'
const BUSINESS_CLAIM = 'https://northone.com/businesses'
const BUSINESS_TOKEN_PREFIX = 'business_token'

export const auth = new Auth0Client({
  domain: config.auth0.domain,
  client_id: config.auth0.clientId,
  redirect_uri: window.location.origin,
  audience: 'northoneCoreApi',
  advancedOptions: {
    defaultScope: BASE_SCOPES,
  },
})

// Make Auth0 client available on window for logout capabilities in e2e tests
window.auth = auth

/**
 * helper function to log the user out and clear application state
 */
let loggedOut = false
export const isLoggedOut = (): boolean => loggedOut
export const logout = (): void => {
  loggedOut = true
  analytics.session.end({ deviceType: isMobile ? 'mobile' : 'desktop' })
  segmentAnalytics().reset()
  client.resetStore().finally(() => {
    auth.logout({ returnTo: `${window.location.origin}?logout=true` })
  })
}

/**
 * helper function to redirect the user to login
 * @param mode mode paramter to pass to SSO page
 */
export const redirectToLogin = (mode = 'signup'): Promise<void> => {
  const signupPromoCode = getURLSearchParameter('pc')
  const utmParams = getUTMSearchParameters()

  return auth.loginWithRedirect({
    mode,
    email: getURLSearchParameter('email'),
    papi_otp: getURLSearchParameter('papi_otp'),
    partner_id: getURLSearchParameter('partner_id'),
    ...utmParams,
    // Required for storing promo code on register
    pc: signupPromoCode,
    appState: {
      // This ensures that the promo code is returned when auth.handleRedirectCallback is called
      // on redirect back to JoinDot
      // Required for storing promo code on login
      pc: signupPromoCode,
    },
  })
}

/**
 * attempts to get an access token and throws if it fails
 */
export const getTokenSilently = async (params?: GetTokenSilentlyOptions): Promise<string | null> => {
  // IMPORTANT: sessions can stay alive if a token is requested during logout
  // see: https://bitbucket.org/northone/northone-web-banking/pull-requests/365/np-5720-return-null-token-after-logout
  if (loggedOut) {
    return null
  }
  // scopes are lost on refresh token grant, so we pass it in our own param.
  // https://github.com/auth0/auth0-spa-js/pull/463
  const { scope = '', ...rest } = params || {}
  const refreshScopes = `${BASE_SCOPES} ${scope}`.trim()
  const options = {
    ...rest,
    scope,
    refreshScopes,
  }

  return await auth.getTokenSilently(options)
}

/**
 * returns a regular access token or logs the user out
 */
const getRegularAccessTokenOrLogout = async (options?: GetTokenSilentlyOptions): Promise<string | null> => {
  try {
    return await getTokenSilently(options)
  } catch (error) {
    logout()
    return null
  }
}

/**
 * returns an access token with all included scopes or logs the user out
 */
export const getTokenOrLogout = async (options?: GetTokenSilentlyOptions): Promise<string | null> => {
  const businessScopes = await getBusinessScopes()
  return getRegularAccessTokenOrLogout({
    ...options,
    scope: businessScopes,
  })
}
/**
 * force renew authentication token
 */
export const renewTokenOrLogout = () => getTokenOrLogout({ cacheMode: 'off' })

/** @deprecated User getAuth0UserData */
export const getBusinessClaims = async (options?: GetTokenSilentlyOptions): Promise<Auth0BusinessClaim[]> => {
  // ensure we have a valid id-token to extract user details from
  await getRegularAccessTokenOrLogout(options)
  // get user details from token
  const user: Auth0UserContext | undefined = await auth.getUser()
  if (user === undefined) return []
  return user[BUSINESS_CLAIM] ?? []
}

/**
 * returns business scopes if any from current user, including the business_token scope.
 * @deprecated User getAuth0UserData
 */
const getBusinessScopes = async (): Promise<string> => {
  const businessClaims = await getBusinessClaims()
  if (businessClaims.length === 0) return ''
  const lastClaim = businessClaims[businessClaims.length - 1]
  if (!lastClaim) throw new Error('No business claims found')
  const { id: businessId, scopes: businessScopes } = lastClaim
  return `${businessScopes.join(' ')} ${BUSINESS_TOKEN_PREFIX}:${businessId}`
}

/** @deprecated User getAuth0UserData */
export const getUserMetadataFromAuth0IdToken = async (): Promise<Auth0UserMetadata> => {
  const idToken = await auth.getUser()
  return idToken[AUTH0_USER_METADATA_URL]
}

export type Auth0UserData = {
  email: string
  emailVerified: boolean
  businessId?: string
  metadata?: Auth0UserMetadata
  businessClaims?: Auth0BusinessClaim[]
  businessScopes?: string
}

export const getAuth0UserData = async (): Promise<Auth0UserData> => {
  const user = (await auth.getUser()) as unknown as Auth0UserContext | undefined
  if (!user) throw new Error('Could not get user from Auth0')

  const metadata = user[AUTH0_USER_METADATA_URL]
  const businessClaims = user[BUSINESS_CLAIM]
  const claimData = businessClaims ? businessClaims[businessClaims.length - 1] : undefined
  const businessScopes = claimData
    ? `${claimData.scopes.join(' ')} ${BUSINESS_TOKEN_PREFIX}:${claimData.id}`
    : undefined

  return {
    businessId: claimData?.id,
    metadata,
    email: user.email,
    emailVerified: user.email_verified,
    businessClaims,
    businessScopes,
  }
}
