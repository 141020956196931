import { Body, Box, CardText, CardTextSizeEnum, ICONS, LegalDisclaimerText } from '@northone/ui-components'
import { useNavigate } from 'react-router-dom'
import adTopUpsUrl from '@/assets/images/ad-topup.webp'
import ContinueButton from '@/components/ContinueButton'
import DepositAccountAgreementLink from '@/components/DepositAccountAgreementLink'
import { ListItem } from '@/components/ListItem'
import ResponsiveOnboardingHeader from '@/components/ResponsiveOnboardingHeader'
import { displayIntercomWidget } from '@/core/intercom/intercom-settings'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import {
  ExpectationScreenCardHalf,
  ExpectationScreenCardLayout,
  ExpectationScreenImage,
  ExpectationScreenLayout,
} from '@/layouts/ExpectationScreenLayout'
import { Pathname } from '@/routes/constants'

function Disclaimer() {
  const t = useOnboardingTranslations()
  const footnoteOneBeforeDAA = t('accountTopUps.landing.footnoteOneBeforeDAA')
  const footnoteOneAfterDAA = t('accountTopUps.landing.footnoteOneAfterDAA')

  return (
    <Box>
      <LegalDisclaimerText color="$charcoal5">
        {footnoteOneBeforeDAA} <DepositAccountAgreementLink /> {footnoteOneAfterDAA}
      </LegalDisclaimerText>
    </Box>
  )
}

export const AccountTopUpsLanding = () => {
  const navigate = useNavigate()
  const t = useOnboardingTranslations()

  displayIntercomWidget(true)

  const nextUp = t('accountTopUps.landing.nextUp')
  const title = t('accountTopUps.landing.title')
  const subtitle = t('accountTopUps.landing.howItWorks')

  const automticallyFund = t('accountTopUps.landing.automaticallyFund')
  const scheduledPayments = t('accountTopUps.landing.scheduledPayments')
  const adjustAnyTime = t('accountTopUps.landing.adjustAnyTime')

  const onContinue = () => {
    navigate(Pathname.ACCOUNT_TOP_UPS_SETUP)
  }

  const primaryButton = <ContinueButton testID="account-top-ups-landing-continue" onPress={onContinue} fullWidth />

  return (
    <ExpectationScreenLayout
      primaryButton={primaryButton}
      disclaimerComponent={<Disclaimer />}
      showProgressAutoSavedNote
    >
      <ExpectationScreenCardLayout>
        <ExpectationScreenCardHalf>
          <Box sx={{ gap: '$6' }}>
            <Box sx={{ gap: '$2' }}>
              <Body>{nextUp}</Body>
              <ResponsiveOnboardingHeader largerSize="md">{title}</ResponsiveOnboardingHeader>
            </Box>
            <Box sx={{ gap: '$4' }}>
              <CardText size={CardTextSizeEnum.XLG}>{subtitle}</CardText>

              <ListItem icon={ICONS.ExchangeIcon}>{automticallyFund}</ListItem>
              <ListItem icon={ICONS.CalendarIcon}>{scheduledPayments}</ListItem>
              <ListItem icon={ICONS.FilterIcon}>{adjustAnyTime}</ListItem>
            </Box>
          </Box>
        </ExpectationScreenCardHalf>
        <ExpectationScreenCardHalf>
          <ExpectationScreenImage
            src={adTopUpsUrl}
            alt="Testimonial: “I use the top ups to connect all of my bank accounts and their funds into North One. I know my North One account is always funded, especially helpful when I schedule payments well into the future.”"
          />
        </ExpectationScreenCardHalf>
      </ExpectationScreenCardLayout>
    </ExpectationScreenLayout>
  )
}
