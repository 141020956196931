import { Body, Box, CardText, CardTextSizeEnum, ICONS } from '@northone/ui-components'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import adFunding from '@/assets/images/marketing-ads/ad-funding.png'
import ContinueButton from '@/components/ContinueButton'
import { ListItem } from '@/components/ListItem'
import ResponsiveOnboardingHeader from '@/components/ResponsiveOnboardingHeader'
import { useAppSelector } from '@/core/redux/utils'
import { PlaidItemStatus, useFundingLandingScreenQuery } from '@/generated/graphql'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import {
  ExpectationScreenCardHalf,
  ExpectationScreenCardLayout,
  ExpectationScreenImage,
  ExpectationScreenLayout,
} from '@/layouts/ExpectationScreenLayout'
import { Pathname } from '@/routes/constants'

const FundingListContent = () => {
  const t = useOnboardingTranslations()
  const plaid = t('funding.landing.howItWorks.plaid')
  const makeDeposit = t('funding.landing.howItWorks.makeDeposit')
  const useFunds = t('funding.landing.howItWorks.useFunds')

  return (
    <Box sx={{ gap: '$2' }}>
      <ListItem icon={ICONS.PlaidIcon}>{plaid}</ListItem>
      <ListItem icon={ICONS.ATMCardIcon}>{makeDeposit}</ListItem>
      <ListItem icon={ICONS.WalletIcon}>{useFunds}</ListItem>
    </Box>
  )
}

export default function FundingLanding() {
  const t = useOnboardingTranslations()
  const navigate = useNavigate()
  const businessId = useAppSelector((state) => state.application.businessId)
  const [hasValidPlaidItem, setHasValidPlaidItem] = useState<boolean>()
  const [nextPage, setNextPage] = useState<Pathname>()

  useFundingLandingScreenQuery({
    variables: { businessId },
    onCompleted: (data) => {
      const dataHasValidPlaidItem = data.plaidItems?.data.some((item) => item.status === PlaidItemStatus.ACTIVE)
      setHasValidPlaidItem(dataHasValidPlaidItem)
    },
  })

  useEffect(() => {
    if (hasValidPlaidItem) setNextPage(Pathname.ACCOUNT_FUNDING_AMOUNT)
    if (hasValidPlaidItem === false) setNextPage(Pathname.ACCOUNT_FUNDING_LINK_EXTERNAL_ACCOUNT)
  }, [hasValidPlaidItem, setNextPage])

  const onContinue = () => {
    if (!nextPage) return
    navigate(nextPage)
  }

  const nextUp = t('accountUsage.landing.nextUp')
  const title = t('funding.landing.title')
  const subtitle = t('funding.landing.howItWorks.subtitle')

  const primaryButton = (
    <ContinueButton
      testID="funding-landing-continue"
      onPress={onContinue}
      disabled={!nextPage}
      loading={!nextPage}
      fullWidth
    />
  )

  return (
    <ExpectationScreenLayout primaryButton={primaryButton} showProgressAutoSavedNote>
      <ExpectationScreenCardLayout>
        <ExpectationScreenCardHalf>
          <Box sx={{ gap: '$6' }}>
            <Box sx={{ gap: '$2' }}>
              <Body>{nextUp}</Body>
              <ResponsiveOnboardingHeader largerSize="md">{title}</ResponsiveOnboardingHeader>
            </Box>
            <Box sx={{ gap: '$4' }}>
              <CardText color={'$charcoal7'} size={CardTextSizeEnum.XLG}>
                {subtitle}
              </CardText>
              <FundingListContent />
            </Box>
          </Box>
        </ExpectationScreenCardHalf>
        <ExpectationScreenCardHalf>
          <ExpectationScreenImage src={adFunding} alt="Phone screen showing NorthOne app" />
        </ExpectationScreenCardHalf>
      </ExpectationScreenCardLayout>
    </ExpectationScreenLayout>
  )
}
