import { Box } from '@northone/ui-components'
import { BankIcon, IconWithBackgroundDisabledIcon, SizeEnum } from '@northone/ui-components'
import React from 'react'

interface AccountLogoProps {
  logo: string | undefined
  institutionName: string | undefined
}

const fallbackIcon = <IconWithBackgroundDisabledIcon icon={BankIcon} size={SizeEnum.MD} />

export const AccountLogo: React.FC<AccountLogoProps> = ({ logo, institutionName }) => {
  return (
    <Box
      sx={{
        width: '$9',
        height: '$9',
        borderRadius: '$sm',
        borderWidth: 1,
        borderColor: '$charcoal1',
        bg: '$white1',
      }}
    >
      {logo ? <img src={`data:image/png;base64,${logo}`} alt={institutionName} /> : fallbackIcon}
    </Box>
  )
}
