import { useBackgroundQuery } from '@apollo/client'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import LoadingScreen from '@/components/LoadingScreen'
import { DisallowNonPrimaryOwner } from '../guards/disallow-non-primary-owner'
import { EMAIL_VERIFIED, TERMS_AND_CONDITIONS } from './operations.gql'

export const WelcomeRoot = () => {
  // Start fetching data for other pages in this section
  useBackgroundQuery(TERMS_AND_CONDITIONS)
  useBackgroundQuery(EMAIL_VERIFIED)

  return (
    <DisallowNonPrimaryOwner>
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
      </Suspense>
    </DisallowNonPrimaryOwner>
  )
}
