import { ApolloError } from '@apollo/client'
import { ErrorCodeEnum } from '../../generated/graphql'
import { get } from 'lodash'

export const hasGQLErrorType = (error: ApolloError, type: ErrorCodeEnum): boolean => {
  const errorCodes = error.graphQLErrors.map(graphQLError => get(graphQLError, 'extensions.code')) as ErrorCodeEnum[]
  return errorCodes.includes(type)
}

export const getGQLErrorTypes = (error: ApolloError): ErrorCodeEnum[] =>
  error.graphQLErrors.map(graphQLError => get(graphQLError, 'extensions.code')) as ErrorCodeEnum[]
