import { Box, CurrencyInput, NumberInput } from '@northone/ui-components'
import { unformat } from 'accounting'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ContinueButton from '@/components/ContinueButton'
import GoBackButton from '@/components/GoBackButton'
import { analytics } from '@/core/analytics/events'
import { accountUsageActions } from '@/core/redux/account-usage-redux/actions'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'
import { numberStringToOptionalNumber } from '@/utils/numberStringToOptionalNumber'

export default function MonthlyACHPayments() {
  const t = useOnboardingTranslations()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const numberOfTransactions = useAppSelector((state) => state.accountUsage.numberOfACHs)
  const averageAmount = useAppSelector((state) => state.accountUsage.averageACHAmount)

  const transactionType = t('accountUsage.transactionTypes.achs')

  const setAverageAmount = (value: number) => {
    // @ts-ignore TS2345
    dispatch(accountUsageActions.setTransactionUsageValue('averageACHAmount', value.toString()))
  }
  const setNumberOfTransactions = (value?: number) => {
    // @ts-ignore TS2345
    dispatch(accountUsageActions.setTransactionUsageValue('numberOfACHs', value?.toString()))
  }

  const continueIsDisabled = !numberOfTransactions || !averageAmount
  const onContinue = () => {
    analytics.accountUsage.numberOfACHs({ value: unformat(numberOfTransactions) })
    analytics.accountUsage.averageACHAmount({ value: unformat(averageAmount) })
    navigate(Pathname.USAGE_QUESTION_WIRE_PAYMENTS)
  }
  const onGoBack = () => {
    navigate(Pathname.USAGE_QUESTION_CHECK_DEPOSITS)
  }

  const tertiaryButtonProps = {
    text: t('accountUsage.numberOf.button', {
      transactionType: transactionType + 's',
    }),
    onClick: () => navigate(Pathname.USAGE_QUESTION_WIRE_PAYMENTS),
  }

  const title = t('accountUsage.numberOf.title.achs', {
    transactionType,
  })
  const numberOfACHLabel = t('accountUsage.numberOf.inputLabel.achs')
  const numberOfACHHelper = t('accountUsage.numberOf.subtitle')
  const averageACHAmountLabel = t('accountUsage.averageAmount.title', {
    transactionType,
  })
  const averageACHAmountHelper = t('accountUsage.numberOf.subtitle')
  const averageACHAmountPlaceholder = t('accountUsage.dollarSignPlaceholder')

  return (
    <BaseContentLayout
      headingText={title}
      primaryButton={
        <ContinueButton
          testID="usage-ach-payments-continue"
          onPress={onContinue}
          disabled={continueIsDisabled}
          fullWidth
        />
      }
      secondaryButton={<GoBackButton testID="usage-average-amount-go-back" onPress={onGoBack} fullWidth />}
      tertiaryButtonProps={tertiaryButtonProps}
    >
      <Box sx={{ gap: '$10' }}>
        <NumberInput
          testID="number-of-ach"
          labelText={numberOfACHLabel}
          helperText={numberOfACHHelper}
          onChange={setNumberOfTransactions}
          value={numberStringToOptionalNumber(numberOfTransactions)}
        />

        <CurrencyInput
          testID="average-ach-amount"
          labelText={averageACHAmountLabel}
          helperText={averageACHAmountHelper}
          placeholder={averageACHAmountPlaceholder}
          onChange={setAverageAmount}
          value={Number(averageAmount)}
          allowDecimals={false}
        />
      </Box>
    </BaseContentLayout>
  )
}
