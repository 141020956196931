import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { fileUploadActions } from '@/core/redux/file-upload-redux/actions'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { Pathname } from '@/routes/constants'

import { DocumentUploadLayout } from './DocumentUploadLayout'
import { ProofOfOwnershipModal } from './components/ProofOfOwnershipModal'
import { useFileUploadNavigationContext } from './file-upload-context/use-file-upload-context'
import { useUploadFileToIntercomConversation } from './intercom'
import { useBase64FileConversion } from './use-base64-file-conversion'

export const DocumentUploadProofOfOwnership = () => {
  const navigate = useNavigate()
  const t = useOnboardingTranslations()
  const dispatch = useDispatch()
  const [isProofOfOwnershipModalVisible, setIsProofOfOwnershipModalVisible] = useState(false)

  const copy = {
    heading: t('inAppDocumentCapture.proofOfOwnership.heading'),
    subHeading: t('inAppDocumentCapture.proofOfOwnership.subHeading'),
    viewAcceptedDocs: t('inAppDocumentCapture.proofOfOwnership.viewAcceptedDocsText'),
  }
  const testIDs = {
    continueButton: 'upload-proof-of-ownership-back-button',
    backButton: 'upload-proof-of-ownership-continue-button',
  }

  const { base64EncodedFile, objectURL, isLoading, onFileInput, fileName, fileContentType, hasUploadError, resetFile } =
    useBase64FileConversion()
  const previouslyUploadedFileName = useAppSelector((state) => state.uploadedFileNames.files.proofOfOwnership)
  const { uploadFileToIntercom, uploadFileLoading } = useUploadFileToIntercomConversation({
    fileName,
    fileContentType,
    fileData: base64EncodedFile,
  })
  const { getFileUploadNavigation } = useFileUploadNavigationContext()
  const { nextPath, prevPath } = getFileUploadNavigation(Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_OWNERSHIP)

  const continueButtonProps = {
    testID: testIDs.continueButton,
    onPress: () => {
      if (previouslyUploadedFileName) {
        return navigate(nextPath)
      }
      if (!fileName) return
      uploadFileToIntercom?.().then(() => {
        dispatch(fileUploadActions.setFileName({ fileType: 'proofOfOwnership', name: fileName }))
        navigate(nextPath)
      })
    },
    disabled: !base64EncodedFile && !previouslyUploadedFileName && !fileName,
    loading: uploadFileLoading,
  }

  const backButtonProps = {
    testID: testIDs.backButton,
    onPress: () => navigate(prevPath),
  }

  const handleDeleteFile = () => {
    dispatch(fileUploadActions.deleteFile('proofOfOwnership'))
    resetFile()
  }

  const dismissProofOfOwnershipModal = () => {
    setIsProofOfOwnershipModalVisible(false)
    navigate(nextPath)
  }

  return (
    <>
      <ProofOfOwnershipModal
        isProofOfOwnershipModalVisible={isProofOfOwnershipModalVisible}
        onDoNotHaveAnyOfThesePressed={dismissProofOfOwnershipModal}
        onUploadButtonPressed={() => setIsProofOfOwnershipModalVisible(false)}
      />
      <DocumentUploadLayout
        headingText={copy.heading}
        subHeadingText={copy.subHeading}
        viewAcceptedDocsText={copy.viewAcceptedDocs}
        onPressViewAcceptedDocs={() => setIsProofOfOwnershipModalVisible(true)}
        continueButtonProps={continueButtonProps}
        backButtonProps={backButtonProps}
        onFileInput={onFileInput}
        onDeleteFile={handleDeleteFile}
        uploadedFileName={previouslyUploadedFileName}
        fileContentType={fileContentType}
        fileURL={objectURL}
        isUploadLoading={isLoading}
        hasUploadError={hasUploadError}
      />
    </>
  )
}
