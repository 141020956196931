import { analytics as segmentAnalytics } from '@northone/segment-js'
import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { useAuth0UserContext } from '@/routes/providers/auth0-user'
import { useApplySignupPromoCodeMutation } from '../../generated/graphql'
import { applicationActions } from '../redux/application-redux/application-actions'
import { useAppSelector } from '../redux/utils'

export const useApplySignupPromoCode = () => {
  const dispatch = useDispatch()

  const userId = useAppSelector((state) => state.application.userId)
  const businessId = useAppSelector((state) => state.application.businessId)
  const hasAppliedPromoCode = useAppSelector((state) => state.application.hasAppliedPromoCode)
  const hasEmailVerifiedReduxState = useAppSelector((state) => state.application.emailVerified)
  const signupPromoCodeReduxState = useAppSelector((state) => state.application.signupPromoCode)

  const [idempotencyKey] = useState(uuid())

  const auth0UserData = useAuth0UserContext()
  const signupPromoCode = auth0UserData?.metadata?.signup_promo_code ?? signupPromoCodeReduxState
  const emailVerifiedAuth0 = auth0UserData?.emailVerified

  // We check emailVerified redux state and auth0 emailVerified context, since
  // email verified auth0 meta does not get updated via polling
  // so if on load the context does not have emailVerified yet populated, the
  // promo code may not be applied
  const emailVerified = hasEmailVerifiedReduxState || emailVerifiedAuth0

  const [applySignupPromoCode] = useApplySignupPromoCodeMutation()

  useEffect(() => {
    const recordSignupPromoCodeInAnalytics = () => {
      // This ensures that any GQL error that occurs here will have
      // the signupPromoCode tag which can be helpful with reporting/debugging
      // issues with applying a signup promo code
      Sentry.setTag('signupPromoCode', signupPromoCode)

      segmentAnalytics().identify(userId, { signupPromoCode: signupPromoCode })
    }

    if (!signupPromoCode || !userId || hasAppliedPromoCode) {
      return
    }

    recordSignupPromoCodeInAnalytics()
  }, [userId, hasAppliedPromoCode, signupPromoCode])

  useEffect(() => {
    if (!signupPromoCode || !businessId || !emailVerified || hasAppliedPromoCode) {
      return
    }

    const applyPromoCode = async () => {
      await applySignupPromoCode({
        variables: {
          input: {
            promoCode: signupPromoCode,
            shouldOverrideExisting: false,
            idempotencyKey,
            businessId,
          },
        },
      })
      dispatch(applicationActions.setHasAppliedPromoCode(true))
    }

    applyPromoCode()
  }, [businessId, emailVerified, signupPromoCode, dispatch, hasAppliedPromoCode, idempotencyKey, applySignupPromoCode])
}
