import { LegalDisclaimerText } from '@northone/ui-components'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import LoadingScreen from '@/components/LoadingScreen'
import { analytics } from '@/core/analytics/events'
import { displayIntercomWidget } from '@/core/intercom/intercom-settings'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { PRIMARY_OWNER_ID } from '@/core/redux/application-redux/application-state'
import { transformBusinessTypeToGQLInput } from '@/core/redux/application-redux/selectors'
import { useAppSelector } from '@/core/redux/utils'
import {
  PlaidFundingCapabilitiesQueryResponse,
  TopUpsQuery,
  usePreconfigureTopUpsSettingsMutation,
  useTopUpsLazyQuery,
} from '@/generated/graphql'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { Pathname } from '@/routes/constants'
import { TopUpOptInForm } from './TopUpOptInForm'
import { TopUpSettingsValues } from './types'

const convertToFullName = ({ firstName = '', lastName = '' }: { firstName: string; lastName: string }) => {
  return `${firstName} ${lastName}`.trim()
}

const NEXT_PAGE = Pathname.USAGE_QUESTIONS
const PREVIOUS_PAGE = Pathname.ACCOUNT_TOP_UPS

export type TopUpsQueryData = NonNullable<TopUpsQuery['plaidItems']>['data']

export const TopUpOptInScreen = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const t = useOnboardingTranslations()

  displayIntercomWidget(true)

  const [savingTopUpPreferences, setSavingTopUpPreferences] = useState(false)
  const [topUpLoading, setTopUpLoading] = useState(true)
  const [plaidItems, setPlaidItems] = useState<TopUpsQueryData>()
  const [topUpCapabilities, setTopUpCapabilities] = useState<PlaidFundingCapabilitiesQueryResponse['topUps']>()
  const [hasPreconfigureError, setHasPreconfigureError] = useState(false)
  const { businessId, businessType: rawBusinessType } = useAppSelector((state) => state.application)
  const businessTypeGQLInput = transformBusinessTypeToGQLInput(rawBusinessType)
  const primaryOwner = useAppSelector((state) => state.application.owners[PRIMARY_OWNER_ID])

  const [getTopUpData] = useTopUpsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.plaidItems) setPlaidItems(data.plaidItems.data)
      if (data.capabilities) setTopUpCapabilities(data.capabilities.topUps)
      setTopUpLoading(false)
    },
  })

  const idempotencyKey = uuid()

  const [preconfigureTopUpsSettings] = usePreconfigureTopUpsSettingsMutation()

  useEffect(() => {
    if (!businessId) return
    getTopUpData({
      variables: { businessId, businessType: businessTypeGQLInput },
    })
  }, [businessId, getTopUpData, rawBusinessType, businessTypeGQLInput])

  if (
    !primaryOwner ||
    plaidItems === undefined ||
    plaidItems.length === 0 ||
    topUpLoading ||
    !topUpCapabilities ||
    !rawBusinessType
  ) {
    return <LoadingScreen />
  }
  const itemId = plaidItems[0]?.id

  const onPreconfigureError = () => {
    analytics.topUpOptIn.errorSaving()
    setHasPreconfigureError(true)
    setSavingTopUpPreferences(false)
  }

  async function handleContinueButtonClicked() {
    setSavingTopUpPreferences(true)
    analytics.topUpOptIn.enableClick()
    if (!itemId) throw new Error('Plaid item ID is undefined')
    const topUpSettingsValues: TopUpSettingsValues = {
      itemId,
      topUpAmount: topUpAmountLimits.default,
      lowBalanceThreshold: minimumBalanceLimits.default,
    }
    await preconfigureTopUpsSettings({
      variables: { businessId, data: { ...topUpSettingsValues, idempotencyKey } },
    })
      .then(() => {
        dispatch(applicationActions.setHasCompletedTopUpOptInPage(true))
        navigate(NEXT_PAGE, { replace: true })
      })
      .catch(onPreconfigureError)
  }
  const handleGoBackButtonClicked = () => navigate(PREVIOUS_PAGE)
  const handleSkipTopUpsClicked = () => {
    setSavingTopUpPreferences(true)
    dispatch(applicationActions.setHasCompletedTopUpOptInPage(true))
    analytics.topUpOptIn.skipTopUpsClick()
    navigate(NEXT_PAGE)
  }

  const minimumBalanceLimits = {
    minimum: topUpCapabilities.amounts.minimumThresholdAmount,
    default: topUpCapabilities.amounts.defaultThresholdAmount,
  }
  const topUpAmountLimits = {
    minimum: topUpCapabilities.amounts.minimumAmount,
    maximum: topUpCapabilities.amounts.maximumAmount,
    default: topUpCapabilities.amounts.defaultAmount,
  }
  const maximumTopUpsPerThirtyDays = topUpCapabilities.velocityLimits.maxTransactionCountPer30DayRolling

  const plaidItem = plaidItems[plaidItems.length - 1]
  const applicantNameDisplay = convertToFullName({ firstName: primaryOwner.firstName, lastName: primaryOwner.lastName })

  const businessTypeDisplay = t(`funnel.businessType.${rawBusinessType}`).toString()
  const todaysDate = new Date()

  const topUpOptInDisclaimer = t('disclaimers.topUpOptIn', {
    applicantNameDisplay,
    dateDisplay: todaysDate.toDateString(),
    topUpsPer30Days: maximumTopUpsPerThirtyDays.toString(),
    businessTypeDisplay,
  })
  const legalDisclaimer = t('disclaimers.standard')
  const savingTopUpMessage = t('topUpOptIn.loading.savingTopUp')

  if (savingTopUpPreferences) return <LoadingScreen title={savingTopUpMessage} />

  if (!plaidItem) throw new Error('Plaid item is undefined')

  return (
    <TopUpOptInForm
      minimumBalanceLimits={minimumBalanceLimits}
      plaidItem={plaidItem}
      topUpAmountLimits={topUpAmountLimits}
      onPressContinueButton={handleContinueButtonClicked}
      onPressGoBackButton={handleGoBackButtonClicked}
      onPressSkipTopUps={handleSkipTopUpsClicked}
      hasSavingTopUpErrorMessage={hasPreconfigureError}
      topUpOptInDisclaimer={topUpOptInDisclaimer}
      disclaimerComponent={<LegalDisclaimerText>{legalDisclaimer}</LegalDisclaimerText>}
    />
  )
}

export const TopUpOptInScreenGuard = ({ children }: { children: ReactElement }) => {
  const navigate = useNavigate()
  const hasCompletedTopUpOptInPage = useAppSelector((state) => state.application.hasCompletedTopUpOptInPage)

  useEffect(() => {
    if (!hasCompletedTopUpOptInPage) return
    navigate(NEXT_PAGE, { replace: true })
  }, [hasCompletedTopUpOptInPage, navigate])

  return children
}
