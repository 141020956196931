import {
  BankIcon,
  Body,
  BodySizeEnum,
  Box,
  CardText,
  CardTextSizeEnum,
  IconWithBackgroundDisabledIcon,
  SizeEnum,
} from '@northone/ui-components'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { TopUpsQueryData } from './TopUpOptInScreen'

interface AccountDisplayProps {
  label: string
  plaidItem: TopUpsQueryData[number]
}

const accountFieldContainerStyles = {
  container: { gap: '$1' },
  itemsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '$4',
    paddingVertical: '$4',
    borderWidth: 1,
    borderColor: '$charcoal2',
    borderRadius: '$sm',
    paddingLeft: '$4',
    bg: '$white1',
  },
  logo: {
    width: '$9',
    height: '$9',
    borderRadius: '$sm',
    borderWidth: 1,
    borderColor: '$charcoal1',
    bg: '$white1',
  },
  text: { flexDirection: 'column' },
}
export function AccountField({ label, plaidItem }: AccountDisplayProps) {
  const t = useOnboardingTranslations()

  const defaultAccountName = t('topUpOptIn.defaultInstitution')

  const institutionLogoURI = plaidItem.institution?.logo ? `data:image/png;base64,${plaidItem.institution.logo}` : null
  const institutionName = plaidItem.institution?.name ?? defaultAccountName
  const maskedAccountNumber = plaidItem.maskedAccountNumber

  return (
    <Box sx={{ ...accountFieldContainerStyles.container }}>
      <Body size={BodySizeEnum.XS}>{label}</Body>

      <Box sx={{ ...accountFieldContainerStyles.itemsWrapper }}>
        <Box sx={{ ...accountFieldContainerStyles.logo }}>
          {institutionLogoURI ? (
            <img src={institutionLogoURI} alt={institutionName} />
          ) : (
            <IconWithBackgroundDisabledIcon icon={BankIcon} size={SizeEnum.MD} />
          )}
        </Box>
        <Box sx={{ ...accountFieldContainerStyles.text }}>
          <CardText size={CardTextSizeEnum.MD} color="$charcoal7">
            {institutionName}
          </CardText>
          <CardText size={CardTextSizeEnum.SM} color="$charcoal5">
            {`Bank Account ${maskedAccountNumber}`}
          </CardText>
        </Box>
      </Box>
    </Box>
  )
}
