import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import LoadingScreen from '@/components/LoadingScreen'
import { RequireNonDeclinedApplication } from '@/routes/guards/require-non-declined-application'
import { DisallowNonPrimaryOwner } from '../guards/disallow-non-primary-owner'

export default function OwnershipRoot() {
  return (
    <DisallowNonPrimaryOwner>
      <RequireNonDeclinedApplication>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </RequireNonDeclinedApplication>
    </DisallowNonPrimaryOwner>
  )
}
