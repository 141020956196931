import {
  AlertTypeEnum,
  Box,
  ICONS,
  IconWithBackground,
  InlineAlert,
  SelectionBoxInputGroup,
  SizeEnum,
} from '@northone/ui-components'
import ContinueButton from '@/components/ContinueButton'
import GoBackButton from '@/components/GoBackButton'
import Loader from '@/components/Loader'
import { TMailingAddress } from '@/core/redux/unpersisted-redux/state'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { CARD_SHIPPING_DAYS } from './constants'

interface IMailingAddressLayout {
  hasError: boolean
  loading: boolean
  updateLoading: boolean
  updateAddressAction: () => void
  isButtonDisabled: boolean | undefined
  businessAddress: string
  homeAddress: string
  onSelectedAddressChanged: (addressType: TMailingAddress) => void
  selectedAddress?: string
  onBackPressed?: () => void
}

export const MailingAddressLayout = ({
  hasError,
  loading,
  updateLoading,
  updateAddressAction,
  isButtonDisabled,
  businessAddress,
  homeAddress,
  selectedAddress,
  onSelectedAddressChanged,
  onBackPressed,
}: IMailingAddressLayout) => {
  const t = useOnboardingTranslations()
  const title = t('finishUp.debitCardAddress.title')
  const subtitle = t('finishUp.debitCardAddress.subtitle', { cardShippingTime: CARD_SHIPPING_DAYS.toString() })
  const shippingAddressLabel = t('finishUp.debitCardAddress.shippingAddress')
  const errorText = t('error.generic')

  const addressSelectorBoxOptions = [
    {
      action: t('finishUp.debitCardAddress.businessAddress'),
      description: businessAddress,
      leftItem: IconWithBackground({
        icon: ICONS.BuildingsIcon,
        backgroundColor: '$receiveHoneydew',
        size: SizeEnum.MD,
      }),
      value: 'BUSINESS' as TMailingAddress,
      testID: 'address-selector-business',
    },
    {
      action: t('finishUp.debitCardAddress.homeAddress'),
      description: homeAddress,
      leftItem: IconWithBackground({ icon: ICONS.HouseIcon, backgroundColor: '$receiveHoneydew', size: SizeEnum.MD }),
      value: 'HOME' as TMailingAddress,
      testID: 'address-selector-home',
    },
  ]

  const onSelectionChanged = (value: string) => {
    const mailingAddressType: TMailingAddress = value === 'BUSINESS' ? 'BUSINESS' : 'HOME'
    onSelectedAddressChanged(mailingAddressType)
  }

  const onContinue = () => {
    updateAddressAction()
  }

  return (
    <BaseContentLayout
      headingText={title}
      subHeadingText={subtitle}
      primaryButton={
        <ContinueButton
          testID="mailing-address-layout-continue"
          onPress={onContinue}
          loading={updateLoading}
          disabled={isButtonDisabled}
          fullWidth
        />
      }
      secondaryButton={
        onBackPressed && <GoBackButton testID="mailing-address-layout-back" onPress={onBackPressed} fullWidth />
      }
      alertComponent={hasError && <InlineAlert type={AlertTypeEnum.Error}>{errorText}</InlineAlert>}
    >
      <>
        {loading ? (
          <Box sx={{ alignItems: 'center' }}>
            <Loader />
          </Box>
        ) : (
          <SelectionBoxInputGroup
            labelText={shippingAddressLabel}
            value={selectedAddress}
            options={addressSelectorBoxOptions}
            onChange={onSelectionChanged}
          />
        )}
      </>
    </BaseContentLayout>
  )
}
