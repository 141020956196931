import { IKYBIndustry } from '../../../routes/business-details/industry-select/types'
import { BusinessType } from '../../../types'

export interface IApplicationState {
  userId: string
  applicationId: string | undefined
  businessId: string
  hasAcceptedTermsAndConditions: boolean
  isPrimaryOwner: boolean | undefined
  referralDataWasHydrated: boolean | undefined
  businessType: TBusinessTypeValues
  businessName: string
  tradeName?: string
  prohibitedIndustriesCheck: boolean
  selectedSameBusinessAndHomeAddressCheckbox?: boolean
  isUSCitizen: boolean | undefined
  /** Deprecated in favour of hasAcceptedTermsAndConditions
   * @deprecated
   */
  hasSelectedTermsAndConditionsCheckbox: boolean
  businessDescription: string
  fullTimeEmployees: string
  businessIndustry: IKYBIndustry | null
  businessPhoneNumber: string
  businessAddress: IAddressState
  socialMedia: ISocialMediaState
  businessRevenue: string
  hasStartedApplication: boolean
  hasRegisteredBusinessName: boolean | undefined
  isCorpWithMultipleOwners: boolean
  owners: IOwners
  ein: string
  controlPersonId: string
  yearsAtLocation: string
  yearsInOperation?: number
  hasClickedDocumentsUploaded: boolean
  socureDeviceSessionId?: string
  partnerInformation?: IPartnerInformationState
  hasCompletedTopUpOptInPage: boolean
  onlinePresence: IOnlinePresenceState
  emailVerified: boolean
  hasAppliedPromoCode: boolean
  signupPromoCode: string | undefined
}

export type IOwners = Record<string, IOwner>

export type TBusinessTypeValues = BusinessType | null

export interface IOwner {
  email: string
  firstName: string
  lastName: string
  birthdate: string
  phoneNumber: string
  address: IAddressState
  jobTitle: string
  isEditing: boolean
  hasEnteredSSNBefore: boolean
  ownershipPercentage: string
  hasSavedBefore: boolean
  isInviteCheckboxSelected?: boolean
}

export interface ISocialMediaState {
  website: string
  facebook: string
  twitter: string
  instagram: string
  linkedin: string
  otherSocialMedia: string
}

export interface IOnlinePresenceState {
  website?: string
  primarySocialMedia?: string
  usesEtsy?: boolean
  usesAmazon?: boolean
  usesCashApp?: boolean
  usesStripe?: boolean
  usesPayPal?: boolean
  usesShopify?: boolean
  usesSquare?: boolean
  usesOtherRevenueProvider?: boolean
  otherRevenueProvider?: string
}

const initialOnlinePresenceState: IOnlinePresenceState = {
  website: undefined,
  primarySocialMedia: undefined,
  usesEtsy: undefined,
  usesAmazon: undefined,
  usesStripe: undefined,
  usesPayPal: undefined,
  usesShopify: undefined,
  usesSquare: undefined,
  usesOtherRevenueProvider: undefined,
  otherRevenueProvider: undefined,
}

export interface IAddressState {
  streetAddress: string
  suite: string
  city: string
  state: string
  zipCode: string
  description: string
  coordinates: { longitude: number; latitude: number } | null
}
export interface IPartnerInformationState {
  otp?: string
  partnerName?: string | undefined
  partnerId?: string | undefined
}
export const initialPartnerInformationState: IPartnerInformationState = {
  otp: undefined,
  partnerName: undefined,
  partnerId: undefined,
}

export const initialAddressState: IAddressState = {
  streetAddress: '',
  suite: '',
  city: '',
  state: '',
  zipCode: '',
  coordinates: null,
  description: '',
}

export const PRIMARY_OWNER_ID = 'PRIMARY_OWNER_ID'
export const CONTROL_PERSON_CANDIDATE_ID = 'CONTROL_PERSON_CANDIDATE_ID'

export const initialOwnerState: IOwner = {
  email: '',
  address: initialAddressState,
  birthdate: '',
  firstName: '',
  lastName: '',
  jobTitle: '',
  phoneNumber: '',
  isEditing: true,
  hasEnteredSSNBefore: false,
  ownershipPercentage: '0',
  hasSavedBefore: false,
}

export const initialSocialMediaState: ISocialMediaState = {
  website: '',
  facebook: '',
  twitter: '',
  instagram: '',
  linkedin: '',
  otherSocialMedia: '',
}

export const initialApplicationState: IApplicationState = {
  businessId: '',
  applicationId: undefined,
  userId: '',
  hasAcceptedTermsAndConditions: false,
  isPrimaryOwner: undefined,
  referralDataWasHydrated: undefined,
  businessType: null,
  businessName: '',
  prohibitedIndustriesCheck: false,
  selectedSameBusinessAndHomeAddressCheckbox: false,
  tradeName: '',
  isUSCitizen: undefined,
  hasSelectedTermsAndConditionsCheckbox: false,
  businessDescription: '',
  fullTimeEmployees: '',
  ein: '',
  businessIndustry: null,
  businessPhoneNumber: '',
  businessAddress: initialAddressState,
  socialMedia: initialSocialMediaState,
  businessRevenue: '',
  hasStartedApplication: false,
  hasRegisteredBusinessName: undefined,
  owners: { [PRIMARY_OWNER_ID]: { ...initialOwnerState, ownershipPercentage: '100' } },
  isCorpWithMultipleOwners: false,
  controlPersonId: PRIMARY_OWNER_ID,
  yearsAtLocation: '',
  hasClickedDocumentsUploaded: false,
  socureDeviceSessionId: undefined,
  partnerInformation: initialPartnerInformationState,
  hasCompletedTopUpOptInPage: false,
  onlinePresence: initialOnlinePresenceState,
  emailVerified: false,
  hasAppliedPromoCode: false,
  signupPromoCode: undefined,
}
