import { NavigateFunction } from 'react-router-dom'
import { Pathname } from '../../routes/constants'
import { determineInitialRouteByMachine } from './init-route-by-machine'
import {
  DetermineInitialRouteProps,
  IInitialRouteProps,
  InitialRouteResponse,
  IReturnRouteNameToRoutingFunction,
} from './types'

type TPostOnboardingApplicationRouteName =
  | 'ACCOUNT_USAGE'
  | 'APPLICATION_STATUS'
  | 'EMAIL_VERIFY'
  | 'FINISH_UP'
  | 'MANUAL_REVIEW_GATE_MORE_INFO'
  | 'REJECTED'
  | 'WELCOME_SCREEN'

const routeNameToRoutingFunction = ({
  navigate,
  navigateToWebBanking,
}: IInitialRouteProps): IReturnRouteNameToRoutingFunction => {
  return {
    GETTING_STARTED: () => {
      navigate(Pathname.WELCOME_GETTING_STARTED, { replace: true })
    },
    BUSINESS_DETAILS: () => {
      navigate(Pathname.BUSINESS_DETAILS, { replace: true })
    },
    OWNER_DETAILS: () => {
      navigate(Pathname.OWNERSHIP_DETAILS, { replace: true })
    },
    PLAID_FUNDING: () => {
      navigate(Pathname.ACCOUNT_FUNDING_LINK_EXTERNAL_ACCOUNT, { replace: true })
    },
    EMAIL_VERIFY: () => {
      navigate(Pathname.WELCOME_EMAIL_VERIFY, { replace: true })
    },
    ACCOUNT_USAGE: () => {
      navigate(Pathname.USAGE_QUESTIONS, { replace: true })
    },
    APPLICATION_STATUS: () => {
      navigate(Pathname.APPLICATION_STATUS, { replace: true })
    },
    WELCOME_SCREEN: () => {
      navigate(Pathname.FINISH_UP_WELCOME_SCREEN, { replace: true })
    },
    REJECTED: () => {
      navigate(Pathname.REJECTED, { replace: true })
    },
    MANUAL_REVIEW_GATE_MORE_INFO: () => {
      navigate(Pathname.DOCUMENT_UPLOAD_FORM, { replace: true })
    },
    WEB_BANKING_REDIRECT: navigateToWebBanking,
    MAILING_ADDRESS: () => {
      navigate(Pathname.DEBIT_CARD_DELIVERY_ADDRESS, { replace: true })
    },
    PLAID_TOP_UP_OPT_IN: () => {
      navigate(Pathname.ACCOUNT_TOP_UPS, { replace: true })
    },
    FINISH_UP: () => {
      navigate(Pathname.FINISH_UP, { replace: true })
    },
    FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_FRONT: () => {
      navigate(Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_FRONT, { replace: true })
    },
    FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_BACK: () => {
      navigate(Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_BACK, { replace: true })
    },
    FINISH_UP_FILE_UPLOAD_PROOF_OF_OWNERSHIP: () => {
      navigate(Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_OWNERSHIP, { replace: true })
    },
    FINISH_UP_FILE_UPLOAD_BUSINESS_LICENSE: () => {
      navigate(Pathname.FINISH_UP_FILE_UPLOAD_BUSINESS_LICENSE, { replace: true })
    },
  }
}

export const initialRouteByMachine = ({
  stateMachine,
  accountStatus,
  applicationStatus,
  isEmailVerified,
  hasMailingAddress,
  applicationState,
  hasAcceptedTermsAndConditions,
  hasTeamMemberShippingAddress,
  navigate,
  navigateToWebBanking,
  isOnboardingComplete,
  hasPlaidLinkedAccount,
  isPlaidTopUpOptInEnabled,
  isPlaidEnabled,
  isManualReviewDocUploadEnabled,
  requiredManualReviewFiles,
}: DetermineInitialRouteProps & IInitialRouteProps & { navigate: NavigateFunction }): InitialRouteResponse => {
  const route = determineInitialRouteByMachine({
    stateMachine,
    accountStatus,
    applicationStatus,
    isEmailVerified,
    hasMailingAddress,
    applicationState,
    hasAcceptedTermsAndConditions,
    hasTeamMemberShippingAddress,
    isOnboardingComplete,
    isPlaidTopUpOptInEnabled,
    hasPlaidLinkedAccount,
    isPlaidEnabled,
    isManualReviewDocUploadEnabled,
    requiredManualReviewFiles,
  })

  // this function will build navigation function out of plain route string: 'ACCOUNT_USAGE'
  const navigationFunction = routeNameToRoutingFunction({
    navigate,
    navigateToWebBanking,
  })[route as TPostOnboardingApplicationRouteName]

  return navigationFunction
}
