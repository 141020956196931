import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import 'react-router-dom'

import LoadingScreen from '@/components/LoadingScreen'
import { useJoinTeamLayoutSuspenseQuery } from '@/generated/graphql'

export const AdditionalOwnerLayout = () => {
  const { data } = useJoinTeamLayoutSuspenseQuery()

  if (data.me?.isPrimaryOwner) {
    throw new Error('Primary owner cannot access Join Team pages')
  }

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Outlet />
    </Suspense>
  )
}
