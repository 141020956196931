import { useBackgroundQuery } from '@apollo/client'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import LoadingScreen from '@/components/LoadingScreen'
import { transformBusinessTypeToGQLInput } from '@/core/redux/application-redux/selectors'
import { useAppSelector } from '@/core/redux/utils'
import { RequireNonDeclinedApplication } from '@/routes/guards/require-non-declined-application'
import { DisallowNonPrimaryOwner } from '../guards/disallow-non-primary-owner'
import { RequireFundingEnabled } from '../guards/require-funding-enabled'
import { FUNDING_AMOUNT_SCREEN } from './operations.gql'

export default function FundingRoot() {
  const { businessId, businessType } = useAppSelector((state) => state.application)
  const businessTypeGQLInput = transformBusinessTypeToGQLInput(businessType)

  useBackgroundQuery(FUNDING_AMOUNT_SCREEN, {
    variables: {
      businessId,
      businessType: businessTypeGQLInput,
    },
  })

  return (
    <DisallowNonPrimaryOwner>
      <RequireNonDeclinedApplication>
        <RequireFundingEnabled>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </RequireFundingEnabled>
      </RequireNonDeclinedApplication>
    </DisallowNonPrimaryOwner>
  )
}
