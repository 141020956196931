import { createAction, IActionWithPayload, TActionsUnion } from '../../redux/utils'
import { IRootState } from '../../redux/root-state'

export enum PersistStateActionEnum {
  HYDRATE_STATE = 'HYDRATE_STATE',
}

export const persistStateActions = {
  hydrate: (state: IRootState): IActionWithPayload<PersistStateActionEnum, IRootState> =>
    createAction(PersistStateActionEnum.HYDRATE_STATE, state),
}

export type TPersistStateActions = TActionsUnion<typeof persistStateActions>
