import { Box } from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'
import { ReactNode } from 'react'

import { HeaderWithMenu } from '@/components/Header'

import { OnboardingHeaderMobile, OnboardingStepperProps } from './StepperLayout'

export const BaseLayout = ({
  children,
  sx,
  stepperProps,
}: {
  children: ReactNode
  sx?: React.CSSProperties
  stepperProps?: OnboardingStepperProps
}) => {
  const { isMobileSize } = useMobileScreenSize()

  return (
    <Box sx={{ backgroundColor: '$background', width: '100%', height: '100dvh', ...sx }}>
      <Box sx={{ zIndex: 2 }}>
        {isMobileSize ? <OnboardingHeaderMobile stepperProps={stepperProps} /> : <HeaderWithMenu />}
      </Box>
      {children}
    </Box>
  )
}
