import { createSelector, OutputSelector } from 'reselect'

import { BusinessType } from '../../../types'
import { isPartnership, isCorporation } from '../../../utils'
import { OWNERSHIP_PERCENTAGE_LOWER_BOUND, OWNERSHIP_PERCENTAGE_UPPER_BOUND } from '../../../utils/constants'
import { IRootState } from '../root-state'
import { CONTROL_PERSON_CANDIDATE_ID, IApplicationState, IOwner, IOwners, PRIMARY_OWNER_ID } from './application-state'

export const selectOwnersData = (state: IRootState): IOwners => state.application.owners
export const selectApplicationData = (state: IRootState): IApplicationState => state.application

export const getOwnersAreSaved = createSelector(
  [selectOwnersData],
  (owners): boolean =>
    !Object.keys(owners)
      .filter((ownerId) => ownerId !== CONTROL_PERSON_CANDIDATE_ID)
      .find((ownerId) => owners[ownerId]?.isEditing),
)

export const getHasMultipleOwners = createSelector(
  [selectApplicationData],
  ({ businessType, isCorpWithMultipleOwners }): boolean => {
    return isPartnership(businessType) || (isCorporation(businessType) && isCorpWithMultipleOwners)
  },
)

export const getIsMissingCoOwners = createSelector(
  [selectApplicationData, selectOwnersData],
  ({ isCorpWithMultipleOwners, businessType }, owners): boolean =>
    (isCorpWithMultipleOwners || businessType === BusinessType.PARTNERSHIP) && Object.keys(owners).length === 1,
)

export const selectOwnerIds = createSelector([selectOwnersData], (owners): string[] => Object.keys(owners))

export const selectOtherOwnerDataByID = (
  ownerId: string,
): OutputSelector<IRootState, IOwner, (res: IOwners) => IOwner> =>
  createSelector([selectOwnersData], (owners): IOwner => {
    const owner = owners[ownerId]
    if (!owner) {
      throw new Error(`Owner with ID ${ownerId} not found.`)
    }
    return owner
  })

export const selectOwnershipPercentages = createSelector(
  [selectOwnersData, selectApplicationData],
  (owners, applicationData): number[] => {
    return Object.keys(owners)
      .filter((ownerId) => ownerId !== CONTROL_PERSON_CANDIDATE_ID)
      .map((ownerId) => {
        const owner = applicationData.owners[ownerId]
        if (!owner) {
          throw new Error(`Owner with ID ${ownerId} not found.`)
        }
        return Number(owner.ownershipPercentage)
      })
  },
)

export const selectTotalOwnersOwnershipPercentage = createSelector(
  [selectOwnershipPercentages],
  (percentages): number => percentages.reduce((acc: number, curr: number) => acc + curr, 0),
)

export const selectOwnerHasPercentOwnershipLessThanLowerBound = createSelector(
  [selectOwnershipPercentages],
  (percentages): boolean =>
    percentages.find((percentage: number) => percentage < OWNERSHIP_PERCENTAGE_LOWER_BOUND) !== undefined,
)

export const selectTotalOwnershipPercentLessThanUpperBound = createSelector(
  [selectTotalOwnersOwnershipPercentage],
  (total): boolean => total < OWNERSHIP_PERCENTAGE_UPPER_BOUND,
)

export const selectHasOwnershipPercentageError = createSelector(
  [selectTotalOwnersOwnershipPercentage, selectOwnerHasPercentOwnershipLessThanLowerBound],
  (totalOwnership, ownerHasLessThen1): boolean =>
    totalOwnership > OWNERSHIP_PERCENTAGE_UPPER_BOUND || ownerHasLessThen1,
)

export const selectControlOwner = createSelector([selectOwnersData], (owners): IOwner | undefined =>
  PRIMARY_OWNER_ID in owners ? owners[PRIMARY_OWNER_ID] : undefined,
)

export const selectControlOwnerFirstName = createSelector([selectControlOwner], (owner): string | undefined =>
  owner ? owner.firstName : undefined,
)

export const selectControlOwnerLastName = createSelector([selectControlOwner], (owner): string | undefined =>
  owner ? owner.lastName : undefined,
)

export const selectInvitedOwnersEmails = createSelector([selectOwnersData], (owners) => {
  const ownerArray = Object.values(owners)
  return ownerArray.filter((owner) => owner.isInviteCheckboxSelected).map((owner) => owner.email)
})
