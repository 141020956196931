import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'
import { analytics } from '../analytics/events'

export interface FeatureFlags {
  'joindot-initial-funding': boolean
  'financial-admin-invites-enabled': boolean
  'joindot-custom-document-upload-ui-enabled': boolean
}

export const featureFlagFallbacks: FeatureFlags = {
  'joindot-initial-funding': true,
  'financial-admin-invites-enabled': false,
  'joindot-custom-document-upload-ui-enabled': false,
}

export type FeatureFlagKey = keyof FeatureFlags

export const useFeatureFlag = <FlagKey extends FeatureFlagKey>(flagKey: FlagKey): FeatureFlags[FlagKey] => {
  const flags = useFlags<FeatureFlags>()
  const result = flags[flagKey]

  useEffect(() => {
    if (result === undefined) return

    const sendAnalyticsEvent = async () => {
      await analytics.featureFlagEvaluated({ featureFlag: flagKey, result })
    }
    void sendAnalyticsEvent()
  }, [flagKey, result])

  //Extra precaution is taken to return default flag values in case flag value is undefined due to SDK or targeting failure
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (result === undefined) return featureFlagFallbacks[flagKey]
  return result
}
