import { DateTime } from 'luxon'
import phone from 'phone'

import { IAddressState } from '../core/redux/application-redux/application-state'
import { Location } from '../generated/graphql'
import { DATE_INPUT_FORMAT, ISO_DATE_FORMAT, regex } from '../utils'

interface StandardAddress {
  addressLine1: string
  addressLine2?: string
  city: string
  state: string
  zipCode: string
}

function toUSAddress(address: StandardAddress) {
  const { addressLine1, addressLine2, city, state, zipCode } = address
  let formattedAddressLine2 = ''

  if (addressLine2) {
    const parsedAddressLine2 = Number(addressLine2)
    formattedAddressLine2 = Number.isNaN(parsedAddressLine2) ? addressLine2 : `#${parsedAddressLine2}`
  }

  return `${addressLine1}${formattedAddressLine2 ? ` ${formattedAddressLine2},` : ','} ${city}, ${state} ${zipCode}`
}

export const format = {
  phoneNumber: (phoneNumber: string): string | undefined => phone(phoneNumber)[0] ?? phoneNumber,
  ein: (ein: string): string | undefined => ein.replace('-', ''),
  ssn: (ssn: string): string | undefined => ssn.replace('-', ''),
  address: {
    redux: (address: IAddressState): string => {
      const { streetAddress, suite, city, state, zipCode } = address
      return toUSAddress({ addressLine1: streetAddress, addressLine2: suite, city, state, zipCode })
    },
    gql: (address?: Location | null): string | undefined => {
      if (!address) {
        return ''
      }

      const { streetAddressLine1, streetAddressLine2, city, provinceState, postalCode } = address

      if (!streetAddressLine1 || !city || !provinceState || !postalCode) {
        return ''
      }

      return toUSAddress({
        addressLine1: streetAddressLine1,
        addressLine2: streetAddressLine2 ?? undefined,
        city,
        state: provinceState,
        zipCode: postalCode,
      })
    },
  },
  highlightText: (text: string): string | undefined =>
    text
      .split(' ')
      .map((word) => `__${word}__`)
      .join(' '),
}

export function toTitleCase(text: string): string {
  return text.replace(/\w\S*/g, function (text) {
    return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
  })
}

export const cleanPercentage = (value: string) => {
  // Allow only decimal point and numbers
  let cleanedValue = value.replace(/[^0-9.]/g, '')

  // If the percentage value starts with a decimal point, prepend '0' to it
  if (cleanedValue.startsWith('.')) {
    cleanedValue = '0' + cleanedValue
    return cleanedValue
  }
  // If there is a decimal, allow only one digit after the decimal point
  const decimalIndex = cleanedValue.indexOf('.')
  if (decimalIndex !== -1) {
    const integerPart = cleanedValue.slice(0, decimalIndex)
    const fractionalPart = cleanedValue.slice(decimalIndex + 1, decimalIndex + 2) // Take only one digit after the decimal point
    cleanedValue = integerPart + '.' + fractionalPart
  }
  return cleanedValue
}

export const formatRouteNameToUrl = (routeName: string): string =>
  '/' +
  routeName
    .split('')
    .map((letter) => (letter === '_' ? '-' : letter.toLowerCase()))
    .join('')

export const formatDateInputToISODate = (date: string): string =>
  DateTime.fromFormat(date, DATE_INPUT_FORMAT).toFormat(ISO_DATE_FORMAT)

export const removeAllNonNumbers = (text: string): string => text.replace(regex.notNumber, '')

export const formatE164PhoneNumber = (value: string): string | undefined => {
  const result = phone(value)
  if (!result.length) return
  return result[0]
}
