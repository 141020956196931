import { Body, BodySizeEnum, Box, CurrencyInput } from '@northone/ui-components'
import { formatMoney, unformat } from 'accounting'
import { ReactElement, useEffect, useState } from 'react'
import ContinueButton from '@/components/ContinueButton'
import { ErrorBanner } from '@/components/ErrorBanner'
import GoBackButton from '@/components/GoBackButton'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { AccountField } from './accountDisplay'
import { TopUpsQueryData } from './TopUpOptInScreen'

const getIsFormValid = ({
  itemId,
  topUpAmount,
  minimumBalanceAmount,
  topUpAmountLimits,
  minimumBalanceLimits,
}: {
  itemId?: string
  topUpAmount?: number
  minimumBalanceAmount?: number
  topUpAmountLimits?: { minimum: number; maximum: number; default: number }
  minimumBalanceLimits?: { minimum: number; default: number }
}) => {
  if (
    !itemId ||
    topUpAmount === undefined ||
    minimumBalanceAmount === undefined ||
    !topUpAmountLimits ||
    !minimumBalanceLimits
  )
    return false

  if (topUpAmount < topUpAmountLimits.minimum || topUpAmount > topUpAmountLimits.maximum) return false
  if (minimumBalanceAmount < minimumBalanceLimits.minimum) return false
  return true
}

interface TopUpsFormProps {
  plaidItem: TopUpsQueryData[number]
  minimumBalanceLimits: { minimum: number; default: number }
  topUpAmountLimits: { minimum: number; maximum: number; default: number }
  onPressContinueButton: () => void
  onPressGoBackButton: () => void
  onPressSkipTopUps: () => void
  hasSavingTopUpErrorMessage: boolean
  disclaimerComponent?: ReactElement
  topUpOptInDisclaimer: string
}

export function TopUpOptInForm({
  minimumBalanceLimits,
  plaidItem,
  topUpAmountLimits,
  onPressContinueButton,
  onPressGoBackButton,
  onPressSkipTopUps,
  hasSavingTopUpErrorMessage,
  disclaimerComponent,
  topUpOptInDisclaimer,
}: TopUpsFormProps) {
  const t = useOnboardingTranslations()

  const [minimumBalanceAmount, setMinimumBalanceAmount] = useState<number>()
  const [topUpAmount, setTopUpAmount] = useState<number>()
  const [isFormValid, setIsFormValid] = useState(false)
  const [minimumBalanceErrorMessage, setMinimumBalanceErrorMessage] = useState<string>()
  const [topUpAmountErrorMessage, setTopUpAmountErrorMessage] = useState<string>()

  const itemId = plaidItem.id

  const onMinimumBalanceAmountChanged = (value: number) => {
    setMinimumBalanceErrorMessage(undefined)
    const minimumBalance = unformat(value.toString())
    setMinimumBalanceAmount(minimumBalance)
    const { minimum } = minimumBalanceLimits
    if (minimumBalance < minimum) {
      setMinimumBalanceErrorMessage(
        t('topUpOptIn.errorMessages.minimumBalanceOutOfRange', {
          minimum,
          minimumFormatted: formatMoney(minimum, undefined, 0),
        }),
      )
    }
  }

  const onTopUpAmountChanged = (value: number) => {
    setTopUpAmountErrorMessage(undefined)
    const amount = unformat(value.toString())
    setTopUpAmount(amount)

    const { minimum, maximum } = topUpAmountLimits
    if (amount < minimum || amount > maximum) {
      setTopUpAmountErrorMessage(
        t('topUpOptIn.errorMessages.amountOutOfRange', {
          minimum,
          minimumFormatted: formatMoney(minimum, undefined, 0),
          maximumFormatted: formatMoney(maximum, undefined, 0),
        }),
      )
    }
  }

  useEffect(() => {
    setMinimumBalanceAmount(minimumBalanceLimits.default)
    setTopUpAmount(topUpAmountLimits.default)
  }, [minimumBalanceLimits.default, topUpAmountLimits.default])

  useEffect(() => {
    setIsFormValid(
      getIsFormValid({
        itemId,
        topUpAmount,
        minimumBalanceAmount,
        topUpAmountLimits,
        minimumBalanceLimits,
      }),
    )
  }, [itemId, topUpAmount, minimumBalanceAmount, topUpAmountLimits, minimumBalanceLimits])

  const title = t('topUpOptIn.title')
  const minimumBalancePlaceholder = `$${minimumBalanceLimits.default}`
  const topUpAmountPlaceholder = `$${topUpAmountLimits.default}`
  const skipButtonText = t('topUpOptIn.buttons.skip')
  const minimumBalanceLabel = t('topUpOptIn.labels.minimumBalance')
  const amountLabel = t('topUpOptIn.labels.amount')
  const accountLabel = t('topUpOptIn.labels.account')
  const savingTopUpErrorMessage = t('topUpOptIn.errorMessages.savingTopUp')

  const tertiaryButtonProps = {
    text: skipButtonText,
    onClick: onPressSkipTopUps,
    testID: 'top-up-opt-in-skip',
  }

  return (
    <BaseContentLayout
      headingText={title}
      primaryButton={
        <ContinueButton
          testID="top-up-opt-in-continue"
          onPress={onPressContinueButton}
          disabled={!isFormValid}
          fullWidth
        />
      }
      secondaryButton={<GoBackButton testID="top-up-opt-in-go-back" onPress={onPressGoBackButton} fullWidth />}
      tertiaryButtonProps={tertiaryButtonProps}
      alertComponent={
        hasSavingTopUpErrorMessage ? (
          <ErrorBanner error={savingTopUpErrorMessage} appendContactCustomerCareLink />
        ) : null
      }
      disclaimerComponent={disclaimerComponent}
      showProgressAutoSavedNote
    >
      <Box sx={{ gap: '$4' }}>
        <CurrencyInput
          testID="minimum-balance"
          allowDecimals={false}
          value={minimumBalanceAmount}
          onChange={onMinimumBalanceAmountChanged}
          placeholder={minimumBalancePlaceholder.toString()}
          labelText={minimumBalanceLabel}
          errorText={minimumBalanceErrorMessage}
        />

        <CurrencyInput
          testID="top-up-amount"
          allowDecimals={false}
          value={topUpAmount}
          onChange={onTopUpAmountChanged}
          placeholder={topUpAmountPlaceholder.toString()}
          labelText={amountLabel}
          errorText={topUpAmountErrorMessage}
        />
      </Box>

      <AccountField label={accountLabel} plaidItem={plaidItem} />

      <Body size={BodySizeEnum.SM}>{topUpOptInDisclaimer}</Body>
    </BaseContentLayout>
  )
}
