import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import LoadingScreen from '@/components/LoadingScreen'
import { RequireNonDeclinedApplication } from '@/routes/guards/require-non-declined-application'
import { FileUploadNavigationContextProvider } from '../document-upload/file-upload-context/file-upload-context-provider'
import { useCreateManualReviewIntercomConversation } from '../document-upload/intercom'
import { DisallowNonPrimaryOwner } from '../guards/disallow-non-primary-owner'

export const FileCaptureRoot = () => {
  useCreateManualReviewIntercomConversation()
  return (
    <FileUploadNavigationContextProvider>
      <DisallowNonPrimaryOwner>
        <RequireNonDeclinedApplication>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </RequireNonDeclinedApplication>
      </DisallowNonPrimaryOwner>
    </FileUploadNavigationContextProvider>
  )
}
