import { Navigate } from 'react-router-dom'

import { useAppSelector } from '../../core/redux/utils'
import { PlaidItemStatus, usePlaidItemsSuspenseQuery } from '../../generated/graphql'
import { Pathname } from '../constants'

/**
 * Ensures that the user has an active Plaid item or redirects them to the funding root.
 */
export const RequireActivePlaidItem = ({ children }: { children: JSX.Element }) => {
  const businessId = useAppSelector((state) => state.application.businessId)

  const { data: plaidItemsData } = usePlaidItemsSuspenseQuery({ variables: { businessId } })
  const hasAnActivePlaidItem = plaidItemsData.plaidItems?.data.some((item) => item.status === PlaidItemStatus.ACTIVE)

  if (hasAnActivePlaidItem) return children

  return <Navigate to={Pathname.ACCOUNT_FUNDING} replace />
}
