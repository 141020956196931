import { TBusinessTypeValues } from '../core/redux/application-redux/application-state'
import { BusinessType } from '../types'

export const isCorporation = (businessType: TBusinessTypeValues): boolean =>
  businessType === BusinessType.LLC || businessType === BusinessType.SCORP || businessType === BusinessType.CCORP

export const getIsSoleProp = (businessType: TBusinessTypeValues): boolean =>
  businessType === BusinessType.SOLEPROP || businessType === BusinessType.FREELANCER

export const isPartnership = (businessType: TBusinessTypeValues): boolean => businessType === BusinessType.PARTNERSHIP
