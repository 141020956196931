import { Box, GradientGlow } from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'
import { ReactNode } from 'react'
import { BaseContentLayout, BaseContentLayoutProps } from './BaseContentLayout'

export const ExpectationScreenLayout = ({
  disclaimerComponent,
  children,
  primaryButton,
  showProgressAutoSavedNote,
  secondaryButton,
  maxContentSize = 'lg',
}: BaseContentLayoutProps & {
  disclaimerComponent?: ReactNode
}) => {
  const { isMobileSize } = useMobileScreenSize()
  return (
    <BaseContentLayout
      disclaimerComponent={disclaimerComponent}
      maxContentSize={maxContentSize}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      showProgressAutoSavedNote={showProgressAutoSavedNote}
      hasGradientBackground
    >
      <Box sx={{ gap: '$12', width: '$full', alignItems: 'center' }}>
        {isMobileSize ? (
          <Box
            sx={{
              flex: 1,
              width: '$full',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {children}
          </Box>
        ) : (
          <GradientGlow>
            <Box
              sx={{
                flex: 1,
                bg: '$background',
                width: '$full',
                p: '$8',
                borderRadius: '$sm',
                borderColor: '$charcoal1',
                borderWidth: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {children}
            </Box>
          </GradientGlow>
        )}
      </Box>
    </BaseContentLayout>
  )
}

const flexRowOnLargerScreensAndColumnOnSmaller = {
  '@base': { flexDirection: 'column' },
  '@lg': { flexDirection: 'row' },
}
export function ExpectationScreenCardLayout({ children }: { children: ReactNode }) {
  return <Box sx={{ gap: '$8', ...flexRowOnLargerScreensAndColumnOnSmaller }}>{children}</Box>
}

const cardHalfStyles = {
  '@lg': { flex: 1 },
}
export function ExpectationScreenCardHalf({ children }: { children: ReactNode }) {
  return <Box sx={cardHalfStyles}>{children}</Box>
}

export function ExpectationScreenImage({ src, alt }: { src: string; alt: string }) {
  return (
    <img src={src} width="804" height="910" style={{ height: 'auto', maxWidth: '100%', width: 'auto' }} alt={alt} />
  )
}
