import {
  Body,
  BodySizeEnum,
  Box,
  FlatButton,
  ICONS,
  Modal,
  OnboardingHeader,
  OnboardingHeaderSizeEnum,
  PrimaryButton,
} from '@northone/ui-components'

import { useOnboardingTranslations } from '@/i18n/locales/en/en'

interface ProofOfIdentityModalProps {
  isProofOfIdentityModalVisible: boolean
  onDoNotHaveAnyOfThesePressed: () => void
  onUploadButtonPressed: () => void
}

export const ProofOfIdentityModal = ({
  isProofOfIdentityModalVisible = false,
  onDoNotHaveAnyOfThesePressed,
  onUploadButtonPressed,
}: ProofOfIdentityModalProps) => {
  const t = useOnboardingTranslations()

  const title = t('inAppDocumentCapture.proofOfIdentityModal.title')
  const preferredMethodSubHeading = t('inAppDocumentCapture.proofOfIdentityModal.preferredMethod.subHeading')
  const driversLicense = t('inAppDocumentCapture.proofOfIdentityModal.preferredMethod.driversLicense')
  const otherSubHeading = t('inAppDocumentCapture.proofOfIdentityModal.other.otherSubHeading')
  const passport = t('inAppDocumentCapture.proofOfIdentityModal.other.passport')
  const residentCard = t('inAppDocumentCapture.proofOfIdentityModal.other.residentCard')
  const authorizationCard = t('inAppDocumentCapture.proofOfIdentityModal.other.authorizationCard')
  const borderCard = t('inAppDocumentCapture.proofOfIdentityModal.other.borderCard')
  const military = t('inAppDocumentCapture.proofOfIdentityModal.other.military')
  const consular = t('inAppDocumentCapture.proofOfIdentityModal.other.consular')
  const tribal = t('inAppDocumentCapture.proofOfIdentityModal.other.tribal')

  const uploadNowButtonText = t('inAppDocumentCapture.proofOfIdentityModal.uploadNowButtonText')
  const noneOfTheseButtonText = t('inAppDocumentCapture.proofOfIdentityModal.noneOfTheseButtonText')

  return (
    <Modal isVisible={isProofOfIdentityModalVisible}>
      <Box sx={{ gap: '$6', flexShrink: 1 }}>
        <OnboardingHeader headingLevel={1} size={OnboardingHeaderSizeEnum.MD}>
          {title}
        </OnboardingHeader>
        <Box sx={{ gap: '$6' }}>
          <Box sx={{ gap: '$2' }}>
            <Body color={'$charcoal7'} size={BodySizeEnum.LG}>
              {preferredMethodSubHeading}
            </Body>
            <Body>
              <ul style={{ marginBlockStart: 0, marginBlockEnd: 0, paddingInlineStart: 24 }}>
                <li>{driversLicense}</li>
              </ul>
            </Body>
          </Box>
          <Box sx={{ gap: '$2' }}>
            <Body color={'$charcoal7'} size={BodySizeEnum.LG}>
              {otherSubHeading}
            </Body>
            <Box>
              <Body>
                <ul style={{ marginBlockStart: 0, marginBlockEnd: 0, paddingInlineStart: 24 }}>
                  <li>{passport}</li>
                  <li>{residentCard}</li>
                  <li>{authorizationCard}</li>
                  <li>{borderCard}</li>
                  <li>{military}</li>
                  <li>{consular}</li>
                  <li>{tribal}</li>
                </ul>
              </Body>
            </Box>
          </Box>
        </Box>
        <Box sx={{ gap: '$2' }}>
          <PrimaryButton testID="types-of-id-modal-upload-now-button" onPress={onUploadButtonPressed} fullWidth>
            {uploadNowButtonText}
          </PrimaryButton>
          <FlatButton
            icon={ICONS.ArrowIcon}
            iconPosition="right"
            testID="types-of-id-modal-dismiss"
            onPress={onDoNotHaveAnyOfThesePressed}
            fullWidth
          >
            {noneOfTheseButtonText}
          </FlatButton>
        </Box>
      </Box>
    </Modal>
  )
}
