import {
  ICONS,
  IconWithBackgroundSendIcon,
  LegalDisclaimerText,
  SelectionBoxInputGroup,
  SizeEnum,
} from '@northone/ui-components'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ContinueButton from '@/components/ContinueButton'
import GoBackButton from '@/components/GoBackButton'
import { analytics } from '@/core/analytics/events'
import { hasGQLErrorType } from '@/core/apollo/utils'
import { useAppSelector } from '@/core/redux/utils'
import {
  ErrorCodeEnum,
  PlaidAccountType,
  PlaidItemStatus,
  useExchangePublicTokenMutation,
  usePlaidItemsQuery,
} from '@/generated/graphql'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'
import {
  PLAID_ITEM_CREATE_ERROR_LOCATION_KEY,
  PLAID_ITEM_CREATE_ERROR_LOCATION_KEY_IDENTITY_FAILURE,
} from '../constants'
import { PlaidFundingBusinessAccountType, PlaidFundingPersonalAccountType } from '../types'

const GQLAccountTypeToPlaidFundingAccountType = {
  [PlaidAccountType.BUSINESS]: PlaidFundingBusinessAccountType,
  [PlaidAccountType.PERSONAL]: PlaidFundingPersonalAccountType,
} as const

const buildAccountTypeOptions = ({
  businessAccountTitle,
  businessAccountSubtitle,
  personalAccountTitle,
  personalAccountSubtitle,
}: {
  businessAccountTitle: string
  businessAccountSubtitle: string
  personalAccountTitle: string
  personalAccountSubtitle: string
}) => {
  return [
    {
      action: businessAccountTitle,
      description: businessAccountSubtitle,
      value: PlaidAccountType.BUSINESS,
      leftItem: IconWithBackgroundSendIcon({
        icon: ICONS.BuildingsIcon,
        size: SizeEnum.MD,
        backgroundColor: '$receiveHoneydew',
      }),
      testID: 'business',
    },
    {
      action: personalAccountTitle,
      description: personalAccountSubtitle,
      value: PlaidAccountType.PERSONAL,
      leftItem: IconWithBackgroundSendIcon({
        icon: ICONS.ProfileIcon,
        size: SizeEnum.MD,
        backgroundColor: '$receiveHoneydew',
      }),
      testID: 'personal',
    },
  ]
}

export const PlaidFundingAccountTypeScreen = () => {
  const navigate = useNavigate()
  const t = useOnboardingTranslations()

  const [exchangePublicTokenMutation] = useExchangePublicTokenMutation()

  const [accountType, setAccountType] = useState<PlaidAccountType>()
  const businessId = useAppSelector((state) => state.application.businessId)
  const { publicToken, accountId: plaidAccountId, institutionName } = useAppSelector((state) => state.plaidFunding)
  const [loading, setLoading] = useState<boolean>(false)
  const businessOwners = useAppSelector((state) => state.application.owners)
  const businessName = useAppSelector((state) => state.application.businessName)
  const ownerNames = Object.values(businessOwners).map((owner) => `${owner.firstName} ${owner.lastName}`)

  const title = t('plaidFunding.accountType.title')
  const subtitle = t('plaidFunding.accountType.subtitle')
  const businessAccountTitle = t('plaidFunding.accountType.business.title')
  const businessAccountSubtitle = t('plaidFunding.accountType.business.subtitle')
  const personalAccountTitle = t('plaidFunding.accountType.personal.title')
  const personalAccountSubtitle = t('plaidFunding.accountType.personal.subtitle')
  const legalDisclaimer = t('disclaimers.standard') + ' ' + t('plaidFunding.linkAccount.disclaimer')

  const accountTypeOptions = buildAccountTypeOptions({
    businessAccountTitle,
    businessAccountSubtitle,
    personalAccountTitle,
    personalAccountSubtitle,
  })

  const {
    data: plaidItemsQueryData,
    startPolling: startPollingForPlaidItems,
    stopPolling: stopPollingForPlaidItems,
  } = usePlaidItemsQuery({
    variables: { businessId },
  })

  useEffect(() => {
    analytics.funding.plaid.accountTypeScreen
  }, [])

  useEffect(() => {
    const hasActivePlaidItem = plaidItemsQueryData?.plaidItems?.data.some(
      (item) => item.status === PlaidItemStatus.ACTIVE,
    )
    if (!hasActivePlaidItem) return
    stopPollingForPlaidItems()
    navigate(Pathname.ACCOUNT_FUNDING_AMOUNT, { replace: true })
  }, [navigate, plaidItemsQueryData, stopPollingForPlaidItems])

  const onAccountTypeSelected = (accountType: PlaidAccountType) => {
    const plaidFundingAccountType = GQLAccountTypeToPlaidFundingAccountType[accountType]
    analytics.funding.plaid.accountTypeSelect({
      accountType: plaidFundingAccountType,
    })
    setAccountType(accountType)
  }

  const identityCheckInputs = { businessName, ownerNames }

  const onContinueClicked = () => {
    if (!accountType || !plaidAccountId || !publicToken) {
      return
    }
    setLoading(true)
    exchangePublicTokenMutation({
      variables: {
        businessId,
        plaidAccountId,
        publicToken,
        accountType,
        institutionName,
        identityCheckInputs,
      },
      onCompleted: () => {
        startPollingForPlaidItems(1_000)
      },
      onError: (error) => {
        setLoading(false)
        const errorIsIdentityCheckFailure = hasGQLErrorType(error, ErrorCodeEnum.PLAID_FUNDING_IDENTITY_MATCH_FAILED)
        const params = {
          [PLAID_ITEM_CREATE_ERROR_LOCATION_KEY]: errorIsIdentityCheckFailure
            ? PLAID_ITEM_CREATE_ERROR_LOCATION_KEY_IDENTITY_FAILURE
            : 'true',
        }
        navigate(Pathname.ACCOUNT_FUNDING_LINK_EXTERNAL_ACCOUNT, { state: params, replace: true })
      },
    })
  }
  const onGoBackClicked = () => navigate(Pathname.ACCOUNT_FUNDING_LINK_EXTERNAL_ACCOUNT)

  const continueButton = (
    <ContinueButton
      testID="linked-account-type-continue"
      onPress={onContinueClicked}
      disabled={!accountType}
      loading={loading}
      fullWidth
    />
  )

  return (
    <BaseContentLayout
      headingText={title}
      subHeadingText={subtitle}
      primaryButton={continueButton}
      secondaryButton={<GoBackButton testID="mailing-address-layout-go-back" onPress={onGoBackClicked} fullWidth />}
      disclaimerComponent={<LegalDisclaimerText>{legalDisclaimer}</LegalDisclaimerText>}
    >
      <SelectionBoxInputGroup value={accountType} onChange={onAccountTypeSelected} options={accountTypeOptions} />
    </BaseContentLayout>
  )
}
