import tlds from 'tlds'

export const regex = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  /*
    List of top level domains (TLD) from IANA: https://data.iana.org/TLD/tlds-alpha-by-domain.txt
    Email validation rules:
    - gmail domain must have .com as TLD
    - TLD must exist in IANA list
  */
  emailRules: {
    gmail: /^.+@gmail\.com$/,
    TLD: new RegExp(`^.+@[a-zA-Z0-9.-]+\\.(${tlds.join('|')})$`),
  },
  onlyNumbers: /^[0-9]*$/,
  notNumber: /[^0-9]/g,
  notPoBox: /(\d+)?(apo|po|apob|pob|post|call|gpo|box)((\d+)|box|office)/gi,
  onlyLetters: /^[a-zA-Z\s]*$/,
  nonPrintableChar: /[^ -~]+/g,
  beginningTrailingSpaces: /^\s/,
  spaceBetweenThreeDigits: /\B(?=(\d{3})+(?!\d))/g,
  card: {
    visa: /^4[0-9]{6,}$/,
    mastercard: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$/,
  },
  ssn: /^(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/,
}
