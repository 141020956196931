import {
  Body,
  BodySizeEnum,
  Box,
  ICONS,
  Modal,
  PageHeader,
  PageHeaderSizeEnum,
  PrimaryButton,
} from '@northone/ui-components'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { DEFAULT_BROWSER_REDIRECT_MESSAGE } from '@/utils/detect-mba-webview'

declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage: (message: string) => void
    }
  }
}

interface MobileWebviewRedirectModalProps {
  isModalVisible: boolean
  onClose: () => void
}

//This modal will only be opened if users are completing their application in the MBA webview
//It informs users that they must continue their application in their default browser
//Pressing continue sends a message to MBA which will trigger the default browser to open in the MBA code
export const MobileWebviewRedirectModal = ({ isModalVisible }: MobileWebviewRedirectModalProps) => {
  const t = useOnboardingTranslations()
  const copy = {
    header: t('gettingStarted.mobileWebviewRedirectModal.header'),
    body: t('gettingStarted.mobileWebviewRedirectModal.body'),
    button: t('gettingStarted.mobileWebviewRedirectModal.button'),
  }
  const onContinue = () => {
    //Send message to MBA WebView to inform it to redirect to default browser
    if (window.ReactNativeWebView?.postMessage)
      window.ReactNativeWebView.postMessage(JSON.stringify({ message: DEFAULT_BROWSER_REDIRECT_MESSAGE }))
  }

  return (
    <Modal isVisible={isModalVisible}>
      <Box sx={{ gap: '$6' }}>
        <PageHeader color={'$charcoal7'} headingLevel={2} size={PageHeaderSizeEnum.SM}>
          {copy.header}
        </PageHeader>
        <Box sx={{ gap: '$2' }}>
          <Body size={BodySizeEnum.MD}>{copy.body}</Body>
        </Box>
        <PrimaryButton testID="done-button" onPress={onContinue} icon={ICONS.DiagonalArrowIcon} fullWidth>
          {copy.button}
        </PrimaryButton>
      </Box>
    </Modal>
  )
}
