import { unformat } from 'accounting'
import phone from 'phone'
import { createSelector } from 'reselect'

import { cleanPercentage, formatDateInputToISODate, removeAllNonNumbers } from '@/utils'

import {
  BusinessType as GQLBusinessType,
  LocationStrictInput,
  OnboardingApplicationOwnerInput,
  ApplicationSubmitAndPreconfigureInvitationsMutationVariables,
} from '../../../generated/graphql'
import { DEFAULT_KYB_INDUSTRY } from '../../../routes/business-details/industry-select/data'
import { BusinessType } from '../../../types'
import { validate } from '../../../utils/validate'
import { IRootState } from '../root-state'
import { ISSNsState, IUnpersistedState } from '../unpersisted-redux/state'
import { IAddressState, IApplicationState, IOwners, PRIMARY_OWNER_ID } from './application-state'
import { selectInvitedOwnersEmails } from './owners-selectors'

const selectApplicationData = (state: IRootState): IApplicationState => state.application
const selectUnpersistedData = (state: IRootState): IUnpersistedState => state.unpersisted

export interface ISectionValidationInfo {
  title: string
  icon: string
  onClick: () => void
  completedFields: number
  totalFields: number
  titleLong?: string
}

export const getVariablesForApplicationSubmit = createSelector(
  [selectApplicationData, selectUnpersistedData, selectInvitedOwnersEmails],
  (
    {
      businessType,
      businessDescription,
      businessIndustry,
      businessPhoneNumber,
      businessAddress,
      businessName,
      tradeName,
      socialMedia,
      owners,
      controlPersonId,
      ein,
      businessRevenue,
      businessId,
      fullTimeEmployees,
      yearsAtLocation,
      socureDeviceSessionId,
    },
    { ssns },
    invitedOwnersEmails,
  ): ApplicationSubmitAndPreconfigureInvitationsMutationVariables => {
    const website = Object.values(socialMedia).find((url) => validate.website(url))
    const gqlBusinessType = transformBusinessTypeToGQLInput(businessType)
    const isSoleProp = gqlBusinessType === GQLBusinessType.SOLEPROP
    return {
      data: {
        business: {
          address: transformAddressToGQLInput(businessAddress),
          annualRevenue: unformat(businessRevenue),
          description: businessDescription.trim(),
          ...(!isSoleProp && { ein: removeAllNonNumbers(ein) }),
          id: businessId,
          legalName: isSoleProp
            ? (() => {
                const primaryOwner = owners[PRIMARY_OWNER_ID]
                if (!primaryOwner) {
                  throw new Error(`Primary owner's data is incomplete or not found. ID: ${PRIMARY_OWNER_ID}`)
                }
                return `${primaryOwner.firstName.trim()} ${primaryOwner.lastName.trim()}`
              })()
            : businessName.trim(),
          naicsCode: businessIndustry?.NAICSCode.toString() ?? DEFAULT_KYB_INDUSTRY.NAICSCode.toString(),
          numberOfEmployees: isSoleProp ? 1 : unformat(fullTimeEmployees),
          phone: phone(businessPhoneNumber)[0],
          yearsAtLocation: unformat(yearsAtLocation),
          dba: tradeName,
          ...(website && { website: website.trim() }),
          businessType: gqlBusinessType,
          typeOfProductsOrServices:
            businessIndustry && businessIndustry.subCategory !== DEFAULT_KYB_INDUSTRY.subCategory
              ? businessIndustry.subCategory
              : 'Other services',
        },
        owners: transformOwnersToGQLInput({ owners, ssns, controlPersonId }),
        socureDeviceSessionId,
      },
      businessId,
      emails: invitedOwnersEmails,
    }
  },
)

export const transformBusinessTypeToGQLInput = (businessType: BusinessType | null): GQLBusinessType => {
  const map: { [_key in BusinessType]: GQLBusinessType } = {
    CCORP: GQLBusinessType.CORP,
    FREELANCER: GQLBusinessType.SOLEPROP,
    LLC: GQLBusinessType.LLC,
    PARTNERSHIP: GQLBusinessType.PARTNERSHIP,
    SCORP: GQLBusinessType.CORP,
    SOLEPROP: GQLBusinessType.SOLEPROP,
  }
  return businessType ? map[businessType] : GQLBusinessType.SOLEPROP
}

export const transformAddressToGQLInput = ({
  city,
  streetAddress,
  state,
  suite,
  zipCode,
}: IAddressState): LocationStrictInput => ({
  city,
  country: 'US',
  postalCode: zipCode,
  provinceState: state,
  streetAddressLine1: streetAddress.trim(),
  streetAddressLine2: suite.trim() ?? undefined,
})

interface ITransformOwnerParam {
  owners: IOwners
  ssns: ISSNsState
  controlPersonId: string
}

const transformOwnersToGQLInput = ({
  owners,
  ssns,
  controlPersonId,
}: ITransformOwnerParam): OnboardingApplicationOwnerInput[] =>
  Object.keys(owners).map((ownerId) => {
    const owner = owners[ownerId]
    if (!owner) {
      throw new Error(`Owner with id ${ownerId} does not exist.`)
    }
    const { address, birthdate, email, firstName, jobTitle, lastName, ownershipPercentage, phoneNumber } = owner
    return {
      address: transformAddressToGQLInput(address),
      dateOfBirth: formatDateInputToISODate(birthdate),
      email,
      firstName: firstName.trim(),
      isControlPerson: ownerId === controlPersonId,
      isPrimaryOwner: ownerId === PRIMARY_OWNER_ID,
      jobTitle: jobTitle.trim() || 'Employee',
      lastName: lastName.trim(),
      ownershipPercentage: unformat(cleanPercentage(ownershipPercentage)),
      phone: phone(phoneNumber)[0] ?? '',
      ssn: removeAllNonNumbers(ssns[ownerId] ?? ''),
    }
  })
