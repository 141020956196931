import { Navigate } from 'react-router-dom'
import { usePrimaryOwnerStatusSuspenseQuery } from '../../generated/graphql'
import { Pathname } from '../constants'

/**
 * Ensures that only an additional owner cannot view this page
 */
export const DisallowNonPrimaryOwner = ({ children }: { children: JSX.Element }) => {
  const { data } = usePrimaryOwnerStatusSuspenseQuery()

  const isPrimaryOwner = data.me?.isPrimaryOwner

  if (isPrimaryOwner === false) return <Navigate to={Pathname.ROOT} replace />

  return children
}
