export interface IPlaidFundingState {
  itemId?: string
  linkToken?: string
  accountId?: string
  publicToken?: string
  institutionName?: string
}

export const initialPlaidFundingState: IPlaidFundingState = {
  itemId: undefined,
  linkToken: undefined,
  accountId: undefined,
  publicToken: undefined,
  institutionName: undefined,
}
