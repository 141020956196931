import * as Sentry from '@sentry/react'
import { Box, CardText, CardTextSizeEnum, GluestackUIStyledProvider, PrimaryButton } from '@northone/ui-components'
import { config as themeConfig } from '@northone/ui-theme'
import React from 'react'

import { CLIENT_NAME } from '@/core/apollo/apollo-client'
import { traceId } from '@/utils/logger'
import { reload } from '@/utils/navigation'

import ResponsiveOnboardingHeader from './components/ResponsiveOnboardingHeader'
import { BaseLayout } from './layouts/BaseLayout'

export class GlobalErrorCatch extends React.Component<{ children: React.ReactNode }> {
  state = {
    hasError: false,
  }

  constructor(props: any) {
    super(props)
  }

  public componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope) => {
      scope.setTags({
        trace_id: traceId,
        client: CLIENT_NAME,
      })
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public render() {
    if (this.state.hasError) {
      return (
        <GluestackUIStyledProvider config={themeConfig}>
          <BaseLayout>
            <Box
              sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '$6',
                flex: 1,
                px: '$4',
                '@md': {
                  my: 'auto',
                },
              }}
            >
              <Box
                sx={{
                  gap: '$6',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flex: 1,
                  '@md': { flex: undefined },
                }}
              >
                <ResponsiveOnboardingHeader>Something went wrong</ResponsiveOnboardingHeader>
                <CardText size={CardTextSizeEnum.MD} color="$charcoal5">
                  Reload the page to try again.
                </CardText>
              </Box>

              <Box sx={{ w: '$full', '@md': { w: undefined } }}>
                <PrimaryButton onPress={reload} testID="global-error-catch-reload" fullWidth>
                  Reload this page
                </PrimaryButton>
              </Box>
            </Box>
          </BaseLayout>
        </GluestackUIStyledProvider>
      )
    }

    return <>{this.props.children}</>
  }
}
