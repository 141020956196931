import { useBackgroundQuery } from '@apollo/client'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import LoadingScreen from '@/components/LoadingScreen'
import { RequireNonDeclinedApplication } from '@/routes/guards/require-non-declined-application'
import { DisallowNonPrimaryOwner } from '../guards/disallow-non-primary-owner'
import { APPLICATION_ADDRESSES_QUERY, MAILING_ADDRESS_QUERY } from './mailing-address/operations.gql'

export const FinishUpRoot = () => {
  useBackgroundQuery(APPLICATION_ADDRESSES_QUERY)
  useBackgroundQuery(MAILING_ADDRESS_QUERY)

  return (
    <DisallowNonPrimaryOwner>
      <RequireNonDeclinedApplication>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </RequireNonDeclinedApplication>
    </DisallowNonPrimaryOwner>
  )
}
