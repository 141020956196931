import { Link } from '@northone/ui-components'

import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { DEPOSIT_ACCOUNT_AGREEMENT_URL } from '@/utils'

export default function DepositAccountAgreementLink() {
  const t = useOnboardingTranslations()
  const depositAccountAgreement = t('links.depositAccountAgreement')

  return (
    <Link href={DEPOSIT_ACCOUNT_AGREEMENT_URL} isExternal>
      {depositAccountAgreement}
    </Link>
  )
}
