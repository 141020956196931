import { AlertTypeEnum, Body, BodySizeEnum, InlineAlert, Link } from '@northone/ui-components'
import { Intercom } from '@/core/intercom'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'

export const ErrorBanner = ({
  error,
  appendContactCustomerCareLink,
}: {
  error: string
  appendContactCustomerCareLink: boolean
}) => {
  const t = useOnboardingTranslations()
  const customerCare = t('plaidFunding.error.customerCare')

  return (
    <InlineAlert type={AlertTypeEnum.Error} showIcon>
      <Body size={BodySizeEnum.SM} color="$error5">
        {error} {appendContactCustomerCareLink ? <Link onPress={Intercom.show}>{customerCare}</Link> : null}
      </Body>
    </InlineAlert>
  )
}
