import { combineReducers } from 'redux'
import { PersistStateActionEnum } from '../persist-state/redux/actions'
import { accountUsageReducer as accountUsage } from './account-usage-redux/reducer'
import { IAccountUsageState } from './account-usage-redux/state'
import { applicationReducer as application } from './application-redux/application-reducer'
import { IApplicationState } from './application-redux/application-state'
import { fileUploadReducer as uploadedFileNames } from './file-upload-redux/reducer'
import { IUploadedFilenameState } from './file-upload-redux/state'
import { funnelReducer as funnel } from './funnel-redux/reducer'
import { IFunnelState } from './funnel-redux/state'
import { plaidFundingReducer as plaidFunding } from './plaid-funding/reducer'
import { IPlaidFundingState } from './plaid-funding/state'
import { IRootState } from './root-state'
import { reducer as unpersisted } from './unpersisted-redux/reducer'
import { IUnpersistedState } from './unpersisted-redux/state'

interface RootReducer {
  application: IApplicationState
  funnel: IFunnelState
  unpersisted: IUnpersistedState
  accountUsage: IAccountUsageState
  plaidFunding: IPlaidFundingState
  uploadedFileNames: IUploadedFilenameState
}

export const createRootReducer =
  () =>
  (state: IRootState | undefined, action: any): RootReducer => {
    if (action.type === PersistStateActionEnum.HYDRATE_STATE) {
      const { application, accountUsage, funnel, uploadedFileNames, navigation, ...restOfPayload } = action.payload
      state = {
        ...state,
        ...restOfPayload,
        application: { ...state?.application, ...application },
        accountUsage: { ...state?.accountUsage, ...accountUsage },
        funnel: { ...state?.funnel, ...funnel },
        uploadedFileNames: { ...state?.uploadedFileNames, ...uploadedFileNames },
      }
    }
    return combineReducers({
      application,
      funnel,
      unpersisted,
      accountUsage,
      plaidFunding,
      uploadedFileNames,
    })(state, action)
  }
