import { createAction, IActionWithPayload, TActionsUnion } from '../../../core/redux/utils'

export enum FunnelActionEnum {
  SET_FREELANCER_MODAL_OPEN = 'SET_FREELANCER_MODAL_OPEN',
}

export const funnelActions = {
  setFreelancerModalOpen: (isOpen: boolean): IActionWithPayload<FunnelActionEnum.SET_FREELANCER_MODAL_OPEN, boolean> =>
    createAction(FunnelActionEnum.SET_FREELANCER_MODAL_OPEN, isOpen),
}

export type TFunnelActions = TActionsUnion<typeof funnelActions>
