import {
  Body,
  BodySizeEnum,
  Box,
  DocumentIcon,
  IconButton,
  InformationIcon,
  SizeEnum,
  TrashIcon,
} from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'
import { Pressable } from 'react-native'

import ContinueButton, { ContinueButtonProps } from '@/components/ContinueButton'
import GoBackButton, { GoBackButtonProps } from '@/components/GoBackButton'
import { UploadAttachment } from '@/components/UploadAttachment'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'

import { truncateFileName } from '../../utils/truncate-file-name'

const PDF_HEIGHT = 274
const PDF_MAX_WIDTH = 548

const iframeStyles = {
  minHeight: PDF_HEIGHT,
  width: PDF_MAX_WIDTH,
  maxWidth: '100%',
  height: '100%',
}

const termsContainerStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderColor: '$charcoal1',
  borderWidth: 1,
  borderRadius: '$sm',
  height: PDF_HEIGHT,
  width: '$full',
  maxWidth: PDF_MAX_WIDTH,
}

interface DocumentUploadLayoutProps {
  headingText: string
  subHeadingText: string
  continueButtonProps: ContinueButtonProps
  backButtonProps: GoBackButtonProps
  viewAcceptedDocsText: string
  onPressViewAcceptedDocs: () => void
  onFileInput: (file: File) => void
  fileContentType?: string
  fileURL?: string
  uploadedFileName?: string
  isUploadLoading?: boolean
  hasUploadError?: boolean
  onDeleteFile: () => void
}

export const DocumentUploadLayout = ({
  headingText,
  subHeadingText,
  viewAcceptedDocsText,
  continueButtonProps,
  backButtonProps,
  onPressViewAcceptedDocs,
  onFileInput,
  fileContentType,
  fileURL,
  uploadedFileName,
  isUploadLoading,
  hasUploadError,
  onDeleteFile,
}: DocumentUploadLayoutProps) => {
  const { isMobileSize } = useMobileScreenSize()

  const onPressDelete = () => {
    onDeleteFile()
  }

  const fileIsImage = fileContentType?.startsWith('image/')

  return (
    <BaseContentLayout
      showProgressAutoSavedNote={isMobileSize}
      headingText={headingText}
      subHeadingText={subHeadingText}
      primaryButton={<ContinueButton {...continueButtonProps} fullWidth />}
      secondaryButton={<GoBackButton {...backButtonProps} fullWidth />}
    >
      <Box sx={{ gap: '$4' }}>
        <Pressable onPress={onPressViewAcceptedDocs}>
          <Box sx={{ gap: '$2', flexDirection: 'row', alignItems: 'center' }}>
            <Body size={BodySizeEnum.LG} color="$charcoal7">
              {viewAcceptedDocsText}
            </Body>
            <InformationIcon size={SizeEnum.MD} />
          </Box>
        </Pressable>
        {!fileURL && !uploadedFileName ? (
          <UploadAttachment
            onChange={onFileInput}
            buttonType="secondary"
            isLoading={isUploadLoading}
            hasError={hasUploadError}
          />
        ) : null}
        {fileURL ? (
          <Box sx={{ position: 'relative' }}>
            <Box sx={{ position: 'absolute', top: '$4', right: '$4', zIndex: 1 }}>
              <IconButton
                onPress={onPressDelete}
                isDestructive={true}
                testID="bill-payments.bill-details.delete-attachment-button"
                size={SizeEnum.LG}
                icon={TrashIcon}
              />
            </Box>
            {fileIsImage ? (
              <img style={{ objectFit: 'contain', maxHeight: 304 }} src={fileURL} alt="uploaded file preview" />
            ) : (
              <Box sx={termsContainerStyles}>
                <iframe
                  style={iframeStyles}
                  src={`${fileURL}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0&view=FitW`}
                />
              </Box>
            )}
          </Box>
        ) : null}
        {!fileURL && uploadedFileName ? (
          <Box
            sx={{
              flex: 1,
              bg: '$white1',
              borderWidth: 1,
              borderColor: '$charcoal1',
              borderRadius: '$sm',
              position: 'relative',
              padding: '$6',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ position: 'absolute', top: '$4', right: '$4', zIndex: 1 }}>
              <IconButton
                onPress={onPressDelete}
                isDestructive={true}
                testID="bill-payments.bill-details.delete-attachment-button"
                size={SizeEnum.LG}
                icon={TrashIcon}
              />
            </Box>
            <Box sx={{ gap: '$2', paddingVertical: '$4', display: 'flex', alignItems: 'center' }}>
              <DocumentIcon size={SizeEnum.LG} />
              <Body color="$charcoal7" size={BodySizeEnum.LG}>
                {truncateFileName(uploadedFileName)}
              </Body>
            </Box>
          </Box>
        ) : null}
      </Box>
    </BaseContentLayout>
  )
}
