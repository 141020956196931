import { Box } from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'
import { useDispatch } from 'react-redux'

import { analytics } from '@/core/analytics/events'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { CONTROL_PERSON_CANDIDATE_ID, PRIMARY_OWNER_ID } from '@/core/redux/application-redux/application-state'
import { useAppSelector } from '@/core/redux/utils'
import { OwnerProfileForm } from '@/routes/ownership/owner-profiles/OwnerProfileForm'
import { OwnerProfileSummary } from '@/routes/ownership/owner-profiles/OwnerProfileSummary'

export interface IOwnerProfileCardProps {
  ownerId: string
  isEditing?: boolean
  zIndex: number
}

export const OwnerProfileCard = ({ ownerId, zIndex }: IOwnerProfileCardProps) => {
  const isEditing = useAppSelector((state) => state.application.owners[ownerId]?.isEditing ?? false)

  const { isMobileSize } = useMobileScreenSize()
  const dispatch = useDispatch()

  const setIsEditing = (isEditing: boolean) => {
    dispatch(
      applicationActions.updateOwner({
        ownerId,
        updatedOwnerFields: { isEditing, ...(!isEditing && { hasSavedBefore: true }) },
      }),
    )
    if (ownerId === CONTROL_PERSON_CANDIDATE_ID) {
      analytics.application.controlPersonEdit()
    } else if (ownerId !== PRIMARY_OWNER_ID) {
      analytics.application.coOwnerEdit()
    }
  }

  return (
    <Box sx={{ zIndex }}>
      <Box sx={{ gap: '$8' }}>
        {isEditing ? (
          <Box
            sx={{
              p: isMobileSize ? '$6' : '$10',
              backgroundColor: '$white1',
              borderRadius: '$md',
              borderWidth: 1,
              borderColor: '$charcoal1',
            }}
          >
            <OwnerProfileForm isEditing={isEditing} setIsEditing={setIsEditing} ownerId={ownerId} />
          </Box>
        ) : (
          <OwnerProfileSummary ownerId={ownerId} startEditing={() => setIsEditing(true)} />
        )}
      </Box>
    </Box>
  )
}
