import { useState } from 'react'
import { IAddressState } from '../../core/redux/application-redux/application-state'
import { useAppSelector } from '../../core/redux/utils'
import { useListTeamMembersQuery } from '../../generated/graphql'
import { gqlTeamMemberAddressToAddressState } from './utils'

export const useListOwnerDetails = () => {
  const businessId = useAppSelector((state) => state.application.businessId)
  const userId = useAppSelector((state) => state.application.userId)
  const isPrimaryOwner = useAppSelector((state) => state.application.isPrimaryOwner)
  const [error, setError] = useState(false)

  const [hasTeamMemberShippingAddress, setHasTeamMemberShippingAddress] = useState<boolean>()
  const [hasCompletedOnboarding, setHasCompletedOnboarding] = useState<boolean>()
  const [shippingAddress, setShippingAddress] = useState<IAddressState | undefined>()
  const [homeAddress, setHomeAddress] = useState<IAddressState | undefined>()

  const { loading: loadingTeamMemberApplicationAddresses, refetch } = useListTeamMembersQuery({
    fetchPolicy: 'network-only',
    skip: !businessId || isPrimaryOwner,
    variables: {
      businessId: businessId,
    },
    onError: () => {
      setHasTeamMemberShippingAddress(false)
      setError(true)
    },
    onCompleted: (data) => {
      const teamMember = data.business.teamMembers.find((o) => o.userId === userId)
      if (!teamMember) return
      const shippingAddress = teamMember.shippingAddress
      const teamMemberHomeAddress = teamMember.address
      const onboardingCompleted = teamMember.onboardingCompleted
      onboardingCompleted ? setHasCompletedOnboarding(true) : setHasCompletedOnboarding(false)
      shippingAddress ? setHasTeamMemberShippingAddress(true) : setHasTeamMemberShippingAddress(false)
      if (shippingAddress) {
        setShippingAddress(gqlTeamMemberAddressToAddressState(shippingAddress))
      }
      if (teamMemberHomeAddress) {
        setHomeAddress(gqlTeamMemberAddressToAddressState(teamMemberHomeAddress))
      }
    },
  })

  return {
    loadingTeamMemberApplicationAddresses,
    error,
    refetch,
    hasTeamMemberShippingAddress,
    hasCompletedOnboarding,
    homeAddress,
    shippingAddress,
  }
}
