import { AlertTypeEnum, Body, Box, FlatButton, ICONS, InlineAlert, PrimaryButton } from '@northone/ui-components'
import ResponsiveOnboardingHeader from '@/components/ResponsiveOnboardingHeader'
import StandardDisclaimer from '@/components/StandardDisclaimer'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { ExpectationScreenLayout } from '@/layouts/ExpectationScreenLayout'
import { SCHEDULE_ONBOARDING_WEBINAR_URL } from '@/utils'

interface IWelcomeContentProps {
  hasError: boolean
  loading: boolean
  submit: () => void
}

export const WelcomeContent = ({ hasError, loading, submit }: IWelcomeContentProps) => {
  const t = useOnboardingTranslations()

  const title = t('finishUp.welcomeToNorthOne.title')
  const scheduleACallNote = t('finishUp.welcomeToNorthOne.scheduleACallNote')
  const scheduleACallText = t('finishUp.welcomeToNorthOne.scheduleACallButton')
  const skipCallText = t('finishUp.welcomeToNorthOne.skipCallButton')
  const errorText = t('error.generic')

  const onScheduleCallClicked = () => {
    const win = window.open(SCHEDULE_ONBOARDING_WEBINAR_URL, '_blank')
    win?.focus()
    submit()
  }

  const onSkipCallClicked = () => {
    submit()
    return
  }

  return (
    <ExpectationScreenLayout disclaimerComponent={<StandardDisclaimer />} maxContentSize="md">
      <Box sx={{ gap: '$6', justifyContent: 'flex-start' }}>
        {hasError && <InlineAlert type={AlertTypeEnum.Error}>{errorText}</InlineAlert>}

        <ResponsiveOnboardingHeader largerSize="md">{title}</ResponsiveOnboardingHeader>

        <Body>{scheduleACallNote}</Body>

        <PrimaryButton
          testID="finish-up-welcome-schedule-call"
          onPress={onScheduleCallClicked}
          loading={loading}
          disabled={loading}
          fullWidth
        >
          {scheduleACallText}
        </PrimaryButton>
        <FlatButton
          testID="finish-up-welcome-skip-call"
          onPress={onSkipCallClicked}
          icon={ICONS.ArrowIcon}
          disabled={loading}
          fullWidth
        >
          {skipCallText}
        </FlatButton>
      </Box>
    </ExpectationScreenLayout>
  )
}
