import useBusinessType from '@/hooks/useBusinessType'

import OwnershipOwnerProfilesNonSoleprop from './OwnershipOwnerProfilesNonSoleprop'
import OwnershipOwnerProfilesSoleprop from './OwnershipOwnerProfilesSoleprop'

export default function OwnershipOwnerProfiles() {
  const { isSoleProp } = useBusinessType()

  if (isSoleProp) {
    return <OwnershipOwnerProfilesSoleprop />
  }
  return <OwnershipOwnerProfilesNonSoleprop />
}
