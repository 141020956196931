import { unformat } from 'accounting'
import { DateTime } from 'luxon'

import i18n from '../i18n/i18n'
import { amounts, maxLengths, minLengths, validate } from './validate'

export const errorMessages = {
  phone: (phone: string): string | undefined => {
    if (!phone) return i18n.t('error.required')
    if (!validate.phoneNumber(phone)) return i18n.t('error.invalidPhone')
  },
  firstName: (firstName: string): string | undefined => {
    if (!firstName) return i18n.t('error.required')
    if (!validate.maxLength(firstName, maxLengths.name)) return i18n.t('error.maxLength', { limit: maxLengths.name })
    if (!validate.onlyAlphabeticalLetters(firstName)) return i18n.t('error.onlyAlphabeticalLetters')
  },
  lastName: (lastName: string): string | undefined => {
    if (!lastName) return i18n.t('error.required')
    if (!validate.minLength(lastName, 2)) return i18n.t('error.minLength', { minLength: 2 })
    if (!validate.maxLength(lastName, maxLengths.name)) return i18n.t('error.maxLength', { limit: maxLengths.name })
    if (!validate.onlyAlphabeticalLetters(lastName)) return i18n.t('error.onlyAlphabeticalLetters')
  },
  bizDescription: (description: string): string | undefined => {
    if (!validate.required(description)) return i18n.t('error.required')
    if (!validate.description(description)) return i18n.t('error.description')
  },
  birthdate: (birthdate?: DateTime): string | undefined => {
    if (!birthdate) return i18n.t('error.required')
    if (!validate.isValidDate(birthdate)) return i18n.t('error.date.invalid')
    if (!validate.isNotFutureDate(birthdate)) return i18n.t('error.date.future')
    if (!validate.isOver18(birthdate)) return i18n.t('error.date.under18')
    if (!validate.isAfter1900(birthdate)) return i18n.t('error.date.before1900')
  },
  fullTimeEmployees: (employees: string): string | undefined => {
    if (!validate.required(employees)) return i18n.t('error.required')
    if (employees === '0') return i18n.t('error.zeroFullTimeEmployee')
    if (!validate.maxLength(unformat(employees).toString(), maxLengths.employees))
      return i18n.t('error.maxDigits', { limit: maxLengths.employees })
  },
  yearsAtLocation: (yearsAtLocation: string): string | undefined => {
    if (!validate.required(yearsAtLocation)) return i18n.t('error.required')
    if (yearsAtLocation === '0') return i18n.t('error.zeroYearsAtLocation')
    if (!validate.maxLength(yearsAtLocation, maxLengths.yearsAtLocation)) {
      return i18n.t('error.maxDigits', { limit: maxLengths.yearsAtLocation })
    }
  },
  yearsInOperation: (yearsInOperation?: number): string | undefined => {
    if (!yearsInOperation) return i18n.t('error.required')
  },
  incorporationDate: (date: DateTime): string | undefined => {
    if (!validate.required(date.toISO() ?? undefined)) return i18n.t('error.required')
    if (!validate.isValidDate(date)) return i18n.t('error.date.invalid')
    if (!validate.isNotFutureDate(date)) return i18n.t('error.date.future')
    if (!validate.isAfter1900(date)) return i18n.t('error.date.before1900')
  },
  ssn: (ssn: string | undefined): string | undefined => {
    if (!validate.required(ssn)) return i18n.t('error.required')
    if (!validate.ssn(ssn)) return i18n.t('error.invalidSSN')
  },
  ein: (ein: string | undefined): string | undefined => {
    if (!validate.required(ein)) return i18n.t('error.required')
    if (!validate.ein(ein ?? '')) return i18n.t('error.invalidEin')
  },
  phoneNumber: (phoneNumber: string): string | undefined => {
    if (!phoneNumber.replace(/[^0-9]/g, '')) return i18n.t('error.required')
    if (!validate.phoneNumber(phoneNumber)) return i18n.t('error.invalidPhone')
  },
  percentage: (percentage: string): string | undefined => {
    if (!validate.minPercentage(percentage)) return i18n.t('error.invalidZeroPercentage')
    if (!validate.maxPercentage(percentage)) return i18n.t('error.invalidExceedsMaxPercentage')
  },
  required: (value: string): string | undefined => {
    if (!validate.required(value)) return i18n.t('error.required')
  },
  streetAddress: (streetAddress: string): string | undefined => {
    if (!validate.required(streetAddress)) return i18n.t('error.required')
    if (!validate.isNotPOBox(streetAddress)) return i18n.t('error.noPOBox')
    if (!validate.streetAddress(streetAddress)) return i18n.t('error.invalidStreetAddress')
  },
  suite: (suite: string): string | undefined => {
    if (!suite) return undefined
    if (!validate.isNotPOBox(suite)) return i18n.t('error.noPOBox')
    if (!validate.suite(suite)) return i18n.t('error.invalidStreetAddress')
  },
  city: (city: string): string | undefined => {
    if (!validate.required(city)) return i18n.t('error.required')
    if (!validate.minLength(city, 2)) return i18n.t('error.minLength', { minLength: 2 })
    if (!validate.maxLength(city, maxLengths.addressField)) return i18n.t('error.invalidCity')
  },
  zipCode: (zipCode: string): string | undefined => {
    if (!validate.required(zipCode)) return i18n.t('error.required')
    if (!validate.zipCode(zipCode)) return i18n.t('error.invalidZipCode')
  },
  state: (state?: string): string | undefined => {
    if (!state) return i18n.t('error.invalidState')
  },
  email: (email: string, isEmailDuplicate: boolean): string | undefined => {
    if (!validate.required(email)) return i18n.t('error.required')
    if (!validate.maxLength(email, maxLengths.email)) return i18n.t('error.maxLength', { limit: maxLengths.email })
    if (!validate.email(email)) return i18n.t('error.invalidEmail')
    if (isEmailDuplicate) return i18n.t('error.duplicateEmail')
  },
  businessName: (businessName: string): string | undefined => {
    if (!validate.required(businessName)) return i18n.t('error.required')
    if (!validate.minLength(businessName, 2)) return i18n.t('error.minLength', { minLength: 2 })
    if (!validate.businessName(businessName)) return i18n.t('error.maxLength', { limit: maxLengths.businessName })
  },
  dba: (dba: string | undefined): string | undefined =>
    !validate.dba(dba)
      ? i18n.t('error.characterRange', { minLength: minLengths.businessName, maxLength: maxLengths.businessName })
      : '',
  initialFunding: (amount: number): string | undefined => {
    if (!validate.minFunding(amount))
      return i18n.t('funding.amount.minValueError', {
        minValue: amounts.funding.minValue,
        maxValue: amounts.funding.maxValue,
      })
    if (!validate.maxFunding(amount))
      return i18n.t('funding.amount.maxValueError', {
        minValue: amounts.funding.minValue,
        maxValue: amounts.funding.maxValue,
      })
  },
  cardholderName: (name: string): string | undefined => {
    if (!validate.required(name)) return i18n.t('error.cardDetails.cardholderName')
  },
  cardNumber: (cardNumber: string): string | undefined => {
    if (!validate.cardDetails.cardNumber(cardNumber)) return i18n.t('error.cardDetails.cardNumber')
  },
  cvv: (cvv: string): string | undefined => {
    if (!validate.cardDetails.cvv(cvv)) return i18n.t('error.cardDetails.cvv')
  },
  expirationDate: (expirationDate: string): string | undefined => {
    if (!validate.cardDetails.expirationDate(expirationDate)) return i18n.t('error.cardDetails.expirationDate.invalid')
    if (!validate.cardDetails.isNotExpired(expirationDate)) return i18n.t('error.cardDetails.expirationDate.expired')
  },
  billingAddressSuite: (suite: string): string | undefined => {
    if (!suite) return ''
    if (!validate.isNotPOBox(suite)) return i18n.t('error.noPOBox')
    if (!validate.suite(suite)) return i18n.t('error.cardDetails.billingAddress.suite')
  },
  jobTitle: (jobTitle: string): string | undefined => {
    if (!validate.required(jobTitle)) return i18n.t('error.required')
    if (!validate.maxLength(jobTitle, maxLengths.jobTitle))
      return i18n.t('error.maxLength', { limit: maxLengths.jobTitle })
  },
} as const
