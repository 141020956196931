export const getURLSearchParameter = (parameter: string) =>
  new URLSearchParams(window.location.search).get(parameter) ?? undefined

export const getUTMSearchParameters = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const utmParams: Record<string, string> = {}

  for (const [key, value] of searchParams.entries()) {
    if (!key.startsWith('utm_')) {
      continue
    }
    utmParams[key] = value
  }
  return utmParams
}
