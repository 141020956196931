import { CurrencyInput } from '@northone/ui-components'
import { unformat } from 'accounting'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ContinueButton from '@/components/ContinueButton'
import GoBackButton from '@/components/GoBackButton'
import { analytics } from '@/core/analytics/events'
import { accountUsageActions } from '@/core/redux/account-usage-redux/actions'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'

export const AverageDeposit = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const averageDeposit = useAppSelector((state) => state.accountUsage.averageDepositAmount)
  const t = useOnboardingTranslations()

  const onContinue = () => {
    analytics.accountUsage.averageDepositAmount({ value: unformat(averageDeposit) })
    navigate(Pathname.USAGE_QUESTION_CHECK_DEPOSITS)
  }
  const onGoBack = () => {
    navigate(Pathname.USAGE_QUESTION_DEBIT_CARD_AMOUNT)
  }
  const onChange = (value: number) =>
    // @ts-ignore TS2345
    dispatch(accountUsageActions.setTransactionUsageValue('averageDepositAmount', value.toString()))

  const title = t('accountUsage.averageDeposit.title')
  const averageDepositLabel = t('accountUsage.averageDeposit.inputLabel')
  const averageDepositHelper = t('accountUsage.averageDeposit.inputHelper')
  const averageDepositPlaceholder = t('accountUsage.dollarSignPlaceholder')

  return (
    <BaseContentLayout
      headingText={title}
      primaryButton={
        <ContinueButton
          testID="usage-average-amount-continue"
          onPress={onContinue}
          disabled={!averageDeposit}
          fullWidth
        />
      }
      secondaryButton={<GoBackButton testID="usage-average-amount-go-back" onPress={onGoBack} fullWidth />}
    >
      <CurrencyInput
        testID="average-deposit"
        labelText={averageDepositLabel}
        helperText={averageDepositHelper}
        placeholder={averageDepositPlaceholder}
        onChange={onChange}
        value={Number(averageDeposit)}
        allowDecimals={false}
      />
    </BaseContentLayout>
  )
}
