import { useState } from 'react'

export const MAX_FILE_SIZE_MB = 100
const MAX_FILE_SIZE_BITS = MAX_FILE_SIZE_MB * 1024 * 1024

const allowedFileTypes = new Set(['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'])

export const useBase64FileConversion = () => {
  const [base64EncodedFile, setBase64EncodedFile] = useState<string | undefined>()
  const [fileName, setFileName] = useState<string | undefined>()
  const [objectURL, setObjectURL] = useState<string | undefined>()
  const [fileContentType, setFileContentType] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [hasUploadError, setHasUploadError] = useState(false)

  const onFileInput = (file: File) => {
    setIsLoading(true)
    if (file.size >= MAX_FILE_SIZE_BITS || !allowedFileTypes.has(file.type)) {
      setIsLoading(false)
      return setHasUploadError(true)
    }
    setHasUploadError(false)
    setFileName(file.name)
    setFileContentType(file.type)
    const reader = new FileReader()
    reader.onloadend = () => {
      if (!reader.result) return setHasUploadError(true)
      setObjectURL(window.URL.createObjectURL(file))

      const base64 = reader.result as string
      setBase64EncodedFile(base64)
    }
    reader.onerror = () => {
      setHasUploadError(true)
      reader.abort()
    }
    reader.readAsDataURL(file)
    setIsLoading(false)
  }

  const resetFile = () => {
    setBase64EncodedFile(undefined)
    setObjectURL(undefined)
    setFileName(undefined)
    setFileContentType(undefined)
    setIsLoading(false)
    setHasUploadError(false)
  }

  return {
    onFileInput,
    base64EncodedFile,
    objectURL,
    fileName,
    fileContentType,
    isLoading,
    hasUploadError,
    resetFile,
  } as const
}
