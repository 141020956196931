import { Body, BodySizeEnum, Box, SecondaryButton } from '@northone/ui-components'

import ResponsiveOnboardingHeader from '@/components/ResponsiveOnboardingHeader'
import { analytics } from '@/core/analytics/events'
import { logout } from '@/core/auth/auth-service'
import { useRejectedScreenSuspenseQuery } from '@/generated/graphql'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseLayout } from '@/layouts/BaseLayout'

export const RejectedScreen = () => {
  const t = useOnboardingTranslations()
  const { data } = useRejectedScreenSuspenseQuery()
  const email = data.me?.email ?? ''

  const onClick = () => {
    analytics.status.rejectedLogout()
    logout()
  }
  const title = t('applicationStatus.rejected.title')
  const subTitle = t('applicationStatus.rejected.subTitle', { email })
  const logoutText = t('button.logout')

  return (
    <BaseLayout>
      <Box
        sx={{
          flex: 1,
          alignSelf: 'center',
          maxWidth: 660,
          justifyContent: 'center',
          alignItems: 'center',
          gap: '$6',
          paddingHorizontal: '$4',
        }}
      >
        <ResponsiveOnboardingHeader textAlign="center">{title}</ResponsiveOnboardingHeader>
        <Body textAlign="center">
          {subTitle} <Body size={BodySizeEnum.LG}>{email}</Body>.
        </Body>
        <Box>
          <SecondaryButton testID="rejected-logout-button" onPress={onClick}>
            {logoutText}
          </SecondaryButton>
        </Box>
      </Box>
    </BaseLayout>
  )
}
